import React from "react";
import { Helmet } from "react-helmet";

const Head = ({ ...props }) => {
  const hostname = window.location.host;
  let title;
  switch (hostname) {
    case "localhost":
      title = "I Party DJ Mix";
      break;
    case "https://ipartydjcrm.com/":
      title = "I Party DJ CRM";
      break;
    case "https://ipartydjmix.com/":
      title = "I Party DJ Mix";
      break;
    case "https://clickmyphoto.online/":
      title = "Click My Photo";
      break;
    case "https://centeraspace.com/":
      title = "CenteaA Space";
      break;
    default:
      title = "I Party DJ Mix";
  }

  return (
    <Helmet>
      {/* <title>{props.title ? props.title + " | " : null} DashLite React Admin Template</title> */}
      {/* <title>I Party DJ Mix</title> */}
      <title>{title}</title>
    </Helmet>
  );
};
export default Head;
