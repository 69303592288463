import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button } from "reactstrap";
import { ReactDataTable } from "../../components/Component";
import NavigationHeader from "../components/NavigationHeader";
import TableActionBar from "../components/TableActionBar";
import { useQuery, useMutation, queryClient, useQueryClient } from "react-query";
import { getEventsList, getPaymentDetails } from "../../http/get/getApi";
import { deleteEvent } from "../../http/delete/deleteApi";
import Swal from "sweetalert2";
import { formatDate, parseDateString } from "../../utils/Utils";
import PaymentDetailsModal from "./PaymentDetailsModal";
import Debounce from "../../utils/Debounce";
import { DataContext } from "../../context/DataProvider";
import dashboard from "../../Jsonfiles/Dashboard.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

export const NotAvailable = () => <div style={{ textAlign: "center" }}>----</div>;

const EventListPage = () => {
  const activeVariable = getActiveEnvironmentVariable();
  const { activeTab, setActivetab } = useContext(DataContext);
  const navigate = useNavigate();

  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [eventfinancials, seteventfinancials] = useState({});
  const [clientdetails, setclient_details] = useState({});
  // console.log("clientdetails",clientdetails)

  const queryClient = useQueryClient();

  const handleInvoiceRedirect = (event_id, package_name) => {
    if (!package_name) {
      Swal.fire({
        title: "Package Not Found",
        text: `The package details for this ${dashboard?.[activeVariable]?.section1?.map((item) => item?.text3).filter(Boolean).join(", ")}  are not available.`,
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    navigate(`invoice-detail/${event_id}`);
  };

  //fetching event data
  const {
    data: eventsList,
    isLoading: eventsListLoading,
    isError: eventsListError,
    refetch: refetcheventList,
  } = useQuery({
    queryKey: ["get-events-list", pageNo, recordLimit, search],
    queryFn: () => getEventsList({ id: "", page: pageNo, limit: recordLimit, search: search }),
    enabled: false,
    // staleTime: Infinity,
    // staleTime: 50000,
    // cacheTime: 300000,
    // retry: 1,
  });

  const debouncedPageNo = Debounce(pageNo, 500);
const debouncedSearch = Debounce(search, 1000);

useEffect(()=>{
  setActivetab("1");
},[])

  useEffect(()=>{
    refetcheventList();
  },[refetcheventList, debouncedPageNo, debouncedSearch, recordLimit])

  // useMutation to delete event
  const {
    mutate: deleteEventMutation,
    isLoading: isEventDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-event-by-Id"],
    mutationFn: (ID) => deleteEvent({ id: ID }),
  });
  const deleteRecord = async (value) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteEventMutation(value);
          eventsList.data = eventsList.data.filter((event) => event?.id !== value);
          Swal.fire({
            title: "Deleted!",
            text: `Your ${dashboard?.[activeVariable]?.section1?.map((item) => item?.text3)} has been deleted.`,
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        queryClient.invalidateQueries(["get-events-list"]);
      });
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const openMessageModal = (event_financials, client_details) => {
    seteventfinancials(event_financials);
    setclient_details(client_details[0]);
    setModalOpen(true);
  };

  const isLoading = isEventDeleting || eventsListLoading;

  const tableHeaddings = [
    {
      name: "Sr. No.",
      // selector: (row, index) => index + 1,
      selector: (row, index) => pageNo * recordLimit + index + 1,
      maxWidth: "5px",
    },
    // {
    //   name: "Client",
    //   selector: (row) =>
    //     `${row.client_details?.[0]?.first_name} ${row.client_details?.[0]?.last_name}` || <NotAvailable />,
    // },
    {
      // name: "Client Name",
      name: "Client Username",
      selector: (row) =>
        // `${row.client_details?.[0]?.first_name} ${row.client_details?.[0]?.last_name}` || <NotAvailable />,
        `${row.client_details?.[0]?.username}` || <NotAvailable />,
    },
    {
      // name: "Event Name",
      // name: `${dashboard?.[activeVariable]?.section1?.map((item) => item?.text2).filter(Boolean).join(", ")} Name`,
      name: `${dashboard?.[activeVariable]?.section1?.map((item) => item?.text2).filter(Boolean).join(", ")} Name`,
      selector: (row) => row.event_name || <NotAvailable />,
    },
    {
      name: `${dashboard?.[activeVariable]?.section1?.map((item) => item?.text2).filter(Boolean).join(", ")} Type`,
      selector: (row) => row.event_type_name || <NotAvailable />,
    },

    {
      name: `${dashboard?.[activeVariable]?.section1?.map((item) => item?.text2).filter(Boolean).join(", ")} Date`,
      selector: (row) => formatDate(row.start_date_time) || <NotAvailable />,
    },

    {
      name: `${dashboard?.[activeVariable]?.section1?.map((item) => item?.text2).filter(Boolean).join(", ")} Status`,
      allowOverflow: true,
      cell: (row) => (
        <>
          <TableActionBar
            options1Icon="icon ni ni-square-fill"
            options1TooleTipText="Event Registered"
            options2Icon={row.package_details?.[0]?.package_name ? "icon ni ni-square-fill" : "icon ni ni-square"}
            options2TooleTipText="Payment Distribution"
            options3Icon={row.event_financials?.status === "4" ? "icon ni ni-square-fill" : "icon ni ni-square"}
            options3TooleTipText="Payment Status"
            options4Icon={
              row.package_details?.[0]?.package_name &&
              row.event_financials?.status === "4" &&
              row.start_date_time < parseDateString(new Date())
                ? "icon ni ni-square-fill"
                : "icon ni ni-square"
            }
            options4TooleTipText="Event End"
            isEventAdded={eventsList}
            isPaymentMethodDefined={!!row.package_details?.[0]?.package_name}
            isAllPaymentDone={row.event_financials?.status === "4"}
            isEventDone={row.start_date_time < parseDateString(new Date())}
          />
        </>
      ),
    },

    {
      name: "Payment Details",
      selector: (row) => (
        <Button
          style={{ backgroundColor: "white", border: "none", color: process.env.REACT_APP_THEME_COLOR }}
          onClick={() => openMessageModal(row.event_financials, row.client_details)}
        >
          View
        </Button>
      ),
    },

    {
      name: "Actions",
      allowOverflow: true,
      cell: (row) => (
        <>
          <TableActionBar
            options1Icon="eye"
            options1TooleTipText="View"
            options1onClick={() => navigate(`add-event/preview?id=${row.id}&from=previewdetail`)}
            // options1onClick={() => navigate(`/add-event?type=view&id=${row.id}&from=fromView`)}
            options2Icon="icon ni ni-edit"
            options2TooleTipText="Edit"
            options2onClick={() => navigate(`add-event?type=edit&id=${row.id}&from=fromEdit`)}
            options3Icon="icon ni ni-trash"
            options3TooleTipText="Delete"
            options3onClick={() => deleteRecord(row.id)}
            options4Icon="printer-fill"
            options4TooleTipText="Invoice"
            options4onClick={() => handleInvoiceRedirect(row.id, row.package_details?.[0]?.package_name)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      {/* <NavigationHeader heading={"Event List"} buttonInnerText={"Add Event"} route={"/add-event"} /> */}
      <section className="m-4 border p-3 bg-white">
        <ReactDataTable
          loading={isLoading}
          data={Array.isArray(eventsList?.data) ? eventsList.data : []}
          columns={tableHeaddings}
          pagination
          actions
          searchBy={`${dashboard?.[activeVariable]?.section1?.map((item) => item?.text3)}_name`}
          pageNo={setPageNo} // sending function to change page no
          // activePage={eventsList?.data?.page || 0} // sending the current page no
          activePage={eventsList?.currentPage || 0} // sending the current page no
          totalRecords={eventsList?.totalRecords || 10} // sending the total no of records on database
          recordLimit={setRecordLimit} //setting up value of no of records to display
          setSearch={setSearch} // send search value setFunction
        />
      </section>
      <PaymentDetailsModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        eventfinancials={eventfinancials}
        clientdetails={clientdetails}
      />
    </>
  );
};
export default EventListPage;
