import React, { useContext, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { DataContext } from "../../../context/DataProvider";
import { getCookie } from "../../../utils/Utils";
import { toast } from "react-toastify";

function CheckoutForm({
  selectedPlan,
  setModalData,
  invalidPlanData,
  setModal,
  modal,
  fetchPlanData,
  setModalTab,
  modalTab,
  duration_type,
}) {
  const { newPlanId, transactionid, setTransactionId } = useContext(
    DataContext
  );
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(getCookie("username"));
  const [email, setEmail] = useState(getCookie("email"));

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_API_URL}plan-selection`,
        payment_method_data: {
          billing_details: {
            name: name,
            email: email,
            // phone: phone,
          },
        },
      },
      redirect: "if_required",
    });

    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent?.status === "succeeded") {
      setTransactionId(paymentIntent.id);
      try {
        handleSubmitSubscribePayment(newPlanId, paymentIntent.id);
      } catch (err) {
        console.error("Error subscribing to plan:", err);
      }
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  const handleSubmitSubscribePayment = async (newPlanId, transactionid) => {
    const plan_id = selectedPlan?.id ? selectedPlan?.id : newPlanId;
    const note = selectedPlan?.name + " plan purchased";

    try {
      const formData = new FormData();
      formData.append("status", "3");
      formData.append("payment_txn_id", transactionid);
      formData.append("payment_response", "payment_response");
      formData.append(
        "amount",
        duration_type === "m"
          ? selectedPlan?.monthly_discount_price || selectedPlan?.monthly_price
          : selectedPlan?.yearly_discount_price || selectedPlan?.yearly_price
      );
      formData.append("validity", duration_type === "m" ? "month" : "year");
      formData.append("payer_id", "1");
      formData.append("payment_mode", "3");
      formData.append("note", note);
      formData.append("purpose", "1");
      formData.append("mode", "1");
      formData.append("plan_id", plan_id);
      const planData = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/client/subscribe-package`,
        {
          method: "POST",
          body: formData,
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );

      if (planData && planData.status) {
        const submittedData = planData?.data;
        const planData1 = await planData.json();
        setModalData(planData1);
        toast.success("Plan Purchased Successfully");
        invalidPlanData();
        setTimeout(() => {
          setModal(!modal);
          fetchPlanData();
          setModalTab(!modalTab);
        }, 1000);
      } else {
        toast.error(planData.message);
      }
    } catch (error) {
      toast.error("Something went to wrong!");
      console.log("Error fetching user data:", error);
    } finally {
    }
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />

        {/* <div className="mb-3" id="payment-element">
        <label htmlFor="name" className="form-label">Card Holder Name</label>
        <input
          type="text"
          maxLength={255}
          placeholder="Enter Card Holder Name"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="form-control"
        />
      </div>

      <div className="mb-3" id="payment-element">
        <label htmlFor="email" className="form-label">Email</label>
        <input
          type="email"
          placeholder="Enter Email"
          maxLength={255}
          id="email"
          value={email}
          onChange={handleEmailChange}
          // onChange={(e) => setEmail(e.target.value)}
          required
          className="form-control"
          
        />
      </div> */}

        <div className="d-flex justify-content-center mt-3">
          <button
            disabled={isLoading || !stripe || !elements}
            id="submit"
            className="stripebutton btn-primary"
          >
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay Now"
              )}
            </span>
          </button>
        </div>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
      {/* [DEV]: Display dynamic payment methods annotation and integration checker */}
      <div id="dpm-annotation">
        {/* <p>
          <a className="" href={dpmCheckerLink} target="_blank" rel="noopener noreferrer" id="dpm-integration-checker">Preview payment methods by transaction</a>
        </p> */}
      </div>
    </>
  );
}

export default CheckoutForm;
