import { Col, DropdownMenu, Input, Label, Row, Spinner } from "reactstrap";
import NavigationFooter from "../components/NavigationFooter";
import NavigationHeader from "../components/NavigationHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import Required from "../components/Required";
import { addPackage } from "../../http/post/postApi";
import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query";
import { editPackageDetails } from "../../http/edit/editApi";
import { useLocation, useNavigate } from "react-router";
import { getPackageList, getServices, getsitesetting } from "../../http/get/getApi";
import { logDOM } from "@testing-library/react";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { toast } from "react-toastify";
import { getChangedValues, transformData } from "../../utils/Utils";
import Select from "react-select";
import UploadImage from "../components/UploadImage";
import { useTheme } from "../../layout/provider/Theme";
import { DataContext } from "../../context/DataProvider";
import { PlanContext } from "../context/PlanContext";

const CreatePackagePage = ({ styles }) => {
  const {currency, setCurrency} = useContext(DataContext);
  const { planState, setPlanState, setHasUnsavedChanges } = useContext(PlanContext);
  // navigation declrations
  const location = useLocation();
  const navigate = useNavigate();
  const [verifyFields, setVerifyFields] = useState(false);
  const queryClient = useQueryClient();
  const [hasInvalidated, setHasInvalidated] = useState(false);
  const [eventPackage, setEventPackage]=useState(localStorage.getItem("financialroute"))

  //getting details from the url
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const from = searchParams.get("from");

  const { skin } = useTheme();

  const customStyles = {
    // Style the container
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#fff" : "#fff", // Example dark mode background
      color: "black", // Change text color
      borderColor: state.isFocused ? process.env.REACT_APP_THEME_COLOR : "#444", // Change border color when focused
      boxShadow: state.isFocused ? ` 0 0 0 1px ${process.env.REACT_APP_THEME_COLOR}` : null, // Highlight border on focus
    }),
    // Style for each option in the dropdown
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? process.env.REACT_APP_THEME_COLOR// Change background color when option is selected
        : state.isFocused
        ? "lightgray" // Change background color when option is hovered
        : null,
      color: state.isSelected ? "#ffff" : "#000000", // Change text color for selected and hovered options
      padding: 10,
    }),
    // Style for the dropdown menu
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff", // Background of dropdown
    }),
  };

  const darkModeStyles = {
    control: (provided, _state) => ({
      ...provided,
      backgroundColor: "#141c26",
      color: "white",
      // border: "1px solid #384d69",
      borderColor: _state.isFocused ? process.env.REACT_APP_THEME_COLOR : process.env.REACT_APP_THEME_COLOR,
      boxShadow: _state.isFocused ? ` 0 0 0 1px ${process.env.REACT_APP_THEME_COLOR}` : null,
    }),
    placeholder: (provided, _state) => ({ ...provided, color: "#fff", opacity: 0.7 }),
    singleValue: (provided, _state) => ({ ...provided, color: "white" }),
    valueContainer: (provided, _state) => ({ ...provided, color: "white" }),
    option: (provided, _state) => ({
      ...provided,
      // backgroundColor: "#141c26",
      backgroundColor: _state.isSelected
        ? process.env.REACT_APP_THEME_COLOR // Change background color when option is selected
        : _state.isFocused
        ? "lightgray" // Change background color when option is hovered
        : null,
      color: _state.isSelected ? "#ffff" : _state.isFocused ? "#000000" : "#fff",
      // color: "white",
      border: "1px solid #384d69",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#141c26", // Background of dropdown
    }),
  };

  const {
    data: servicesData,
    isLoading: servicesLoading,
    isError: servicesError,
  } = useQuery({
    queryKey: ["get-services"],
    queryFn: () => getServices(),
    staleTime: Infinity,
    // staleTime: 20000,
    // cacheTime: 300000,
    // retry: 1,
  });

  const servicesOptions = transformData({
    dataArray: servicesData?.data?.Services || [],
    label: "title",
    value: "id",
    isLoading: servicesLoading,
  });

  const handleSelectChange = (selectedOption) => {
    formik.setFieldValue("package_name", selectedOption ? selectedOption.label : "");
  };

  useEffect(() => {
    if (type === null) {
      formik.setValues(initialValues);
    }
  }, [type]);

  const isEditOnly = type === "edit" ? true : false;
  const isViewOnly = type === "view" ? true : false;

  //initial values for formik
  let initialValues = {
    package_id: "",
    package_name: "",
    package_price: "",
    package_deposite_price: "",
    package_overtime: "",
    package_duration: "h",
    description: "",
    package_file: "",
    package_nameCount: "",
    package_priceCount: "",
    package_deposite_priceCount: "",
    package_overtimeCount: "",
    descriptionCount: "",
    imagePresent: "",
  };

  const PackageDuration = [
    { label: "Hourly Rate", value: "h" },
    { label: "Daily Rate", value: "d" },
  ];

  //fetching package data from id
  const {
    data: packageData,
    isLoading: packageLoading,
    isError: packageIsError,
  } = useQuery({
    queryKey: ["get-package-by-id", id],
    queryFn: () => getPackageList({ id: id }),
    staleTime: Infinity,
    // staleTime: 50000,
    // cacheTime: 300000,
    // retry: 1,
    // onSuccess: (data) => {
    //   if (data.status && !hasInvalidated) {
    //     queryClient.invalidateQueries(["get-package-by-id", id]);
    //     setHasInvalidated(true);
    //   }
    // },
  });

  //Mutation hook for adding package
  const {
    mutate: addNewPackage,
    isLoading: isPackageAdding,
    error: packageAddError,
  } = useMutation({
    mutationKey: ["Add-package"],
    mutationFn: (values) => addPackage(values),
    onSuccess: (data) => {
      if (!data.errors && data.status) {
        if (window.history.length > 1) {
          if (from === "fromEdit") {
            // navigate("/package-list");
            toast.success("Package Updated Succesfully");
          } else {
            toast.success("Package Added Succesfully");
            // navigate("/package-list");
          }
        }
      } else {
        toast.warning("This Package already added");
      }
      queryClient.invalidateQueries(["get-packages-list"]);
    },
  });

  const addnewPackage = (values) => {
    try {
      if (planState === "true") {
        addNewPackage(values);
      } else {
        navigate("/plan-selection");
      }
    } catch (error) {
      console.error("Error adding package:", error);
    }
  };

  //Mutation hook for edit
  const {
    mutate: editPackageMutation,
    isLoading: isPackageEditing,
    error: packageEditError,
  } = useMutation({
    mutationKey: ["Edit-package-by-ID"],
    mutationFn: (value) => editPackageDetails(value, id),
    onSuccess: (data) => {
      if (!data.errors && data.status && !hasInvalidated) {
        queryClient.invalidateQueries(["get-package-by-id", id]);
        setHasInvalidated(true);
        // QueryClient.invalidateQueries(["get-package-by-id"]);
      }
      queryClient.invalidateQueries(["get-packages-list"]);
      // navigate("/package-list");
    },
  });
  const editPackageFromList = (value) => {
    try {
      editPackageMutation(value);
    } catch (error) {
      console.error("Error editing package:", error);
    }
  };

  //validation schema
  // const schema = Yup.object().shape({
  //   package_name: Yup.string().required("Package name field is required."),
  //   package_price: Yup.number().required("Package value field is required."),
  //   package_deposite_price: Yup.number()
  //   .nullable() // Allow it to be null or undefined
  //   .typeError("Deposit price must be a number")
  //   .test(
  //     "is-deposit-valid",
  //     "Retainer price should be less than or equal to package price",
  //     function (value) {
  //       const { package_price } = this.parent;
  //       // Only validate if deposit price is provided (not null or undefined)
  //       if (value == null || value === "") return true;
  //       return value <= package_price;
  //     }
  //   ),
  //   // package_deposite_price: Yup.string().required("Deposite value field is required."),
  //   // package_overtime: Yup.string().required("Over-time rate field is required."),
  // });

  const schema = Yup.object().shape({
    package_name: Yup.string().required("Package name field is required."),
    package_price: Yup.number().required("Package value field is required."),
    package_deposite_price: Yup.number()
      .nullable() // Allow null or undefined
      .typeError("Deposit price must be a number")
      .max(Yup.ref('package_price'), "Retainer price should be less than or equal to package price"), // Directly compare using max
  });
  

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (value) => {
      if (!isEditOnly) {
        addnewPackage(value);
      } else if (isEditOnly) {
        // editPackageFromList(value);
        editPackageFromList(getChangedValues(packageData?.data?.packages[0], formik.values));
      }
    },
  });


  //to set the values from the api when form is opened  edit type
  useEffect(() => {
    if (isEditOnly || isViewOnly) {
      formik.setValues((prevData) => {
        return {
          ...prevData,
          ...packageData?.data.packages[0],
        };
      });
    }
  }, [isEditOnly, packageLoading, packageData]);

  useEffect(() => {
    if (
      formik.values.package_name !== "" &&
      formik.values.package_price !== ""
      // formik.values.package_deposite_price !== "" &&
      // formik.values.package_overtime !== ""
    ) {
      setVerifyFields(true);
    } else {
      setVerifyFields(false);
    }
  }, [formik.values]);

  // const handelValidation = (callbackFun) => {
  //   if (formik.values.package_name === "") {
  //     formik.setFieldTouched("package_name", true);
  //   }

  //   if (formik.values.package_price === "") {
  //     formik.setFieldTouched("package_price", true);
  //   }
    

  //   if (formik.values.package_name !== "" && formik.values.package_price !== "" && formik.values.package_deposite_price > formik.values.package_price) {
  //     callbackFun(true);
  //   }

  //   if (formik.values.package_deposite_price > formik.values.package_price) {
  //     toast.error("Retainer price should be less than package price");
  //     return;
  //   }

  //   // if (formik.values.package_overtime > formik.values.package_deposite_price) {
  //   //   toast.error("Overtime price should be less than retainer price");
  //   //   return;
  //   // }
  // };


  // const handelValidation = (callbackFun) => {
  //   formik.setFieldTouched("package_name", true);
  //   formik.setFieldTouched("package_price", true);
  //   formik.setFieldTouched("package_deposite_price", true); // Keep touched for feedback
  
  //   // Only check if package_deposite_price is provided
  //   if (
  //     formik.values.package_deposite_price &&
  //     formik.values.package_deposite_price > formik.values.package_price
  //   ) {
  //     toast.error("Retainer price should be less than package price");
  //     return; // Prevent saving if validation fails
  //   }
  
  //   // Proceed if all other required fields are filled and the validation passes
  //   if (
  //     formik.values.package_name !== "" &&
  //     formik.values.package_price !== "" &&
  //     (formik.values.package_deposite_price == null || 
  //       formik.values.package_deposite_price <= formik.values.package_price)
  //   ) {
  //     callbackFun(true); // Submit form
  //   }
  // };

  const handelValidation = async (callbackFun) => {
    formik.setFieldTouched("package_name", true);
    formik.setFieldTouched("package_price", true);
    formik.setFieldTouched("package_deposite_price", true);
  
    const errors = await formik.validateForm(); // Validate all fields using Yup schema
  
    // If there are no errors, proceed to the callback
    if (Object.keys(errors).length === 0) {
      callbackFun(true);
    } else {
      // If validation fails, show error if deposit price condition fails
      if (errors.package_deposite_price) {
        toast.error(errors.package_deposite_price); // Show specific validation error
      }
    }
  };
  
  
  
  

  const [showEventTypeSelect, setShowEventTypeSelect] = useState(false);
  const eventTypeDropdownRef = useRef(null);
  const [dropDownData, setDropDownData] = useState(false);
  const [fitlerCategory, setFitlerCategory] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (eventTypeDropdownRef.current && !eventTypeDropdownRef.current.contains(event.target)) {
        setShowEventTypeSelect(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputClick = () => {
    // if (inputType === "CategoryType") {
    setShowEventTypeSelect(true);
    // }
  };

  const handleSelect = (name) => {
    formik.setFieldValue("package_name", name.title);
    // setFitlerCategory(name.id);
    setShowEventTypeSelect(false);
  };

  const handlepackageDescription = (e) => {
    const { value } = e.target;
    let wordCount = 0;

    // Check if the input is not empty
    if (value.trim() !== "") {
      wordCount = value.trim().split(/\s+/).length;
    }

    formik.setFieldValue("description", wordCount);
    // setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const maxLength = 500;
  const currentLength = formik?.values?.description?.length ? formik?.values?.description?.length : 0;

  const handleInputChange = (field, e) => {
    const { value } = e.target;

    // Calculate word count for the specific field
    let wordCount = 0;
    if (value.trim() !== "") {
      wordCount = value.trim().split(/\s+/).length;
    }

    // Update the field's value and its word count in Formik
    formik.setFieldValue(field, value);
    formik.setFieldValue(`${field}Count`, wordCount);
  };



  return (
    <>
      <NavigationHeader
        // heading={id ? " Edit Package" : "Add New Package"}
        heading={from === "fromEdit" ? " Edit Package" : from === "fromView" ? "Package Details" : "Add Package"}
        buttonInnerText={"Back"}
        route={-1}
        // route={"/package-list"}
        back
      />

      {packageLoading && isEditOnly ? (
        <div className="ps-3 mt-5">
          <Row>
            <Col md={3} className=" pt-4 bg-white">
              <ShimmerThumbnail height={40} width={350} rounded />
            </Col>
            <Col md={3} className=" pt-4 bg-white">
              <ShimmerThumbnail height={40} width={350} rounded />
            </Col>
            <Col md={3} className=" pt-4 bg-white">
              <ShimmerThumbnail height={40} width={350} rounded />
            </Col>
            <Col md={3} className=" pt-4 bg-white">
              <ShimmerThumbnail height={40} width={350} rounded />
            </Col>
          </Row>
        </div>
      ) : (
        <section className="m-4 mt-2 border p-3 bg-white" style={{ height: "auto" }}>
          <Row className="p-2 pb-0">
            {/* First Section */}
            <Col md={6} className="p-3 bg-white">
              <Row className="g-2">
                <Col md={6}>
                  {/* <div>
                    <Label for="packageName">
                      Enter Service / Package Name
                      <Required />
                    </Label>
                    <Select
                      id="package_name"
                      name="package_name"
                      options={servicesOptions}
                      value={servicesOptions.find((option) => option.label === formik.values.package_name) || null}
                      onChange={handleSelectChange}
                      isClearable
                      isDisabled={isViewOnly}
                      placeholder="Select an option..."
                    />
                    {formik.errors.package_name && formik.touched.package_name && (
                      <p style={{ color: "red" }}> {formik.errors.package_name}</p>
                    )}
                  </div> */}
                  <Label for="packageName">
                    Enter Service / Package Name
                    <Required />
                  </Label>
                  <Input
                    size="sm"
                    type="text"
                    name="package_name"
                    maxLength={50}
                    value={formik.values.package_name}
                    className="rounded-start flex-grow-1 rounded-0"
                    placeholder="Enter Package Name."
                    onClick={() => handleInputClick("CategoryType")}
                    onChange={(e) => {
                      // setInput(e.target.value);
                      // formik.setFieldValue("package_name", e.target.value);
                      setDropDownData(true);
                      handleInputChange("package_name", e);
                    }}
                    autoComplete="off"
                  />
                  {formik.errors.package_name && formik.touched.package_name && (
                    <p style={{ color: "red" }}> {formik.errors.package_name}</p>
                  )}
                  {showEventTypeSelect && (
                    <div
                      ref={eventTypeDropdownRef}
                      className="position-absolute h-200px overflow-auto mt-1"
                      style={{ zIndex: 1, width: "290px", scrollbarWidth: "none" }}
                    >
                      <DropdownMenu className="d-flex flex-wrap p-1 border">
                        {servicesLoading ? (
                          <div className="mx-1 align-center">
                            <Spinner size="sm" color="light" />
                            <span className="px-1">Loading...</span>
                          </div>
                        ) : servicesError ? (
                          <div>Error loading services. Please try again.</div>
                        ) : servicesData?.data?.Services?.length ? (
                          servicesData?.data?.Services?.map((category, index) => (
                            <div
                              key={index}
                              className="dropdown-item"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleSelect(category)}
                            >
                              {category.title}
                            </div>
                          ))
                        ) : (
                          <div>No Data Found</div>
                        )}
                      </DropdownMenu>
                      {formik.errors.package_name && formik.touched.package_name && (
                        <p style={{ color: "red" }}> {formik.errors.package_name}</p>
                      )}
                    </div>
                  )}
                </Col>
                <Col md={6}>
                  <Label for="packageValue">
                    Enter Service / Package Price
                    <Required />
                  </Label>
                  <div className="input-group">
                    <div className="input-group-append">
                      {/* <span className="input-group-text">$</span> */}
                      <span className="input-group-text">{currency}</span>
                    </div>
                    <Input
                      readOnly={isViewOnly}
                      id="package_price"
                      type="number"
                      placeholder="Package Price"
                      className="w-35"
                      name="package_price"
                      min={0}
                      value={formik.values.package_price}
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 5) {
                          // formik.handleChange(e);
                          handleInputChange("package_price", e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      invalid={formik.errors.package_price && formik.touched.package_price}
                    />
                    <Select
                      id="timeType"
                      name="timeType"
                      value={PackageDuration.find((option) => option.value === formik.values.package_duration)}
                      onChange={(option) => formik.setFieldValue("package_duration", option.value)}
                      options={PackageDuration}
                      styles={skin === "dark" ? { ...darkModeStyles, ...styles } : customStyles}
                    />
                    {/* <Input
                      type="select"
                      name="timeType"
                      id="timeType"
                      className="rounded-end flex-shrink-1"
                      onChange={(e) => formik.setFieldValue("package_duration", e.target.value)}
                      value={formik.values.package_duration || "Hourly"}
                      styles={skin === "dark" ? { ...darkModeStyles, ...styles } : customStyles}
                    >
                      <option value="h">Hourly</option>
                      <option value="d">Daily</option>
                    </Input> */}
                    {formik.errors.package_price && formik.touched.package_price && (
                      <p style={{ color: "red" }}> {formik.errors.package_price}</p>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>

            {/* Second Section */}
            <Col md={6} className="p-3 bg-white border-start-0">
              <Row className="g-2">
                <Col md={6}>
                  <Label for="depositeValue">
                    Enter Retainer Price
                    {/* <Required /> */}
                  </Label>
                  <div className="input-group">
                    <div className="input-group-append">
                      {/* <span className="input-group-text">$</span> */}
                      <span className="input-group-text">{currency}</span>
                    </div>
                    <Input
                      readOnly={isViewOnly}
                      min={0}
                      id="package_deposite_price"
                      type="number"
                      placeholder="Retainer Price"
                      name="package_deposite_price"
                      value={formik.values.package_deposite_price}
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 5) {
                          // formik.handleChange(e);
                          handleInputChange("package_deposite_price", e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      invalid={formik.errors.package_deposite_price && formik.touched.package_deposite_price}
                    />
                    {formik.errors.package_deposite_price && formik.touched.package_deposite_price && (
                      <p style={{ color: "red" }}> {formik.errors.package_deposite_price}</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <Label for="overTimeRate">
                    Enter Overtime Price
                    {/* <Required /> */}
                  </Label>
                  <div className="input-group">
                    <div className="input-group-append">
                      {/* <span className="input-group-text">$</span> */}
                      <span className="input-group-text">{currency}</span>
                    </div>
                    <Input
                      readOnly={isViewOnly}
                      min={0}
                      id="package_overtime"
                      type="number"
                      placeholder="Overtime Price"
                      name="package_overtime"
                      value={formik.values.package_overtime}
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 5) {
                          // formik.handleChange(e);
                          handleInputChange("package_overtime", e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      invalid={formik.errors.package_overtime && formik.touched.package_overtime}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">/ hour</span>
                    </div>
                    {/* {formik.errors.package_overtime && formik.touched.package_overtime && (
                      <p style={{ color: "red" }}> {formik.errors.package_overtime}</p>
                    )} */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={3} className="p-4 mt-0">
              <UploadImage
                label="Upload Package Picture"
                name="package_file"
                isViewOnly={isViewOnly}
                formik={formik}
                setHasUnsavedChanges={setHasUnsavedChanges}
              />
              {formik.errors.package_file && formik.touched.package_file && (
                <p style={{ color: "red" }}>{formik.errors.package_file}</p>
              )}
            </Col>
            <Col md={6} className="p-4 mt-0">
              <Label for="description">
                Package Description
                {/* <Required /> */}
              </Label>
              <textarea
                readOnly={isViewOnly}
                // className="form-control form-control-sm"
                className={`form-control form-control-sm ${
                  formik.errors.description && formik.touched.description ? "is-invalid" : ""
                }`}
                placeholder="Write about your package description"
                maxLength={maxLength}
                id="description"
                name="description"
                value={formik.values.description}
                // onChange={handlepackageDescription}
                onChange={(e) => handleInputChange("description", e)}
                invalid={formik.errors.description && formik.touched.description}
              />
              <div className="mt-1 justify-end" style={{ fontSize: "0.875rem", color: "#6c757d" }}>
                {currentLength}/{maxLength}
              </div>
              {/* {formik.errors.uniqueness && formik.touched.uniqueness && (
                    <p style={{ color: "red" }}>{formik.errors.uniqueness}</p>
                  )} */}
            </Col>
          </Row>
          {isViewOnly ? (
            ""
          ) : (
            <NavigationFooter
              lastPage={"1"}
              currentState={"1"}
              formik={formik}
              disableSave={verifyFields}
              isPackageAdding={isPackageAdding}
              validationFunction={handelValidation}
              isPackageEditing={isPackageEditing}
              navigateRoute={eventPackage ? eventPackage : "/package-list"}
              emailAvialable={true}
              usernameAvailable={true}
            />
          )}
        </section>
      )}
    </>
  );
};
export default CreatePackagePage;
