import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { TooltipComponent, Icon } from "../../components/Component";
import { useTheme } from "../provider/Theme";
import LogoFetch from "../../pages/prebuilt/Logo Separation/LogoFetch";
import sidebar1 from "../../pages/Photography/Sidebar/Sidebar"; // Static sidebar fallback

// API endpoint
const API_URL = `${process.env.REACT_APP_API_URL}api/v1/public/get-sidebar-menu`;

const Appbar = () => {
  const theme = useTheme();

  const [sidebarData, setSidebarData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch sidebar data from API
  useEffect(() => {
    const fetchSidebarData = async () => {
      try {
        const response = await fetch(API_URL);
        const data = await response.json();
        if (data.status && data.data.sidebarMenus) {
          setSidebarData(data.data.sidebarMenus);
        } else {
          console.warn("Using static sidebar as fallback.");
          setSidebarData(sidebar1); // Fallback to static data
        }
      } catch (error) {
        console.error("Error fetching sidebar:", error);
        setSidebarData(sidebar1); // Fallback to static data on error
      } finally {
        setLoading(false);
      }
    };

    fetchSidebarData();
  }, []);

  // CSS class management with `classNames`
  const appSidebarClass = classNames({
    "nk-apps-sidebar": true,
    [`is-light`]: theme.appbar === "white",
    [`is-${theme.appbar}`]:
      theme.appbar !== "white" && theme.appbar !== "light",
    // [`is-${theme.appbar}`]: theme.appbar !== "white" && theme.appbar !== "light",
  });

  // Render sidebar items dynamically
  const renderSidebarItems = () =>
    sidebarData.map((item, idx) => (
      <>
        <TooltipComponent
          id={"dashboard" + idx}
          text={item?.label}
          direction="right"
        />
        <li
          key={item.id}
          className={`nk-menu-item ${
            window.location.pathname == `/${item.route}`
              ? "active current-page border-start border-3"
              : ""
          }`}
          id={"dashboard" + idx}
        >
          <Link
            to={`${process.env.REACT_APP_PUBLIC_URL}${
              item.link || item.route || ""
            }`}
            className="nk-menu-link"
            id={item.id}
          >
            <span className="nk-menu-icon" id={"dashboard" + idx}>
              <Icon name={item.icon} />
            </span>
            <span className="nk-menu-text">{item.label || item.text}</span>
          </Link>
        </li>
      </>
    ));

  if (loading) {
    return <div>Loading sidebar...</div>;
  }

  return (
    <div className={appSidebarClass}>
      <LogoFetch />
      <div className="nk-sidebar-element">
        <div className="nk-sidebar-body">
          <SimpleBar className="nk-sidebar-content">
            <div className="nk-sidebar-menu">
              <ul className="nk-menu apps-menu">{renderSidebarItems()}</ul>
            </div>
          </SimpleBar>
        </div>
      </div>
    </div>
  );
};

export default Appbar;
