import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button } from "reactstrap";
import NavigationHeader from "../components/NavigationHeader";
import TableActionBar from "../components/TableActionBar";
import { useQuery, useMutation, queryClient, useQueryClient } from "react-query";
import { deleteEnquiry } from "../../http/delete/deleteApi";
import Swal from "sweetalert2";
import MessageModal from "./MessageModal"; // Import the modal component
import EnquiryDataTable from "./EnquiryDataTable";
import SendMessage from "./SendMessage";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import dashboard from "../../Jsonfiles/Dashboard.json"

import { getEnquiryList } from "../../http/get/getApi";
import { sendMessage } from "../../http/edit/editApi";
import ReportUser from "./ReportUser";
import { ReactDataTable } from "../../components/Component";
import Debounce from "../../utils/Debounce";

export const NotAvailable = () => <div style={{ textAlign: "center" }}>----</div>;

const EnquiryList = () => {
  const navigate = useNavigate();
  const activeVariable = getActiveEnvironmentVariable();
  const queryClient = useQueryClient();

  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({
    enquiry_message: "",
    enquiry_reply: "",
  });
  const [reportUserModalOpen, setReportUserModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [reportUser, setReportUser] = useState(null);

  // Fetching enquiry data
  const {
    data: enquiryList,
    isLoading: eventsListLoading,
    isError: eventsListError,
    refetch: refetchEnquirylist,
  } = useQuery({
    queryKey: ["get-inquiry-list", pageNo, recordLimit, search],
    queryFn: () => getEnquiryList({ id: "", page: pageNo, limit: recordLimit, search: search }),
    enabled: false,
    // staleTime: Infinity,
    // staleTime: 60000,
    // cacheTime: 300000,
    // retry: 1,
  });

  const debouncedPageNo = Debounce(pageNo, 500);
  const debouncedSearch = Debounce(search, 1000);

  useEffect(() => {
    refetchEnquirylist();
  }, [refetchEnquirylist, debouncedPageNo, debouncedSearch, recordLimit]);

  // useMutation to delete event
  const {
    mutate: deleteEventMutation,
    isLoading: isEventDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-event-by-Id"],
    mutationFn: (ID) => deleteEnquiry({ id: ID }),
    onSuccess: () => {
      queryClient.invalidateQueries(["get-inquiry-list"]);
    },
  });

  const deleteRecord = async (value) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteEventMutation(value);
          enquiryList.data = enquiryList.data.filter((event) => event?.id !== value);
          Swal.fire("Deleted!", `Your ${dashboard?.[activeVariable]?.section1?.map((item) => item?.text3)} has been deleted.`, "success");
        }
      });
    } catch (error) {
      console.error(`Error deleting ${dashboard?.[activeVariable]?.section1?.map((item) => item?.text3)}:`, error);
    }
  };

  const isLoading = isEventDeleting || eventsListLoading;

  const openMessageModal = (message, reply) => {
    console.log("message", message, reply);
    setSelectedMessage({
      enquiry_message: message !== "null" ? message : "-",
      enquiry_reply: reply !== "null" ? reply : "-",
    });
    setModalOpen(true);
  };

  const openSendMessageModal = (event) => {
    setSelectedEvent(event);
    setNotificationModalOpen(true);
  };

  const openReportUserModal = (event) => {
    setReportUser(event);
    setReportUserModalOpen(true);
  };

  // Mutation for sending message
  const { mutate: sendMessageMutation, isLoading: isSending } = useMutation(sendMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries(["get-inquiry-list"]);
      Swal.fire({
        title: "Success!",
        text: "Message sent successfully!",
        icon: "success",
        showConfirmButton: false,
        // confirmButtonText: 'OK',
        timer: 2000,
        willClose: () => {
          setNotificationModalOpen(false);
        },
      });
    },
    onError: (error) => {
      Swal.fire({
        title: "Error!",
        text: `Failed to send message: ${error.message}`,
        icon: "error",
        confirmButtonText: "OK",
      });
    },
  });

  const handleSendMessage = (message) => {
    if (selectedEvent) {
      sendMessageMutation({
        id: selectedEvent.id,
        name: selectedEvent.name,
        phone_number: selectedEvent.phone_number,
        email: selectedEvent.email,
        event_name: selectedEvent.event_name,
        event_package: selectedEvent.event_package,
        event_type: selectedEvent.event_type,
        enquiry_message: selectedEvent.enquiry_message,
        message: message,
      });
    }
  };

  // User Report To Admin
  const { mutate: reportUserMutation, isLoading: isSending1 } = useMutation(sendMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries(["get-inquiry-list"]);
      Swal.fire({
        title: "Success!",
        text: "Report sent successfully!",
        icon: "success",
        // confirmButtonText: 'OK',
        showConfirmButton: false,
        timer: 2000,
        willClose: () => {
          setNotificationModalOpen(false);
          setReportUserModalOpen(false);
        },
      });
    },
    onError: (error) => {
      Swal.fire({
        title: "Error!",
        text: `Failed to send Report message: ${error.message}`,
        icon: "error",
        confirmButtonText: "OK",
      });
    },
  });

  const reportUserMutation1 = (report_message) => {
    if (reportUser) {
      reportUserMutation({
        id: reportUser.id,
        name: reportUser.name,
        phone_number: reportUser.phone_number,
        email: reportUser.email,
        event_name: reportUser.event_name,
        event_package: reportUser.event_package,
        event_type: reportUser.event_type,
        enquiry_message: reportUser.enquiry_message,
        report_message: report_message,
      });
    }
  };

  const tableHeadings = [
    {
      name: "Sr. No.",
      // selector: (row, index) => index + 1,
      selector: (row, index) => pageNo * recordLimit + index + 1,
      maxWidth: "5px",
    },
    {
      name: "Client Name",
      selector: (row) => row?.name || <NotAvailable />,
    },
    {
      name: "Email",
      selector: (row) => row?.email || <NotAvailable />,
    },
    {
      name: "Contact No.",
      selector: (row) => row?.phone_number || <NotAvailable />,
    },
    {
      name: "Event Type",
      // selector: (row) => row.event_type || <NotAvailable />,
      selector: (row) => row?.event_type_title || <NotAvailable />,
    },
    {
      name: "Event Name",
      selector: (row) => row?.event_name || <NotAvailable />,
    },
    {
      name: "Message",
      selector: (row) => (
        <Button
          style={{ backgroundColor: "white", border: "none", color: "green" }}
          onClick={() => openMessageModal(row.enquiry_message, row.reply)}
        >
          View
        </Button>
      ),
    },
    {
      name: "Action",
      allowOverflow: true,
      cell: (row) => (
        <>
          <TableActionBar
            options1Icon="icon ni ni-send"
            options1TooleTipText="Send Message"
            options1onClick={() => openSendMessageModal(row)}
            options2Icon="report"
            options2TooleTipText="Client Report"
            options2onClick={() => openReportUserModal(row)}
            options3Icon="icon ni ni-trash"
            options3TooleTipText="Delete"
            options3onClick={() => deleteRecord(row.id)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <NavigationHeader heading={"Inquiry List"} />
      <section className="m-4 border p-3 bg-white">
        <ReactDataTable
          loading={isLoading}
          data={Array.isArray(enquiryList?.data) ? enquiryList.data : []}
          datacount={enquiryList}
          columns={tableHeadings}
          pagination
          actions
          // searchBy={"event_name"}
          searchBy={"name"}
          pageNo={setPageNo} // sending function to change page no
          activePage={enquiryList?.pagination?.current_page || 0} // sending the current page no
          totalRecords={enquiryList?.pagination?.total || 10} // sending the total no of records on database
          recordLimit={setRecordLimit} //setting up value of no of records to display
          setSearch={setSearch} // send search value setFunction
        />
      </section>
      <MessageModal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} enquiry_message={selectedMessage} />
      <SendMessage
        isOpen={notificationModalOpen}
        toggle={() => setNotificationModalOpen(!notificationModalOpen)}
        onSendMessage={handleSendMessage}
        isSending={isSending}
      />
      <ReportUser
        isOpen={reportUserModalOpen}
        toggle={() => setReportUserModalOpen(!reportUserModalOpen)}
        onSendMessage={reportUserMutation1}
        reportUser={reportUser}
        isSending={isSending1}
      />
    </>
  );
};

export default EnquiryList;
