import React, { useContext } from "react";
import classNames from "classnames";
import Icon from "../icon/Icon";
import { DataContext } from "../../context/DataProvider";
import profileimg from "../../../src/assets/images/profileimg.png";

// Aviable sizes {xs,sm,md,lg,xl}
/* Aviable theme {blue-dim, blue, azure-dim, azure, indigo-dim, indigo, purple-dim, purple, pink-dim, pink, orange-dim, orange, teal-dim, teal, 
    primary-dim, primary, secondary-dim, secondary, success-dim, success, info-dim, info, warning-dim, warning, danger-dim, danger, dark-dim, dark, gray-dim, gray, lighter, light} */

const UserAvatar = ({
  className,
  size,
  theme,
  icon,
  text,
  image,
  id,
  imageAlt,
  ...props
}) => {
  // if in future we got the requirnment that we need to show default dj image ->  if dj dont have image , then just import default dj image in this component and use that image in the <img src={defaultImage}>

  const classes = classNames({
    "user-avatar": true,
    [`${className}`]: className,
    [`user-avatar-${size}`]: size,
    [`bg-${theme}`]: theme,
  });

  // const handleImageError = (e) => {
  //   e.target.src = profileimg; // Set fallback image if userProfile image fails to load
  // };

  return (
    // <div className={classes} {...props}>
    //   {icon && !userProfile ? <Icon name={icon} /> : null}
    //   {userProfile || profileimg ? (
    //     <img
    //       src={userProfile}
    //       alt={imageAlt || "Profile"} // Default alt text if imageAlt is not provided
    //       onError={handleImageError} // Fallback in case image fails to load
    //       className="user-avatar" // Add a class for custom styling
    //       // className="user-avatar-img" // Add a class for custom styling
    //     />
    //   ) : (
    //     text && !userProfile && <span>{text}</span> // Show text if no image
    //   )}
    //   {props.children}
    // </div>

    <div className={classes} {...props} >
      <span>{text}</span>
      {props.children}
    </div>
  );
};

export default UserAvatar;
