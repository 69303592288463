import React, { useState, useEffect, useRef } from "react";
import { Input, Row, Col, Label, DropdownMenu, Spinner } from "reactstrap";
import DatePicker from "react-datepicker";
import NavigationFooter from "../components/NavigationFooter";
import Required from "../components/Required";
import { toast } from "react-toastify";
import ReactSelect from "../../components/ReactSelect/ReactSelect";
import { getEventType, getAttire } from "../../http/get/getApi";
import { useQuery, useQueryClient } from "react-query";
import { parseDateString, parseDateStringDisabled, transformData } from "../../utils/Utils";
import { ShimmerThumbnail } from "react-shimmer-effects";
import dashboard from "../../Jsonfiles/Dashboard.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const EDetails = ({
  toggleMain,
  mainCurrentTab,
  formik,
  disabled,
  eventsListLoading,
  isEventAdding,
  isViewOnly,
  isEventEditing,
}) => {
  const activeVariable = getActiveEnvironmentVariable();
  const [isEventDateSelected, setIsEventDateSelected] = useState(false);

  const [categoryOptions, setCategoryOption] = useState("");

  //fetching eventTypes data
  const {
    data: eventType,
    isLoading: eventtypeLoading,
    isError: eventTypeError,
    // refetch: refetchEventType,
  } = useQuery({
    queryKey: ["get-event-type"],
    queryFn: () => getEventType(),
    // enabled: false
    // staleTime: 50000
    staleTime: Infinity
  });

  const eventTypeOption = transformData({
    dataArray: eventType?.data?.Category || [],
    label: "title",
    value: "id",
    isLoading: eventtypeLoading,
  });

  const {
    data: attire,
    isLoading: attireLoading,
    isError: attireError,
    // refetch: refetchAttire,
  } = useQuery({
    queryKey: ["get-attire"],
    queryFn: () => getAttire({ type: "2" }),
    // staleTime: 50000,
    staleTime: Infinity,
    // enabled: false,
  });

  const attireOptions = transformData({
    dataArray: attire?.data?.attire || [],
    label: "name",
    value: "id",
    isLoading: attireLoading,
  });

  useEffect(()=>{
    // refetchEventType();
    // refetchAttire();
  },[])

  function handleEventType(e) {
    formik.setFieldValue("event_type", e.value);
  }

  function handleAttire(e) {
    formik.setFieldValue("attire", e.value);
  }

  //validation for the next button in the form
  const handelValidation = (callbackFun) => {
    if (formik.values.event_name === "") {
      formik.setFieldTouched("event_name", true);
    }
    if (formik.values.event_type === "") {
      formik.setFieldTouched("event_type", true);
    }
    if (formik.values.event_type !== "") {
      formik.setFieldTouched("event_type", false);
    }

    if (formik.values.eventDate === "") {
      formik.setFieldTouched("eventDate", true);
    }
    if (formik.values.startTime === "") {
      formik.setFieldTouched("startTime", true);
    }
    if (formik.values.end_date_time === "") {
      formik.setFieldTouched("end_date_time", true);
    }
    if (formik.values.startTime <= formik.values.setup_time && formik.values.startTime !== "") {
      toast.error("Start time must be greater than setup time");
      return;
    }

    if (formik.values.end_date_time <= formik.values.startTime && formik.values.end_date_time !== "") {
      toast.error("End time must be greater than start time");
      return;
    }

    if (
      formik.values.event_name !== "" &&
      formik.values.event_type !== "" &&
      formik.values.eventDate !== "" &&
      formik.values.startTime !== "" &&
      formik.values.end_date_time !== ""
    ) {
      callbackFun(true);
    }
  };

  // Function to handle event date selection
  const handleEventDateChange = (date) => {
    formik.setFieldValue("eventDate", date ? date.toISOString() : "");
    setIsEventDateSelected(true);
  };

  useEffect(() => {
    let temp = formik.values.eventDate;
    if (temp) setIsEventDateSelected(true);
  }, [formik]);

  // Function to adjust time while keeping the date part unchanged
  const adjustTimeWithStartDate = (time, startDate) => {
    const adjustedTime = new Date(startDate);
    adjustedTime.setHours(time.getHours());
    adjustedTime.setMinutes(time.getMinutes());
    return adjustedTime;
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  //event listener  to stop the scrolling behavior
  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number" && document.activeElement.classList.contains("noscroll")) {
      document.activeElement.blur();
    }
  });

  const [showEventTypeSelect, setShowEventTypeSelect] = useState(false);
  const eventTypeDropdownRef = useRef(null);
  const [dropDownData, setDropDownData] = useState(false);
  const [fitlerCategory, setFitlerCategory] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (eventTypeDropdownRef.current && !eventTypeDropdownRef.current.contains(event.target)) {
        setShowEventTypeSelect(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputClick = () => {
    // if (inputType === "CategoryType") {
    setShowEventTypeSelect(true);
    // }
  };

  const handleSelect = (name) => {
    formik.setFieldValue("attire", name.label);
    // setFitlerCategory(name.id);
    setShowEventTypeSelect(false);
  };

  const handleInputChange = (field, e) => {
    const { value } = e.target;

    // Calculate word count for the specific field
    let wordCount = 0;
    if (value.trim() !== "") {
      wordCount = value.trim().split(/\s+/).length;
    }

    // Update the field's value and its word count in Formik
    formik.setFieldValue(field, value);
    formik.setFieldValue(`${field}Count`, wordCount);
  };

  return (
    <>
      <Row className="p-2 py-0">
        {/* First Section */}
        <Col md={6} className="border p-3 bg-white">
          {/* First row */}
          <Row className="g-2">
            <Col md={6}>
              {disabled && eventsListLoading ? (
                <ShimmerThumbnail height={40} width={250} rounded />
              ) : (
                <div>
                  <Label for="eventName">
                    {/* Event */}
                    {dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)}{" "}
                     Name
                    <Required />
                  </Label>
                  <Input
                    readOnly={isViewOnly}
                    type="text"
                    id="event_name"
                    name="event_name"
                    disabled={disabled}
                    value={formik.values.event_name}
                    // placeholder="Enter Event name"
                    placeholder={`Enter ${dashboard?.[activeVariable]?.section1?.map((item) => item?.text2).filter(Boolean).join(", ")} name`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.event_name && formik.touched.event_name}
                    autoComplete="off"
                  />
                  {formik.errors.event_name && formik.touched.event_name && (
                    <p style={{ color: "red" }}> {formik.errors.event_name}</p>
                  )}
                </div>
              )}
            </Col>
            <Col md={6}>
              <div className="form-control-wrap">
                <>
                  {!eventtypeLoading && (
                    <ReactSelect
                      disabled={isViewOnly}
                      setCategoryOption={setCategoryOption}
                      options={eventTypeOption}
                      addOpetionsForm
                      label= {dashboard?.[activeVariable]?.section1?.map((item) => item?.text4)} 
                      // api="https://api.ipartydjcrm.com/api/v1/events/add-categories"
                      required
                      id="event_type"
                      name="event_type"
                      postId="1"
                      value={formik.values.event_type}
                      onChange={(e) => handleEventType(e)}
                      onBlur={() => formik.setFieldTouched("event_type", true)}
                      invalid={formik.errors.event_type && formik.touched.event_type}
                    />
                  )}
                  {formik.errors.event_type && formik.touched.event_type && (
                    <p style={{ color: "red" }}> {formik.errors.event_type}</p>
                  )}
                </>
              </div>
            </Col>
          </Row>
          {/* Second Row */}
          <Row className="mt-2 g-2">
            <Col md={6}>
              {/* {!attireLoading && (
                <ReactSelect
                  disabled={isViewOnly}
                  setCategoryOption={setCategoryOption}
                  id="attire"
                  label="Attire"
                  // api="https://api.ipartydjcrm.com/api/v1/events/add-categories"
                  name="attire"
                  options={attireOptions}
                  // disabled={disabled}
                  value={formik.values.attire}
                  onChange={(e) => handleAttire(e)}
                  onBlur={() => formik.setFieldTouched("attire", true)}
                  addOpetionsForm
                  postId="2"
                />
              )} */}
              <Label for="packageName">
                Attire
                {/* <Required /> */}
              </Label>
              <Input
                size="sm"
                type="text"
                name="attire"
                maxLength={50}
                value={formik.values.attire}
                className="rounded-start flex-grow-1 rounded-0"
                placeholder="Enter the Attire Name"
                onClick={() => handleInputClick("CategoryType")}
                onChange={(e) => {
                  // setInput(e.target.value);
                  formik.setFieldValue("attire", e.target.value);
                  setDropDownData(true);
                }}
                autoComplete="off"
              />
              {showEventTypeSelect && (
                <div
                  ref={eventTypeDropdownRef}
                  className="position-absolute h-200px overflow-auto mt-1 dropdown-item custom-dropdown-item"
                  style={{ zIndex: 1, width: "290px", scrollbarWidth: "none" }}
                >
                  <DropdownMenu className="d-flex flex-wrap p-1 border">
                    {attireLoading ? (
                      <div className="mx-1 align-center">
                        <Spinner size="sm" color="light" />
                        <span className="px-1">Loading...</span>
                      </div>
                    ) : attireError ? (
                      <div>Error loading Attire. Please try again.</div>
                    ) : attireOptions?.length ? (
                      attireOptions?.map((attire, index) => (
                        <div
                          key={index}
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSelect(attire)}
                        >
                          {" "}
                          {attire.label}
                        </div>
                      ))
                    ) : (
                      <div>No Data Found</div>
                    )}
                  </DropdownMenu>
                </div>
              )}
            </Col>
            <Col md={6}>
              <div>
                <Label for="guestCount">Guest Count</Label>
                <Input
                  readOnly={isViewOnly}
                  type="number"
                  id="guest_count"
                  name="guest_count"
                  className="noscroll"
                  disabled={disabled}
                  min={0}
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  value={formik.values.guest_count}
                  placeholder="Guest Count"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 10) {
                      // formik.handleChange(e);
                      handleInputChange("guest_count", e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>
            </Col>
          </Row>
        </Col>
        {/* Second Section */}
        <Col md={6} className="border p-3 bg-white border-start-0">
          {/* First row */}
          <Row className="g-2">
            <Col md={6}>
              <div>
                <Label for="eventDate">
                  {/* Event  */}
                  {dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)}{" "}
                  Date
                  <Required />
                </Label>
                <DatePicker
                  readOnly={isViewOnly}
                  isClearable={isEventDateSelected && !isViewOnly}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormatCalendar="MMMM"
                  placeholderText=" Select Event Date"
                  minDate={tomorrow}
                  id="eventDate"
                  name="eventDate"
                  disabled={disabled}
                  selected={formik.values?.eventDate ? new Date(formik.values.eventDate) : ""}
                  dateFormat={"dd/MM/yy"}
                  onChange={handleEventDateChange}
                  onBlur={() => formik.setFieldTouched("eventDate", true)}
                  className="form-control date-picker"
                  invalid={formik.errors.eventDate && formik.touched.eventDate}
                  autoComplete="off"
                />
                {formik.errors.eventDate && formik.touched.eventDate && (
                  <p style={{ color: "red" }}> {formik.errors.eventDate}</p>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div>
                <Label for="setupTime">Setup Time</Label>
                <DatePicker
                  readOnly={isViewOnly}
                  placeholderText="Enter Setup Time"
                  name="setup_time"
                  selected={formik.values.setup_time ? new Date(formik.values.setup_time) : ""}
                  onChange={(time) => {
                    if (time == null) {
                      formik.setFieldValue("setup_time", "");
                    } else {
                      const adjustedTime = adjustTimeWithStartDate(time, formik.values.eventDate);
                      formik.setFieldValue("setup_time", adjustedTime);
                    }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  isClearable={isEventDateSelected && !isViewOnly}
                  disabled={!isEventDateSelected}
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  className="form-control date-picker"
                  invalid={formik.errors.setup_time && formik.touched.setup_time}
                  autoComplete="off"
                />
              </div>
            </Col>
          </Row>
          {/* Second row */}
          <Row className="mt-2 g-2">
            <Col md={6}>
              <div>
                <Label for="startTime">
                  Start Time
                  <Required />
                </Label>
                <DatePicker
                  readOnly={isViewOnly}
                  minTime={
                    formik.values.setup_time
                      ? new Date(new Date(formik.values.setup_time).getTime() + 15 * 60 * 1000)
                      : new Date().setHours(0, 0, 0, 0)
                  }
                  maxTime={new Date().setHours(23, 59, 59, 999)}
                  placeholderText="Enter Start Time"
                  name="startTime"
                  selected={formik.values.startTime ? new Date(formik.values.startTime) : ""}
                  onChange={(time) => {
                    if (time == null) {
                      formik.setFieldValue("startTime", "");
                    } else {
                      const adjustedTime = adjustTimeWithStartDate(time, formik.values.eventDate);
                      formik.setFieldValue("startTime", adjustedTime);
                    }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  isClearable={isEventDateSelected && !isViewOnly}
                  disabled={!isEventDateSelected}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  className="form-control date-picker"
                  invalid={formik.errors.startTime && formik.touched.startTime}
                  autoComplete="off"
                />
                {formik.errors.startTime && formik.touched.startTime && (
                  <p style={{ color: "red" }}> {formik.errors.startTime}</p>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div>
                <Label for="endTime">
                  End Time
                  <Required />
                </Label>
                <DatePicker
                  readOnly={isViewOnly}
                  minTime={
                    formik.values.startTime
                      ? new Date(new Date(formik.values.startTime).getTime() + 15 * 60 * 1000)
                      : new Date().setHours(0, 0, 0, 0)
                  }
                  maxTime={new Date().setHours(23, 59, 59, 999)}
                  placeholderText="Enter End Time"
                  name="end_date_time"
                  selected={formik.values.end_date_time ? new Date(formik.values.end_date_time) : ""}
                  onChange={(time) => {
                    if (time == null) {
                      formik.setFieldValue("end_date_time", "");
                    } else {
                      const adjustedTime = adjustTimeWithStartDate(time, formik.values.eventDate);
                      formik.setFieldValue("end_date_time", adjustedTime);
                    }
                  }}
                  disabled={!isEventDateSelected || disabled}
                  showTimeSelect
                  showTimeSelectOnly
                  isClearable={isEventDateSelected && !isViewOnly}
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  className="form-control date-picker"
                  invalid={formik.errors.end_date_time && formik.touched.end_date_time}
                  autoComplete="off"
                />
                {formik.errors.end_date_time && formik.touched.end_date_time && (
                  <p style={{ color: "red" }}> {formik.errors.end_date_time}</p>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {isViewOnly ? (
        ""
      ) : (
        <NavigationFooter
          toggle={toggleMain}
          currentState={mainCurrentTab}
          lastPage={"5"}
          validationFunction={handelValidation}
          isViewOnly={disabled}
          formik={formik}
          isEventAdding={isEventAdding}
          isEventEditing={isEventEditing}
          emailAvialable={true}
          usernameAvailable={true}
        />
      )}
    </>
  );
};

export default EDetails;
