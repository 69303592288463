import { useNavigate } from "react-router";
import { Button } from "reactstrap";
import { motion } from "framer-motion";
import { fetchPlanData, getHeaderData } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { useContext } from "react";
import { DataContext } from "../../context/DataProvider";
import profileimg from "../../../src/assets/images/profileimg.png";
import { getCookie } from "../../utils/Utils";
import profileimgph from "../../assets/images/avtarimage.png";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import Cookies from "js-cookie";

const NavigationHeader = ({
  heading,
  buttonInnerText,
  route,
  back,
  removeIcon = false,
  total,
  limit,
}) => {
  const navigate = useNavigate();
  const activeVariable = getActiveEnvironmentVariable();

  const {
    setProfileProgress,
    setUserProfile,
    setPlanEndDates,
    setSubscribedPalResponce,
  } = useContext(DataContext);

  const handleClick = () => {
    navigate(route);
    localStorage.removeItem("EventformData");
  };

  const priveHover = {
    hover: {
      x: -3,
    },
  };

  const {
    data: data,
    isLoading: isLoading,
    isError: isError,
    refetch: refetchComapnyProfile,
  } = useQuery({
    queryKey: ["headerdata"],
    queryFn: () => getHeaderData(),
    // enabled: false,
    staleTime: Infinity,
    // staleTime: 90000,
    onSuccess: (data) => {
      const profileProgress = data?.data?.profile_progress
        ? JSON.parse(data?.data?.profile_progress)
        : 0;
      setProfileProgress(profileProgress);

      const profileImg = data?.data?.profile_image_path
        ? data?.data?.profile_image_path
        : activeVariable === "CRM"
        ? profileimg
        : profileimgph;
      setUserProfile(profileImg);
      Cookies.set("profile_image", profileImg);
    },
  });

  console.log("data:", data);

  const { data: data1, isLoading: isLoading1, isError: isError1 } = useQuery({
    queryKey: ["subscribePlanData"],
    queryFn: () => fetchPlanData(),
    // enabled: false,
    staleTime: Infinity,
    // staleTime: 50000,
    onSuccess: (responseData) => {
      setSubscribedPalResponce(responseData?.status);
      setPlanEndDates(responseData?.data?.package?.end_date);
    },
  });

  // const subscribedPlanAPI = async () => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}api/v1/subscription/get-subscribed-plan`,
  //       {
  //         method: "POST",
  //         headers: {
  //           authToken: getCookie("authToken"),
  //         },
  //       }
  //     );

  //     if (response.ok) {
  //       const responseData = await response.json();
  //       setSubscribedPalResponce(responseData?.status);
  //       setPlanEndDates(responseData?.data?.package?.end_date);
  //     } else {
  //       throw new Error("Failed to fetch plan data");
  //     }
  //     return response;
  //   } catch (error) {
  //     console.error("Error fetching plan data:", error);
  //   }
  // };

  return (
    <>
      <div className="container-fluid">
        <div className=" nk-block-head d-flex justify-content-between  align-content-center mx-1 mt-4">
          <div className="d-flex ms-1">
            <h3 className="text-body">{heading}</h3>
          </div>
          {buttonInnerText && (
            <>
              {removeIcon ? (
                <Button color="primary" onClick={handleClick}>
                  <span>{buttonInnerText}</span>
                </Button>
              ) : (
                <motion.div whileHover={"hover"}>
                  <Button
                    // style={{
                    //   background: "linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%)",
                    //   border: "none",
                    // }}
                    // onMouseOver={(e) =>
                    //   (e.currentTarget.style.background =
                    //     "linear-gradient(23deg, rgb(82 224 93) 0%, rgb(54 186 0) 35%, rgb(4 28 4) 100%)")
                    // }
                    // onMouseOut={(e) =>
                    //   (e.currentTarget.style.background =
                    //     "linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%)")
                    // }
                    // color="primary"
                    // color="primary"
                    className="btn-primary"
                    onClick={() => navigate(route)}
                  >
                    {back ? (
                      <motion.span variants={priveHover}>
                        {/* <Icon className="icon ni ni-arrow-long-left"></Icon> */}
                        {/* <Icon className="icon ni ni-arrow-left p-1"></Icon> */}
                      </motion.span>
                    ) : (
                      ""
                      // <Icon className="ni-plus"></Icon>
                    )}
                    <span>{buttonInnerText}</span>
                  </Button>
                </motion.div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default NavigationHeader;
