import React, { useContext, useEffect, useMemo, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Routes, Route, useNavigate } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import { Button } from "reactstrap";
import { getCookie } from "../../../utils/Utils";
import { DataContext } from "../../../context/DataProvider";

// const stripePromise = loadStripe(
//   "pk_test_51QA9qFBNxoKCw9k9ighgIrz8vUBwVMv1W9eHajwxHl3E5UbthGv3Vj99hlCHbgwBNky6ZS8vi17vyba85OQRQznr00HQZ3VYm4"
// );

function StripePayment({
  data,
  price,
  selectedPlan,
  setModalData,
  invalidPlanData,
  setModal,
  modal,
  refetchPlandata,
  setModalTab,
  modalTab,
  client_key,
  duration_type,
}) {
  const { setNewPlanId } = useContext(DataContext);
  const [clientSecret, setClientSecret] = useState("");
  const [dpmCheckerLink, setDpmCheckerLink] = useState("");

  const stripePromise = useMemo(() => loadStripe(client_key), [client_key]);

  const handleBuyNow = () => {
    setNewPlanId(data.id);
    const formData = new FormData();

    formData.append("id", data.id);
    formData.append("amount", price);

    fetch(
      `${process.env.REACT_APP_API_URL}api/v1/payment/stripe-plan-payment`,
      {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
        body: formData,
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.clientSecret);
        setDpmCheckerLink(data.dpmCheckerLink);
      })
      .catch((error) => {
        console.error("Error creating payment intent:", error);
      });
  };

  useEffect(() => {
    handleBuyNow();
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const loader = "auto";

  return (
    <>
      {/* <div className="plan-price-container">
        <span className="plan-name">Plan: {data.name}</span>
        <span className="plan-price">Price: ${price}</span>
      </div> */}

      <div className="App">
        {clientSecret && (
          <Elements
            options={{ clientSecret, appearance, loader }}
            stripe={stripePromise}
          >
            <CheckoutForm
              dpmCheckerLink={dpmCheckerLink}
              selectedPlan={selectedPlan}
              setModalData={setModalData}
              invalidPlanData={invalidPlanData}
              setModal={setModal}
              modal={modal}
              fetchPlanData={refetchPlandata}
              setModalTab={setModalTab}
              modalTab={modalTab}
              duration_type={duration_type}
            />
          </Elements>
        )}
      </div>
    </>
  );
}

export default StripePayment;
