import React, { useState } from "react";
import { Col, Row, Button, Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { Icon } from "../../components/Component";
import SubVideoTab from "./SubVideoTab";
import UploadYoutubLinks from "./UploadYoutubLinks";
import NavigationFooter from "../components/NavigationFooter";

const Videos = ({
  formik,
  isCompanyProfileEditing,
  toggle,
  currentState,
  videosLimit,
  youtubLinkLimit,
  videoCount,
  youtubelinkCount,
  subscribedpalresponce,
}) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="p-3">
      <Nav tabs>
        <NavItem>
          <NavLink
            tag="a"
            href="#tab"
            className={classnames({ active: activeTab === "1" })}
            onClick={(ev) => {
              ev.preventDefault();
              toggleTab("1");
            }}
          >
            <Icon name="video" /> <span>Video</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag="a"
            href="#tab"
            className={classnames({ active: activeTab === "2" })}
            onClick={(ev) => {
              ev.preventDefault();
              toggleTab("2");
            }}
          >
            <Icon name="youtube" /> <span>YouTube Links</span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <SubVideoTab
            currentState={activeTab}
            isCompanyProfileEditing={isCompanyProfileEditing}
            videosLimit={videosLimit}
            videoCount={videoCount}
            formik={formik}
            subscribedpalresponce={subscribedpalresponce}
          />
        </TabPane>
        <TabPane tabId="2">
          <UploadYoutubLinks
            currentState={activeTab}
            isCompanyProfileEditing={isCompanyProfileEditing}
            youtubLinkLimit={youtubLinkLimit}
            youtubelinkCount={youtubelinkCount}
            formik={formik}
            subscribedpalresponce={subscribedpalresponce}
          />
        </TabPane>
      </TabContent>

      <div className="mt-4">
        <NavigationFooter
          toggle={toggle}
          currentState={currentState}
          lastPage="8"
          validationFunction={(callbackFun) => callbackFun(true)}
          formik={formik}
          isCompanyProfileEditing={isCompanyProfileEditing}
          form={"updateprofile"}
        />
      </div>
    </div>
  );
};

export default Videos;
