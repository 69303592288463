import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ReactDataTable } from "../../../components/Component";
import { getEmployeeList, getEngList, getPhotoList } from "../../../http/get/getApi";
import { NotAvailable } from "../../Client/ClientListPage";
import NavigationHeader from "../../components/NavigationHeader";
import TableActionBar from "../../components/TableActionBar";
import { ShimmerTable } from "react-shimmer-effects";
import Swal from "sweetalert2";
import { deleteEmployee, deleteEngg, deletePhoto } from "../../../http/delete/deleteApi";
import { Navigate, useNavigate } from "react-router";

const EngineerList = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [hasInvalidated, setHasInvalidated] = useState(false);

  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [search, setSearch] = useState("");

  //photographer list
  const {
    data: photographerList,
    isLoading: employeeIsLoading,
    isError: employeeListIsError,
  } = useQuery({
    queryKey: ["get-photographer-list", pageNo, recordLimit, search],
    queryFn: () => getEngList({ id: "", page: pageNo, limit: recordLimit, search: search }),
    staleTime: 50000,
    cacheTime: 300000,
    retry: 1,
  });

  // useMutation to delete photographer list
  const {
    mutate: deletePhotographerMutation,
    isLoading: isEmployeeDeleting,
    error: deleteEmployeeError,
  } = useMutation({
    mutationKey: ["delete-photographer-event-by-Id"],
    mutationFn: (ID) => deleteEngg({ id: ID }),
    onSuccess: (data) => {
      if (data.status && !hasInvalidated) {
        queryClient.invalidateQueries(["get-photographer-list"]);
        setHasInvalidated(true);
      }
    },
  });
  const deleteEmployeeFromList = async (value) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deletePhotographerMutation(value);
          photographerList.data = photographerList?.data?.eventdata?.filter((emp) => emp?.id !== value);
          // Swal.fire("Deleted!", "Your employee has been deleted.", "success");
          Swal.fire({
            title: "Deleted!",
            text: "Your employee has been deleted.",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        queryClient.invalidateQueries(["get-photographer-list"]);
      });

      console.log("Photographer-event deleted successfully!");
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  };

  const isLoading = isEmployeeDeleting || employeeIsLoading; // combining 2 states to check for laoding state/for showing shimmer effect

  const tableHeaddings = [
    {
      name: "Sr. No.",
      // selector: (row, index) => index + 1,
      selector: (row, index) => pageNo * recordLimit + index + 1,
      maxWidth: "5px",
    },
    {
      name: "first_name",
      selector: (row) => row.first_name || <NotAvailable />,
    },
    
    {
      name: "Email",
      selector: (row) => row.email || <NotAvailable />,
    },
    {
      name: "Contact No.",
      selector: (row) => row.phone_number || <NotAvailable />,
    },
    {
      name: "Actions",
      allowOverflow: true,
      cell: (row) => (
        <>
          {console.log("row: " + JSON.stringify(row))}{" "}
          <TableActionBar
            options1Icon="eye"
            options1TooleTipText="view"
            options2Icon="icon ni ni-edit"
            options2TooleTipText="Edit"
            options3Icon="icon ni ni-trash"
            options3TooleTipText="Delete"
            // options1onClick={() => navigate(`/employee-list/create-employee?type=view&id=${row.id}&from=fromView`)}
            options1onClick={() => navigate(`add-engineer-event/preview?id=${row.id}&previewdetail=true`)}
            options2onClick={() => {
              navigate(`add-engineer-event?type=edit&id=${row.id}&from=fromEdit`);
              console.log("first");
            }}
            options3onClick={() => deleteEmployeeFromList(row.id)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <NavigationHeader
        heading={"Project List"}
        buttonInnerText={"Add Project"}
        route={"/engineer-list/add-engineer-event?page=construction"}
      />
      <section className="m-4 border p-3 bg-white">
        <ReactDataTable
          loading={isLoading}
          data={photographerList?.data?.eventdata || []}
          columns={tableHeaddings}
          searchBy={"first_name"}
          pagination
          actions
          pageNo={setPageNo} // sending function to change page no
          activePage={photographerList?.data?.eventdata?.page || 0} 
          totalRecords={photographerList?.data?.eventdata?.totalRecords || 10}
          recordLimit={setRecordLimit} //setting up value of no of records to display
          setSearch={setSearch} // send search value setFunction
        />
      </section>
    </>
  );
};
export default EngineerList;
