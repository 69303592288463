import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import { Icon, ReactDataTable } from "../../components/Component";
import NavigationHeader from "../components/NavigationHeader";
import TableActionBar from "../components/TableActionBar";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import dashboard from "../../Jsonfiles/Dashboard.json";
import {
  useQuery,
  useMutation,
  queryClient,
  useQueryClient,
} from "react-query";
import {
  getEventsList,
  getEventsRequestList,
  getPaymentDetails,
} from "../../http/get/getApi";
import { handleEventRequest } from "../../http/delete/deleteApi";
import Swal from "sweetalert2";
import { formatDate, parseDateString } from "../../utils/Utils";
import PaymentDetailsModal from "./PaymentDetailsModal";

export const NotAvailable = () => (
  <div style={{ textAlign: "center" }}>----</div>
);

const EventBookingRequest = () => {
  const navigate = useNavigate();

  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [eventfinancials, seteventfinancials] = useState({});
  const [clientdetails, setclient_details] = useState({});
  // console.log("clientdetails",clientdetails)

  const queryClient = useQueryClient();
  const activeVariable = getActiveEnvironmentVariable();
  //fetching event data
  const {
    data: eventRequestList,
    isLoading: eventsListLoading,
    isError: eventsListError,
  } = useQuery({
    queryKey: ["get-events-request-list", pageNo, recordLimit, search],
    // queryKey: ["get-events-request-list"],
    queryFn: () =>
      getEventsRequestList({
        id: "",
        page: pageNo,
        limit: recordLimit,
        search: search,
      }),
    staleTime: Infinity,
    // staleTime: 50000,
    // cacheTime: 30000,
    // retry: 1,
  });

  //   console.log("eventRequestList",eventRequestList)

  // useMutation to delete event
  const {
    mutate: deleteEventMutation,
    isLoading: isEventDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-event-by-Id"],
    mutationFn: (data) => handleEventRequest(data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["get-events-request-list"]);
    },
  });

  const handleRequest = (value) => {
    try {
      let cancelReason = "";
      Swal.fire({
        title: "Are you sure?",
        text: `${
          value?.status == "Accepted"
            ? "You want to accept this request!"
            : "You want to cancel this request!"
        }`,
        icon: "warning",
        html:
          value?.status !== "Accepted" // Show the textarea only if the status is not "Accepted"
            ? `
          <div style="text-align: left;">
            <label for="cancelReason">Reason for cancellation</label>
            <textarea id="cancelReason" name="cancelReason" rows="4" cols="50" placeholder="Provide a reason for cancellation..."></textarea>
          </div>
        `
            : "",
        showCancelButton: true,
        confirmButtonText: `${
          value?.status == "Accepted" ? "Yes, Accept it" : "Yes, cancel it"
        }`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info",
        },
        // timer: 20000,
        // preConfirm: () => {
        //   if (value?.status !== "Accepted") {
        //     // Capture the textarea value if status is "Cancelled"
        //     cancelReason = document.getElementById("cancelReason").value;
        //   }
        // },
        preConfirm: () => {
          // Capture the textarea value
          if (value?.status !== "Accepted") {
            cancelReason = document.getElementById("cancelReason").value;

            // Check if cancelReason is empty and show an error if it is
            if (!cancelReason) {
              Swal.showValidationMessage("Cancellation reason is required.");
              return false; // Stop the submission if validation fails
            }
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            id: value.id,
            status: value.status === "Accepted" ? "Accepted" : "Cancelled",
            reason: value.status === "Cancelled" ? cancelReason : null,
          };
          deleteEventMutation(payload);
          eventRequestList.data = eventRequestList.data.filter(
            (event) => event?.id !== value
          );
          Swal.fire({
            title: `${value?.status == "Accepted" ? "Accepted" : "Cancelled"}`,
            text: `${
              value?.status == "Accepted"
                ? `${dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)} has been Accepted.`
                : `${dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)}  has been Cancelled.`
            }`,
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    } catch (error) {
      console.error(`Error deleting ${dashboard?.[activeVariable]?.section1?.map((item) => item?.text3)} :`, error);
    }
  };

  //   const openMessageModal = (event_financials, client_details) => {
  //     seteventfinancials(event_financials);
  //     setclient_details(client_details[0])
  //     setModalOpen(true);
  //   };

  const isLoading = isEventDeleting || eventsListLoading;

  const tableHeaddings = [
    {
      name: "Sr. No.",
      selector: (row, index) => pageNo * recordLimit + index + 1,
      maxWidth: "5px",
    },

    {
      name: "Client Name",
      selector: (row) =>
        `${row.client_details?.first_name || "Not Provided"} ${
          row.client_details?.last_name || ""
        }` || <NotAvailable />,
    },
    {
      name: `${dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)}  Name`,
      selector: (row) => row.event_name || <NotAvailable />,
    },
    {
      name: `${dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)}  Type`,
      selector: (row) => row.event_type_name || <NotAvailable />,
    },

    {
      name: `${dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)}  Date`,
      selector: (row) => formatDate(row.start_date_time) || <NotAvailable />,
    },

    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.booking_request_status === "Accepted"
                ? process.env.REACT_APP_THEME_COLOR
                : row.booking_request_status === "Cancelled"
                ? "#ff0000"
                : "#dada2b",
          }}
        >
          {row.booking_request_status}
        </span>
      ),
    },

    {
      name: "Actions",
      allowOverflow: true,
      cell: (row) => (
        <>
          <div className="d-flex max-vw-100 gx-2 d-none d-lg-block">
            <Row className=" gx-1">
              <Col md={0} sm={2} xs={2}>
                <TableActionBar
                  options1Icon="eye"
                  options1TooleTipText="View"
                  options1onClick={() =>
                    navigate(
                      `add-event/preview?id=${row.id}&from=previewdetail`
                    )
                  }
                />
              </Col>
              <Col md={5} sm={5} xs={5}>
                <Button
                  id={`accept-btn-${row.id}`} // Unique ID for the tooltip
                  color="success"
                  size="xs"
                  disabled={
                    !(
                      row.booking_request_status === "Cancelled" ||
                      row.booking_request_status === "Pending"
                    )
                  }
                  onClick={() =>
                    handleRequest({ id: row.id, status: "Accepted" })
                  }
                >
                  Accept
                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target={`accept-btn-${row.id}`}
                >
                  Accept the request
                </UncontrolledTooltip>
              </Col>
              <Col md={5} sm={5} xs={5}>
                <Button
                  id={`cancel-btn-${row.id}`} // Unique ID for the tooltip
                  // className="ms-2"
                  color="danger"
                  size="xs"
                  disabled={
                    !(
                      row.booking_request_statuss === "Accepted" ||
                      row.booking_request_status === "Pending"
                    )
                  }
                  onClick={() =>
                    handleRequest({ id: row.id, status: "Cancelled" })
                  }
                >
                  Cancel
                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target={`cancel-btn-${row.id}`}
                >
                  Cancel the request
                </UncontrolledTooltip>
              </Col>
            </Row>
          </div>
          <div className="d-block d-lg-none w-auto">
            <ul className="d-flex g-2">
              <li>
                <UncontrolledDropdown>
                  <DropdownToggle className="dropdown-toggle btn btn-icon btn-light">
                    <Icon name="more-h"></Icon>
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="link-list-opt">
                      <li>
                        <DropdownItem
                          tag="a"
                          onClick={(ev) => ev.preventDefault()}
                        >
                          <TableActionBar
                            options1Icon="eye"
                            options1TooleTipText="View"
                            options1onClick={() =>
                              navigate(
                                `add-event/preview?id=${row.id}&from=previewdetail`
                              )
                            }
                          />
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          onClick={(ev) => ev.preventDefault()}
                        >
                          <Button
                            id={`accept-btn-${row.id}`} // Unique ID for the tooltip
                            color="success"
                            size="xs"
                            disabled={
                              !(
                                row.booking_request_status === "Cancelled" ||
                                row.booking_request_status === "Pending"
                              )
                            }
                            onClick={() =>
                              handleRequest({ id: row.id, status: "Accepted" })
                            }
                          >
                            Accept
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target={`accept-btn-${row.id}`}
                          >
                            Accept the request
                          </UncontrolledTooltip>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          onClick={(ev) => ev.preventDefault()}
                        >
                          <Button
                            id={`cancel-btn-${row.id}`} // Unique ID for the tooltip
                            // className="ms-2"
                            color="danger"
                            size="xs"
                            disabled={
                              !(
                                row.booking_request_status === "Accepted" ||
                                row.booking_request_status === "Pending"
                              )
                            }
                            onClick={() =>
                              handleRequest({ id: row.id, status: "Cancelled" })
                            }
                          >
                            Cancel
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target={`cancel-btn-${row.id}`}
                          >
                            Cancel the request
                          </UncontrolledTooltip>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      {/* <NavigationHeader heading={"Event List"} buttonInnerText={"Add Event"} route={"/add-event"} /> */}
      <section className="m-4 border p-3 bg-white">
        <ReactDataTable
          loading={isLoading}
          //   data={Array.isArray(eventRequestList?.data) ? eventRequestList.data : []}
          data={
            Array.isArray(eventRequestList?.data) ? eventRequestList.data : []
          }
          columns={tableHeaddings}
          pagination
          actions
          searchBy={`${dashboard?.[activeVariable]?.section1?.map((item) => item?.text3)}_name`}
          pageNo={setPageNo} // sending function to change page no
          // activePage={eventRequestList?.data?.page || 0} // sending the current page no
          activePage={eventRequestList?.currentPage || 0} // sending the current page no
          totalRecords={eventRequestList?.total_records || 10} // sending the total no of records on database
          recordLimit={setRecordLimit} //setting up value of no of records to display
          setSearch={setSearch} // send search value setFunction
        />
      </section>
      {/* <PaymentDetailsModal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} eventfinancials={eventfinancials} clientdetails={clientdetails}  /> */}
    </>
  );
};
export default EventBookingRequest;
