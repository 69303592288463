import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap";
import classnames from "classnames";
import {
  BlockBetween,
  BlockContent,
  BlockDes,
  BlockTitle,
  Icon,
} from "../../../../src/components/Component";
import PlanRegistration from "../../../../src/pages/prebuilt/Registration Plans/PlanRegistration";
import { DataContext } from "../../../context/DataProvider";
import { getCookie } from "../../../utils/Utils";

const MonthlyandYearlyPlan = ({ isCompanyProfileEditing }) => {
  const { durationType, setDurationType } = useContext(DataContext);
  const [activeTab, setActiveTab] = useState("1");
  const [isPlanExpired, setIsPlanExpired] = useState(false);

  const fetchPlanData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/subscription/get-subscribed-plan`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch plan data");
      }

      const responseData = await response.json();
      const end_date = responseData?.data?.package?.end_date;
      const currentDate = new Date();
      const endDate = new Date(end_date);

      setIsPlanExpired(endDate < currentDate);
    } catch (error) {
      console.error("Error fetching plan data:", error);
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (durationType === "m") {
      setActiveTab("1");
    } else if (durationType === "y") {
      setActiveTab("2");
    }

    fetchPlanData();
  }, [durationType]);

  return (
    <div className="p-3">
      {isPlanExpired && (
        <Alert color="warning" className="mb-4">
          <strong>Your subscription has expired.</strong> Please renew or choose
          a plan to continue enjoying all features.
        </Alert>
      )}
      <BlockBetween className={`g-3 ${isPlanExpired ? "d-none" : ""}`}>
        <BlockContent>
          <BlockTitle>Select Plan</BlockTitle>
          <BlockDes className="text-soft">
            <p>Choose the perfect plan that fits your needs and budget.</p>
          </BlockDes>
        </BlockContent>
      </BlockBetween>

      <Nav tabs className="mt-2">
        <NavItem>
          <NavLink
            tag="a"
            href="#tab"
            className={classnames({ active: activeTab === "1" })}
            onClick={(ev) => {
              ev.preventDefault();
              toggleTab("1");
            }}
          >
            <Icon name="monthlylan" /> <span>Monthly</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag="a"
            href="#tab"
            className={classnames({ active: activeTab === "2" })}
            onClick={(ev) => {
              ev.preventDefault();
              toggleTab("2");
            }}
          >
            <Icon name="yearlyplan" /> <span>Yearly</span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <PlanRegistration
            duration_type="m"
            currentState={activeTab}
            isCompanyProfileEditing={isCompanyProfileEditing}
          />
        </TabPane>
        <TabPane tabId="2">
          <PlanRegistration
            duration_type="y"
            currentState={activeTab}
            isCompanyProfileEditing={isCompanyProfileEditing}
          />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default MonthlyandYearlyPlan;
