import React from "react";
import { getVenderList } from "../../http/get/getApi";
import NavigationHeader from "../components/NavigationHeader";
import { ShimmerText, ShimmerThumbnail } from "react-shimmer-effects";
import { Col, Row } from "reactstrap";
import { useLocation } from "react-router";
import { useQuery } from "react-query";
import profileimg from "../../../src/assets/images/profileimg.png";
import  profileimgph from "../../assets/images/avtarimage.png";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

function PreviewVendorDetails() {
  const activeVariable = getActiveEnvironmentVariable();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const from = searchParams.get("from");
  const page = searchParams.get("page");
  console.log("id", id);
  console.log("from", from);

  const {
    data: venderData,
    isLoading: venderLoading,
    isError: venderIsError,
  } = useQuery({
    queryKey: ["get-vender-by-id", id],
    queryFn: () => getVenderList({ id: id }),
    staleTime: Infinity,
    // staleTime: 5000,
    // cacheTime: 300000,
    // retry: 1,
  });

  const client = venderData?.data?.venders?.[0];

  const handleImageError = (e) => {
    e.target.src = activeVariable=== "CRM" ? profileimg : profileimgph; // Set fallback image if userProfile image fails to load
  };

  return (
    <>
      <NavigationHeader
        heading={from === "previewdetail" ? "Vendor Detail" : ""}
        buttonInnerText={"Back"}
        route={"/vendor-list"}
        back
      />
      {venderLoading ? (
        <Row className="py-0 m-4">
          {/* Left column with image */}
          <Col md={6} className="border p-3 bg-white g-3">
            <Row>
              <Col md={4} className="text-start mt-2">
                {/* Image */}
                <ShimmerThumbnail height={150} width={150} rounded={false} />
              </Col>
              <Col md={8} className="g-4">
                <Row className="mt-2">
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {/* Right column with details */}
          <Col md={6} className="border p-3 bg-white g-3">
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <ShimmerText height={20} width={150} />
          </Col>
        </Row>
      ) : (
        <>
          <Row className="py-0 m-4">
            {/* Left column with image */}
            <Col xs={12} md={2} className="border p-3 bg-white g-3">
              <Row>
                <Col md={12} className="text-start">
                  {/* Image */}
                  <img
                    src={client?.profile_image_path ? client?.profile_image_path : activeVariable ==="CRM" ? profileimg : profileimgph } // Replace with the actual image field
                    alt="Client Image"
                    onError={handleImageError}
                    style={{ width: "150px", height: "150px", borderRadius: "0%", objectFit: "cover" }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4} className=" bg-white p-3 g-3">
              <Row className="mt-3">
                <Col xs={6} md={5}>
                  <strong>First Name :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.first_name || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Last Name :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.last_name || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Company Name :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.company_name || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Website :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.website || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Business Phone :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.business_phone || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Home Phone :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.phone_number || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Fax Phone :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.fax_phone || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Cell Phone :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.cell_phone || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Home Address :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.address_1 || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Home Zip-Code :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.zip_code || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Country :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.country_name || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>State :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.stateName || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>City :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {client?.cityName || "N/A"}
                </Col>
              </Row>
            </Col>

            {/* Right column with details */}
            <Col xs={12} md={6} className="border-start p-3 bg-white g-3">
              <Row>
                <Col xs={6} md={4}>
                  <strong>Email :</strong>
                </Col>
                <Col xs={6} md={8} className="text-break">
                  {client?.email || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Username :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.username || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Business Fax :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.business_fax || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Business Address :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.business_address || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Zip-code :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.zip_code || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Business Country :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {/* {client?.country_name || "N/A"} */}
                  {client?.businessCountryName || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Business State :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {/* {client?.business_state || "N/A"} */}
                  {client?.businessStateName || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Business City :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {/* {client?.business_city || "N/A"} */}
                  {client?.businessCityName || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Representive Name :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.representative_name || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Notes :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.notes || "N/A"}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default PreviewVendorDetails;
