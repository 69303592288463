import React, { useEffect, useState } from "react";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
} from "../../../components/Component";
import { LineChartExample } from "../../Home Page Setup/Event Graph/EventCompareList";
import { useNavigate } from "react-router";
import { getCookie } from "../../../utils/Utils";
import { getActiveEnvironmentVariable } from "../../../utils/Utils";
import dashboard from "../../../Jsonfiles/Dashboard.json"


// let token = process.env.REACT_APP_AUTH_TOKEN;


const EventComparison = ({allData}) => {
  const activeVariable = getActiveEnvironmentVariable();
  const navigate = useNavigate();
  const [chartData, setChartData] = useState(null);
  const [newEventComparision, setNewEventComparision]= useState();
  const [solidLineChart, setSolidLineChart] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    dataUnit: "BTC",
    lineTension: 0.4,
    legend: true,
    categoryPercentage: 0.9,
    barPercentage: 0.6,
    datasets: []
  });

  useEffect(()=>{
    setNewEventComparision(allData?.data?.completedEventData)
    updateChartData(allData?.data?.completedEventData);
  },[allData])

  // useEffect(() => {
  //   eventInfo();
  //   return () => {};
  // }, []);

  // const eventInfo = async () => {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/client/get-completed-event`, {
  //       method: "POST",
  //       headers: {
  //         authToken: getCookie("authToken"),
  //       },
  //     });
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const responseData = await response.json();
  //     // console.log('responseData :', responseData);
  //     updateChartData(responseData.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const updateChartData = (data) => {
    setSolidLineChart((prevChart) => ({
      ...prevChart,
      labels: data?.labels,
      datasets: data?.datasets
    }));
  };

  return (
    <React.Fragment>
      <Block>
        <BlockHead className="pb-0">
          <BlockHeadContent className="mx-4 my-3">
            <BlockTitle tag="h6" className="mb-0">
              <h4>Gross {dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)} Comparison</h4>
            </BlockTitle>
            <BlockDes>
              {/* <p>according to events which sold properly</p> */}
            </BlockDes>
          </BlockHeadContent>
        </BlockHead>
        <PreviewCard>
          <div className="nk-ck">
            {solidLineChart?.datasets?.length > 0 ? (
              <LineChartExample legend={true} data={solidLineChart} />
            ) : (
              // <p>Loading...</p>
              <LineChartExample legend={true} data={solidLineChart} />
            )}
          </div>
        </PreviewCard>
      </Block>
    </React.Fragment>
  );
};

export default EventComparison;
