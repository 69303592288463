// EClient.js

import React, { useEffect, useState } from "react";
import NavigationFooter from "../components/NavigationFooter";
import { Row, Col, Label, Button } from "reactstrap";
import Required from "../components/Required";
import { useLocation, useNavigate } from "react-router";
import { Icon } from "../../components/Component";
import ReactSelect from "../../components/ReactSelect/ReactSelect";
import { getClientsList, getEventsList } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { transformData } from "../../utils/Utils";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { color } from "framer-motion";

const EClient = ({ toggleMain, mainCurrentTab, formik, isViewOnly, isEventAdding, isEventEditing }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [route, setRoute]= useState(`${window.location.pathname}${window.location.search}`)

  //fetching clientList data
  const {
    data: clientsList,
    isLoading: clientListLoading,
    isError: clientListError,
  } = useQuery({
    queryKey: ["get-clients-list"],
    queryFn: () => getClientsList({ id: "", page: "", limit: "", search: "" }),
    staleTime: Infinity,
    // cacheTime: 300000, 
    retry: 1,
  });


  const {
    data: eventsList,
    isLoading: eventsListLoading,
    isError: eventsListError,
  } = useQuery({
    queryKey: ["get-events-by-id", id],
    queryFn: () => getEventsList({ id: id, page: "", limit: "", search: "" }),
    // staleTime: 50000,
    staleTime: Infinity,
    // cacheTime: 300000, 
    retry: 1,
  });

  function handleExistingClient(e) {
    formik.setFieldValue("client_id", e.value);
  }

  const handelValidation = (callbackFun) => {
    if (formik.values.client_id === "") {
      formik.setFieldTouched("client_id", true);
    }
    if (formik.values.client_id !== "") {
      callbackFun(true);
    }
  };

  //options for react select
  const options = transformData({
    dataArray: clientsList?.data?.clients || [],
    // label: "first_name",
    label: "username",
    value: "id",
    isLoading: clientListLoading,
  });

  const hnadleClientClcik = () => {
    navigate("/client-list/create-client?page=event");
    localStorage.setItem("clientroute",route)
  }

  useEffect(()=>{
    localStorage.removeItem("clientroute");
  },[])

  return (
    <>
      <section>
        <Row className="p-2 py-0">
          <Col md={12} className="border p-3 bg-white">
            <Row className="row g-2">
              <Label for="existingClient">
                Select From Existing
                <Required />
              </Label>

              <Col sm="6" lg="7">
                <>
                  {clientListLoading ? (
                    <ShimmerThumbnail height={40} width={700} rounded />
                  ) : (
                    <ReactSelect
                      disabled={isViewOnly}
                      options={options}
                      id="client_id"
                      name="client_id"
                      value={formik.values.client_id}
                      onChange={(e) => handleExistingClient(e)}
                      onBlur={() => formik.setFieldTouched("client_id", true)}
                      invalid={formik.errors.client_id && formik.touched.client_id}
                    />
                  )}
                </>
                {formik.errors.client_id && formik.touched.client_id && (
                  <p style={{ color: "red" }}> {formik.errors.client_id}</p>
                )}
              </Col>
              <Col sm="6" lg="3">
              {
                isViewOnly ? "" :
                <Button
                  // color="primary"
                  className="btn-dim btn-primary"
                  outline
                  onClick={hnadleClientClcik}
                  // onClick={() => navigate("/client-list/create-client?page=event")}
                >
                  <Icon className="ni-plus" style={{color:"white"}}></Icon>
                  <span style={{color:"white"}}>Add New Client</span>
                </Button>
              }
              </Col>
            </Row>
          </Col>
        </Row>
        <NavigationFooter
          toggle={toggleMain}
          currentState={mainCurrentTab}
          lastPage={"5"}
          validationFunction={handelValidation}
          formik={formik}
          form="addEvent"
          isEventAdding={isEventAdding}
          isEventEditing={isEventEditing}
        />
      </section>
    </>
  );
};

export default EClient;
