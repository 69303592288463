import React, { useContext, useEffect, useRef } from "react";
import { Label, Input, Row, Col, Button } from "reactstrap";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { Icon, ReactDataTable } from "../../components/Component";
import NavigationFooter from "../components/NavigationFooter";
import Required from "../components/Required";
import { toast } from "react-toastify";
import ReactSelect from "../../components/ReactSelect/ReactSelect";
import { useLocation, useNavigate } from "react-router";
import { getPackageList, getsitesetting } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { transformPackagesData } from "../../utils/Utils";
import Swal from "sweetalert2";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { DataContext } from "../../context/DataProvider";

export const TableInput = ({
  type = "number",
  value,
  onChange,
  onBlur,
  name,
  placeholder = "0",
  error,
  style,
  disabled,
}) => {
  return (
    <input
      type={type}
      value={value}
      onKeyDown={(evt) =>
        ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
      }
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      style={{
        width: "fit-content",
        background: "transparent",
        border: error ? "1px solid red" : "none",
        marginLeft: "2px",
        paddingLeft: "2px",
        ...style,
      }}
    />
  );
};

const EFinancials = ({
  toggleMain,
  mainCurrentTab,
  formik,
  disabled,
  eventsList,
  isEventEditing,
  isViewOnly,
}) => {
  const { currency, setCurrency } = useContext(DataContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [route, setRoute] = useState(
    `${window.location.pathname}${window.location.search}`
  );

  const currentPath = location.pathname;

  //getting details from the URl
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");

  const [payment1Done, setPayment1Done] = useState(false);
  const [payment2Done, setPayment2Done] = useState(false);

  const scheduledPayment3 = eventsList?.data?.[0]?.scheduled_payment_3_value;
  const scheduledPayment2txn =
    eventsList?.data?.[0]?.event_financials?.scheduled_payment_2_txn;
  const scheduledPayment3txn =
    eventsList?.data?.[0]?.event_financials?.scheduled_payment_3_txn;

  //fetching packageList
  const {
    data: packagesList,
    isLoading: packageListLoading,
    isError: packageListError,
    refetch: refetchPackageList,
  } = useQuery({
    queryKey: ["get-package-list"],
    queryFn: () => getPackageList({ id: "", page: "", limit: "", search: "" }),
    // staleTime: 60000,
    // cacheTime: 300000,
    staleTime: Infinity,
    retry: 1,
  });

  //options for react select (packagesOptions)
  const packagesOptions = transformPackagesData({
    dataArray: packagesList?.data?.packages || [],
    label: "package_name",
    value: "id",
    isLoading: packageListLoading,
  });

  useEffect(() => {
    refetchPackageList();
  }, [refetchPackageList]);

  //   useEffect(() => {
  //     if (!packageListLoading && formik.values.package_name) {
  //       const selectedId = formik.values.package_name;
  //       const selectedPackage = packagesList?.data?.packages.filter((item) => item.id === selectedId);
  //       formik.setFieldValue("retainer_value", selectedPackage[0].package_deposite_price);
  //       formik.setFieldValue("package_price", selectedPackage[0].package_price);
  //       formik.setFieldValue("package_overtime", selectedPackage[0].package_overtime);
  //     }

  //     const formatTo12HourCustom = (date) => {
  //       let hours = date.getHours();
  //       const minutes = date.getMinutes().toString().padStart(2, '0');
  //       const ampm = hours >= 12 ? 'PM' : 'AM';
  //       hours = hours % 12 || 12; // Convert 0 to 12 for 12 AM/PM.
  //       return `${hours}:${minutes} ${ampm}`;
  //     };

  //     const start = new Date(formik.values.start_date_time);
  //     const end = new Date(formik.values.end_date_time);
  //     const startFormatted = formatTo12HourCustom(start);
  // const endFormatted = formatTo12HourCustom(end);
  // console.log("startFormatted",startFormatted)
  // console.log("endFormatted",endFormatted)

  //     if (!isNaN(startFormatted) && !isNaN(endFormatted)) {
  //       const diffInMilliseconds = endFormatted - startFormatted; // Difference in milliseconds
  //       console.log("diffInMilliseconds",diffInMilliseconds)
  //       const diffInHours = Math.round(diffInMilliseconds / (1000 * 60 * 60)).toFixed(2); // Convert to hours
  //       formik.setFieldValue("timeDiff", diffInHours); // Update formik field
  //     }

  //   }, [packageListLoading, formik.values.package_name, formik.values.start_date_time, formik.values.end_date_time]);

  useEffect(() => {
    if (!packageListLoading && formik.values.package_name) {
      const selectedId = formik.values.package_name;
      const selectedPackage = packagesList?.data?.packages.find(
        (item) => item.id === selectedId
      );

      if (selectedPackage) {
        formik.setFieldValue(
          "retainer_value",
          selectedPackage.package_deposite_price
        );
        formik.setFieldValue("package_price", selectedPackage.package_price);
        formik.setFieldValue(
          "package_overtime",
          selectedPackage.package_overtime
        );
      }
    }

    const formatTo12HourCustom = (date) => {
      if (!(date instanceof Date) || isNaN(date)) {
        return ""; // Handle invalid dates gracefully
      }
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12; // Convert 0 to 12 for 12 AM/PM.
      return `${hours}:${minutes} ${ampm}`;
    };

    const start = new Date(formik.values.startTime);
    const end = new Date(formik.values.end_date_time);

    const startFormatted = formatTo12HourCustom(start);
    const endFormatted = formatTo12HourCustom(end);

    if (!isNaN(start.getTime()) && !isNaN(end.getTime())) {
      const diffInMilliseconds = end - start; // Difference in milliseconds
      const diffInMinutes = diffInMilliseconds / (1000 * 60);
      const diffInHours = diffInMinutes / 60;
      const formattedDiffInHours = diffInHours.toFixed(2);
      // const diffInMilliseconds = end - start; // Difference in milliseconds

      // const totalMinutes = Math.floor(diffInMilliseconds / (1000 * 60)); // Total minutes
      // const hours = Math.floor(totalMinutes / 60); // Whole hours
      // const minutes = totalMinutes % 60; // Remaining minutes

      // const formattedDiff = `${hours}.${minutes.toString().padStart(2, '0')}`;
      // console.log("formattedDiff",formattedDiff);

      formik.setFieldValue("timeDiff", formattedDiffInHours); // Update Formik field
    }
  }, [
    packageListLoading,
    formik.values.package_name,
    formik.values.startTime,
    formik.values.end_date_time,
    packagesList,
  ]);

  console.log("timeDiff", formik.values.timeDiff);
  function handleDemoValue(e) {
    formik.setFieldValue("package_id", e.value);
    formik.setFieldValue("package_price", e.package_price);
    formik.setFieldValue("retainer_value", e.package_deposite_price);
    formik.setFieldValue("package_overtime", e.package_overtime);
    formik.setFieldValue("package_duration", e.package_duration);
  }

  function addData() {
    if (
      !formik.values.addon_name ||
      !formik.values.quantity ||
      !formik.values.price
    ) {
      toast.error("Please fill in all fields");
      return;
    }

    const newRow = {
      no: formik.values.addons?.length + 1,
      addon_name: formik.values.addon_name,
      quantity: parseInt(formik.values.quantity),
      price: formik.values.price,
      Total: formik.values.quantity * formik.values.price,
    };

    if (formik.values.addons?.length > 0) {
      if (
        formik.values.addons.filter(
          (item) => item.addon_name === formik.values.addon_name
        ).length > 0
      ) {
        toast.error("Same Field Alredy Exist");
        return;
      }
    }

    let tempArr = [...formik.values.addons];
    let newTotal =
      formik.values.add_on_total + formik.values.quantity * formik.values.price;

    tempArr.push(newRow);
    formik.setFieldValue("addons", tempArr);
    formik.setFieldValue("addon_name", "");
    formik.setFieldValue("quantity", 0);
    formik.setFieldValue("price", 0);
    formik.setFieldTouched("addon_name", false);
    formik.setFieldTouched("quantity", false);
    formik.setFieldTouched("price", false);
    formik.setFieldValue("add_on_total", newTotal);
  }

  //function to delete a row from table
  function deleteRow(row) {
    const getIndex = formik.values.addons.indexOf(row);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updateData = formik.values.addons.filter(
          (item) => item.addon_name !== row.addon_name
        );
        if (type === "edit") {
          formik.setFieldValue(
            "add_on_total",
            formik.values.add_on_total -
              formik.values.addons[getIndex].price *
                formik.values.addons[getIndex].quantity
          );
        } else {
          formik.setFieldValue(
            "add_on_total",
            formik.values.add_on_total - formik.values.addons[getIndex].Total
          );
        }
        formik.setFieldValue("addons", updateData);
        Swal.fire("Deleted!", "Your add on has been deleted.", "success");
      }
    });
  }

  function handleOvertimeChange(e) {
    const newOvertimeHours = parseFloat(e.target.value) || 0;
    const newOvertimeRateTotal =
      formik.values.package_overtime * newOvertimeHours;

    formik.setFieldValue("overtimeHours", newOvertimeHours);
    formik.setFieldValue("overtimeRateTotal", newOvertimeRateTotal);
  }

  // useEffect(() => {
  //   let add =
  //   formik.values.package_duration === "h" ? formik.values.package_price * formik.values.timeDiff + formik.values.add_on_total : formik.values.package_price +
  //     formik.values.add_on_total +
  //     (formik.values.travel_cost !== "" && formik.values.travel_cost !== 0 ? parseInt(formik.values.travel_cost) : 0) +
  //     formik.values.overtimeRateTotal;
  //   let sub = formik.values.discount1 + formik.values.discount2;

  //   formik.setFieldValue("total", add - sub);
  // }, [formik.values.overtimeHours, formik.values.package_overtime, formik.values.overtimeRateTotal]);

  useEffect(() => {
    // Calculate the total
    const add =
      formik.values.package_duration === "h"
        ? formik.values.package_price * formik.values.timeDiff +
          formik.values.add_on_total // Include add_on_total here
        : formik.values.package_price +
          formik.values.add_on_total +
          (formik.values.travel_cost !== "" && formik.values.travel_cost !== 0
            ? parseInt(formik.values.travel_cost)
            : 0) +
          formik.values.overtimeRateTotal;

    const sub = formik.values.discount1 + formik.values.discount2;

    // Update the total in formik
    formik.setFieldValue("total", add - sub);
  }, [
    formik.values.package_duration,
    formik.values.package_price,
    formik.values.timeDiff,
    formik.values.add_on_total,
    formik.values.travel_cost,
    formik.values.overtimeRateTotal,
    formik.values.discount1,
    formik.values.discount2,
  ]);

  //to run all the calculations in invoice side by side
  useEffect(() => {
    let add =
      formik.values.package_duration == "h"
        ? formik.values.package_price * formik.values.timeDiff +
          formik.values.add_on_total
        : formik.values.package_price +
          formik.values.add_on_total +
          (formik.values.travel_cost !== "" && formik.values.travel_cost !== 0
            ? parseInt(formik.values.travel_cost)
            : 0) +
          formik.values.overtimeRateTotal;

    let sub = formik.values.discount1 + formik.values.discount2;

    //this sets values in formik for letter Use
    formik.setFieldValue("total", add - sub);
    // formik.setFieldValue("balanceDue", parseInt(formik.values.total) - parseInt(formik.values.retainer_value));
    formik.setFieldValue(
      "balanceDue",
      add - sub - formik.values.retainer_value
    );

    if (formik.values.retainer_value >= add - sub) {
      formik.setFieldValue("retainer_value", formik.values.total);
      formik.setFieldValue("scheduled_payment_2", 0);
      formik.setFieldValue("scheduled_payment_2_value", 0);
      formik.setFieldValue("scheduled_payment_3", 0);
      formik.setFieldValue("scheduled_payment_3_value", 0);

      // disebleing the schedule inputs
      setPayment1Done(true);
      setPayment2Done(true);
    } else {
      setPayment1Done(false);
      setPayment2Done(false);
    }

    // if all payment done in payments schedule 2 diseble payment schedule3
    if (
      formik.values.scheduled_payment_2_value + formik.values.retainer_value ===
      add - sub
    ) {
      formik.setFieldValue("scheduled_payment_3", 0);
      formik.setFieldValue("scheduled_payment_3_value", 0);
      setPayment2Done(true);
    }

    if (
      formik.values.scheduled_payment_2_value + formik.values.retainer_value >
      add - sub
    ) {
      formik.setFieldValue(
        "scheduled_payment_2_value",
        add - sub - formik.values.retainer_value
      );
    }
    if (
      formik.values.scheduled_payment_2_value +
        formik.values.scheduled_payment_3_value +
        formik.values.retainer_value >
      add - sub
    ) {
      formik.setFieldValue(
        "scheduled_payment_3_value",
        formik.values.total -
          (formik.values.retainer_value +
            formik.values.scheduled_payment_2_value)
      );
    }
  }, [
    formik.values.package_price,
    formik.values.add_on_total,
    formik.values.travel_cost,
    formik.values.retainer_value,
    formik.values.total,
    formik.values.balanceDue,
    formik.values.discount1,
    formik.values.discount2,
    formik.values.scheduled_payment_2_value,
    formik.values.scheduled_payment_3_value,
  ]);

  // // AUTO CALCULATE AND PREFILL THE SCHEDULED PAYMENT VALUES
  useEffect(() => {
    if (scheduledPayment3 === null) {
      if (formik.values.retainer_value < formik.values.total) {
        formik.setFieldValue(
          "scheduled_payment_2_value",
          formik.values.balanceDue
        );
        formik.setFieldValue("scheduled_payment_2", formik.values.eventDate);
      }
    }
  }, [
    scheduledPayment3,
    formik.values.retainer_value,
    formik.values.package_price,
    formik.values.total,
    formik.values.balanceDue,
  ]);

  useEffect(() => {
    if (
      formik.values.retainer_value + formik.values.scheduled_payment_2_value <
        formik.values.total &&
      formik.values.scheduled_payment_2_value !== formik.values.balanceDue
    ) {
      formik.setFieldValue(
        "scheduled_payment_3_value",
        formik.values.balanceDue - formik.values.scheduled_payment_2_value
      );
      formik.setFieldValue("scheduled_payment_3", formik.values.eventDate);
    }
  }, [
    formik.values.scheduled_payment_2_value,
    formik.values.retainer_value,
    formik.values.package_id,
    formik.values.add_on_total,
  ]);

  useEffect(() => {
    let add =
      formik.values.package_price +
      formik.values.add_on_total +
      parseInt(formik.values.travel_cost) +
      formik.values.overtimeRateTotal;

    let sub = formik.values.discount1 + formik.values.discount2;
  }, [formik.values.retainer_value]);

  useEffect(() => {
    let add =
      formik.values.package_duration == "h"
        ? formik.values.package_price * formik.values.timeDiff
        : formik.values.package_price +
          formik.values.add_on_total +
          parseInt(formik.values.travel_cost) +
          formik.values.overtimeRateTotal;

    let sub = formik.values.discount1 + formik.values.discount2;

    if (
      formik.values.scheduled_payment_2_value + formik.values.retainer_value !==
        add - sub &&
      type !== "edit" &&
      !localStorage.getItem("EventformData")
    ) {
      formik.setFieldValue("scheduled_payment_3_value", 0);
      setPayment2Done(false);
    }

    if (
      formik.values.scheduled_payment_2_value + formik.values.retainer_value ===
      add - sub
    ) {
      formik.setFieldValue("scheduled_payment_3_value", 0);
      setPayment2Done(true);
    }
  }, [formik.values.scheduled_payment_2_value, formik.values.retainer_value]);

  useEffect(() => {
    let add =
      formik.values.package_duration == "h"
        ? formik.values.package_price * formik.values.timeDiff
        : formik.values.package_price +
          formik.values.add_on_total +
          parseInt(formik.values.travel_cost) +
          formik.values.overtimeRateTotal;

    let sub = formik.values.discount1 + formik.values.discount2;
    if (
      formik.values.scheduled_payment_2_value +
        formik.values.retainer_value +
        formik.values.scheduled_payment_3_value >
      add - sub
    ) {
      formik.setFieldValue(
        "scheduled_payment_3_value",
        formik.values.total -
          formik.values.retainer_value -
          formik.values.scheduled_payment_2_value
      );
    }
  }, [formik.values.scheduled_payment_3_value]);

  let columns = [
    { name: "Sr.NO", selector: (row, index) => index + 1 },
    { name: "Add On", selector: (row) => row.addon_name },
    // { name: "Quantity", selector: (row) => row.quantity },
    { name: "Hourly/Daily", selector: (row) => row.quantity },
    { name: "Price", selector: (row) => `${currency}` + `${row.price}` },
    // { name: "Value", selector: (row) => row.price },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Icon
            className="ni-trash"
            onClick={() => deleteRow(row)}
            style={{ cursor: "pointer", fontSize: "18px" }}
          ></Icon>
        </>
      ),
    },
  ];

  if (disabled) {
    columns = columns.filter(
      (e) =>
        e.name === "Sr.NO" ||
        e.name === "Add On" ||
        e.name === "Quantity" ||
        e.name === "Value"
    );
  }

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("noscroll")
    ) {
      document.activeElement.blur();
    }
  });

  const handelValidation = (callbackFun) => {
    // if (formik.errors.package_overtime) {
    //   formik.setFieldTouched("package_overtime", true);
    // }

    // if (formik.errors.overtimeRateHalfHourly) {
    //   formik.setFieldTouched("overtimeRateHalfHourly", true);
    // }

    if (
      formik.values.package_id !== "" &&
      formik.values.retainer_value + formik.values.scheduled_payment_2_value !==
        formik.values.total
    ) {
      if (
        formik.values.scheduled_payment_2_value +
          formik.values.scheduled_payment_3_value +
          formik.values.retainer_value !==
        formik.values.total
      ) {
        toast.error("Invalid Scheduled 3 Payment");
        return;
      }
    }

    callbackFun(true);
  };

  const handlePackageClick = () => {
    navigate("/package-list/create-package?type=null&id=null&page=event");
    localStorage.setItem("financialroute", route);
  };

  useEffect(() => {
    localStorage.removeItem("financialroute");
  }, []);

  return (
    <>
      <Row className="p-2 py-0">
        <Col md={6} className="border p-3 bg-white">
          <Row>
            <Col md={4}>
              <div>
                <Label for=" package_name">Package</Label>
                {packageListLoading ? (
                  <ShimmerThumbnail height={40} width={300} rounded />
                ) : (
                  <ReactSelect
                    options={packagesOptions}
                    id=" package_id"
                    name=" package_id"
                    // disabled={disabled}
                    disabled={isViewOnly}
                    value={formik.values.package_id}
                    onChange={(e) => handleDemoValue(e)}
                    onBlur={() => formik.setFieldTouched("package_id", true)}
                  />
                )}

                {formik.errors.package_id && formik.touched.package_id && (
                  <p style={{ color: "red" }}> {formik.errors.package_id}</p>
                )}
              </div>
            </Col>
            <Col md={4} className="mt-4">
              <div className="input-group mt-1">
                <Input
                  type="number"
                  className="form-control"
                  // disabled
                  disabled={isViewOnly}
                  name="package_price"
                  value={formik.values.package_price}
                  onChange={(e) =>
                    formik.setFieldValue("package_price", e.target.value)
                  }
                />
                <div className="input-group-append">
                  {/* <span className="input-group-text">$</span> */}
                  <span className="input-group-text">{currency}</span>
                </div>
                <div className="input-group-append">
                  {/* <span className="input-group-text">$</span> */}
                  <span className="input-group-text">
                    {formik.values.package_duration == "d" ? "Daily" : "Hourly"}
                  </span>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="input-group mt-4 py-1" style={{ zIndex: "auto" }}>
                <Button
                  // color="primary"
                  className="btn-primary"
                  // disabled={disabled}
                  disabled={isViewOnly}
                  outline
                  onClick={handlePackageClick}
                  // onClick={() => navigate("/package-list/create-package?type=null&id=null&page=event")}
                  style={{ zIndex: "auto" }}
                >
                  {/* <Icon className="ni-plus"></Icon> */}
                  <span style={{color:"white"}}>Add New Package</span>
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mt-3 g-2">
            <Col md={3}>
              <div>
                <Label for="AddOn">Add On</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Add On Name"
                  id="addon_name"
                  // disabled={disabled}
                  disabled={isViewOnly}
                  name="addon_name"
                  value={formik.values.addon_name}
                  onChange={(e) =>
                    formik.setFieldValue("addon_name", e.target.value)
                  }
                />
                {formik.errors.addon_name && formik.touched.addon_name && (
                  <p style={{ color: "red" }}> {formik.errors.addon_name}</p>
                )}
              </div>
            </Col>
            <Col md={3}>
              <div>
                {/* <Label for="quantity">Quantity</Label> */}
                <Label for="quantity">Hourly/Daily</Label>
                <Input
                  className="noscroll"
                  type="number"
                  id="quantity"
                  name="quantity"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  // disabled={disabled}
                  disabled={isViewOnly}
                  value={formik.values.quantity}
                  onChange={(e) =>
                    formik.setFieldValue("quantity", e.target.value)
                  }
                  onBlur={formik.handleBlur}
                />
                {formik.errors.quantity && formik.touched.quantity && (
                  <p style={{ color: "red" }}> {formik.errors.quantity}</p>
                )}
              </div>
            </Col>
            <Col md={3}>
              <div>
                <Label for="value">Price</Label>
                <Input
                  className="noscroll"
                  type="number"
                  id="price"
                  name="price"
                  // disabled={disabled}
                  disabled={isViewOnly}
                  value={formik.values.price}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  onChange={(e) =>
                    formik.setFieldValue("price", e.target.value)
                  }
                  onBlur={formik.handleBlur}
                />
                {formik.errors.price && formik.touched.price && (
                  <p style={{ color: "red" }}> {formik.errors.price}</p>
                )}
              </div>
            </Col>

            <Col md={3}>
              <div className="mt-4">
                <Button
                  onClick={() => addData()}
                  // color="primary"
                  className="mt-1 btn-dim btn-primary"
                  disabled={isViewOnly}
                  outline
                >
                  {/* <Icon className="ni-plus"></Icon> */}
                  <span style={{color:"white"}}>Add</span>
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mt-3 g-2">
            <Label>Add On Details</Label>
            <ReactDataTable
              loading={packageListLoading}
              data={formik.values.addons}
              columns={columns}
              searchBy="addon_name"
              pagination
              normalSearch
            />
          </Row>

          <Row className="mt-2 px-3">
            <Label style={{ marginLeft: "-10px" }}>Additional Details</Label>
            <div className="table-responsive">
              <table className="table table-bordered ">
                <tbody>
                  <tr>
                    <td>Retainer Value</td>
                    <td>
                      {/* $ */}
                      {currency}
                      <TableInput
                        value={formik.values.retainer_value}
                        name="retainer_value"
                        // disabled={disabled}
                        disabled={isViewOnly}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Overtime Rate (Hourly)</td>
                    <td>
                      {/* $ */}
                      {currency}
                      <TableInput
                        value={formik.values.package_overtime}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        name="package_overtime"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // disabled={disabled}
                        disabled={isViewOnly}
                        error={
                          formik.touched.package_overtime &&
                          formik.errors.package_overtime
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Overtime Hours </td>
                    <td>
                      {/* $ */}
                      {currency}
                      <TableInput
                        value={formik.values.overtimeHours}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        name="overtimeHours"
                        onChange={(e) => handleOvertimeChange(e)}
                        onBlur={formik.handleBlur}
                        // disabled={disabled}
                        disabled={isViewOnly}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Travel Fee</td>
                    <td>
                      {/* $ */}
                      {currency}
                      <TableInput
                        value={formik.values.travel_cost}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        name="travel_cost"
                        // disabled={disabled}
                        disabled={isViewOnly}
                        onChange={(e) =>
                          formik.setFieldValue("travel_cost", e.target.value)
                        }
                        onBlur={formik.handleBlur}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {/* <ReactSelect
                      options={discount1}
                      id="discount1"
                      name="discount1"
                      disabled={disableDiscount}
                      placeholder="Discount 1"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: "none",
                          marginLeft: "-10px",
                          padding: "0px",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          margin: "0px",
                          padding: "0px",
                        }),
                      }}
                      value={formik.values.discount1}
                      onChange={(e) => formik.setFieldValue("discount1", e.value)}
                      onBlur={() => formik.setFieldTouched("discount1", true)}
                    /> */}
                      Discount 1
                    </td>
                    <td>
                      {/* $ */}
                      {currency}
                      <TableInput
                        value={formik.values.discount1}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        name="discount1"
                        // disabled={disabled}
                        disabled={isViewOnly}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {/* <ReactSelect
                      options={discount2}
                      id="discount2"
                      disabled={disableDiscount}
                      name="discount2"
                      value={formik.values.discount2}
                      placeholder="Discount 2"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: "none",
                          marginLeft: "-10px",
                          padding: "0px",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          margin: "0px",
                          padding: "0px",
                        }),
                      }}
                      onChange={(e) => formik.setFieldValue("discount2", e.value)}
                      onBlur={() => formik.setFieldTouched("discount2", true)}
                    /> */}
                      Discount 2
                    </td>
                    <td>
                      {/* $ */}
                      {currency}
                      <TableInput
                        value={formik.values.discount2}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        name="discount2"
                        onChange={formik.handleChange}
                        // disabled={disabled}
                        disabled={isViewOnly}
                        onBlur={formik.handleBlur}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
        </Col>
        {/* Second Section */}
        <Col md={6} className="border p-3 bg-white border-start-0">
          <Label>Fee Summary</Label>

          <table className="table table-bordered ">
            <thead>
              <tr className="fw-bold">
                <th scope="col">Item</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="fw-light">
                <td>Demo Package</td>
                {/* <td>$ {formik.values.package_price}</td> */}
                <td>
                  {currency}{" "}
                  {formik.values.package_duration == "d"
                    ? formik.values.package_price
                    : `${formik.values.package_price} X ${
                        formik.values.timeDiff
                      } = ${currency}${(
                        formik.values.package_price * formik.values.timeDiff
                      )?.toFixed(0)}`}
                </td>
              </tr>
              <tr className="fw-light">
                <td>Add On Total</td>
                {/* <td>$ {formik.values.add_on_total}</td> */}
                <td>
                  {currency} {formik.values.add_on_total.toFixed(0)}
                </td>
              </tr>
              {formik.values.overtimeRateTotal !== "" &&
                formik.values.overtimeRateTotal !== 0 && (
                  <tr className="fw-light">
                    <td>Overtime Total</td>
                    {/* <td>$ {formik.values.overtimeRateTotal}</td> */}
                    <td>
                      {currency} {formik.values.overtimeRateTotal}
                    </td>
                  </tr>
                )}
              {formik.values.travel_cost !== "" &&
                formik.values.travel_cost !== 0 && (
                  <tr className="fw-light">
                    <td>Travel Fee</td>
                    {/* <td>$ {formik.values.travel_cost}</td> */}
                    <td>
                      {currency} {formik.values.travel_cost}
                    </td>
                  </tr>
                )}
              {formik.values.discount1 !== "" && formik.values.discount1 !== 0 && (
                <tr className="fw-light">
                  <td>Discount 1</td>
                  {/* <td>$ {formik.values.discount1}</td> */}
                  <td>
                    {currency} {formik.values.discount1}
                  </td>
                </tr>
              )}
              {formik.values.discount2 !== "" && formik.values.discount2 !== 0 && (
                <tr className="fw-light">
                  <td>Discount 2</td>
                  {/* <td>$ {formik.values.discount2}</td> */}
                  <td>
                    {currency} {formik.values.discount2}
                  </td>
                </tr>
              )}
              <tr className="fw-bold">
                <td>Total</td>
                {/* <td>$ {formik.values.total}</td> */}
                <td>
                  {currency} {formik.values.total?.toFixed(0)}
                </td>
              </tr>
              <tr className="fw-light">
                <td>Retainer Value</td>
                {/* <td>$ {formik.values.retainer_value}</td> */}
                <td>
                  {currency} {formik.values.retainer_value?.toFixed(0)}
                </td>
              </tr>
              <tr className="fw-bold">
                <td>Balance Due After Retainer</td>
                {/* <td>$ {formik.values.balanceDue}</td> */}
                <td>
                  {currency} {formik.values.balanceDue?.toFixed(0)}
                </td>
              </tr>
            </tbody>
          </table>

          <Col md={12} className="mt-4">
            <div>
              <Label>Scheduled Payment - 1</Label>
              <p>
                {" "}
                [ The retainer value is considered as scheduled payment 1 so
                there are no settings for this ]
              </p>
            </div>
          </Col>

          <Col md={12} className="mt-4">
            <Row>
              <Col md={8}>
                <div>
                  <Label for="scheduledPayment2">
                    Scheduled Payment - 2 {!payment1Done && <Required />}{" "}
                  </Label>
                  <DatePicker
                    isClearable
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormatCalendar="MMMM"
                    // minDate={new Date()}
                    // maxDate={formik.values.eventDate - 1}
                    // minDate={formik.values.scheduled_payment_2 + 1}
                    minDate={new Date()}
                    maxDate={formik.values.eventDate - 1}
                    placeholderText="Scheduled Payment Date"
                    id="scheduled_payment_2"
                    name="scheduled_payment_2"
                    dateFormat={"dd/MM/yy"}
                    disabled={disabled ? disabled : payment1Done}
                    selected={
                      formik.values.scheduled_payment_2
                        ? new Date(formik.values.scheduled_payment_2)
                        : ""
                    }
                    onChange={(date) =>
                      formik.setFieldValue(
                        "scheduled_payment_2",
                        date ? date.toISOString() : ""
                      )
                    }
                    className="form-control date-picker"
                    autoComplete="off"
                  />
                  {formik.errors.scheduled_payment_2 &&
                    formik.touched.scheduled_payment_2 && (
                      <p style={{ color: "red" }}>
                        {" "}
                        {formik.errors.scheduled_payment_2}
                      </p>
                    )}
                </div>
              </Col>
              <Col md={3} className="mt-4">
                <div className="input-group mt-1">
                  <Input
                    type={payment1Done ? "text" : "number"}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    name="scheduled_payment_2_value"
                    max
                    className="form-control noscroll"
                    disabled={disabled ? disabled : payment1Done}
                    value={formik.values.scheduled_payment_2_value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.scheduled_payment_2_value &&
                    formik.touched.scheduled_payment_2_value && (
                      <p style={{ color: "red" }}>
                        {" "}
                        {formik.errors.scheduled_payment_2_value}
                      </p>
                    )}
                  <div className="input-group-append">
                    {/* <span className="input-group-text">$</span> */}
                    <span className="input-group-text">{currency}</span>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col md={8}>
                <div>
                  <Label for="scheduledPayment3">
                    Scheduled Payment - 3{!payment2Done && <Required />}
                  </Label>
                  <DatePicker
                    isClearable
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormatCalendar="MMMM"
                    // minDate={formik.values.scheduled_payment_2 + 1}
                    minDate={
                      new Date(formik.values.scheduled_payment_2).getTime() +
                      86400000
                    }
                    maxDate={formik.values.eventDate - 1}
                    placeholderText="Scheduled Payment Date"
                    id="scheduled_payment_3"
                    name="scheduled_payment_3"
                    dateFormat={"dd/MM/yy"}
                    disabled={disabled ? disabled : payment2Done}
                    selected={
                      formik.values.scheduled_payment_3
                        ? new Date(formik.values.scheduled_payment_3)
                        : ""
                    }
                    onChange={(date) =>
                      formik.setFieldValue(
                        "scheduled_payment_3",
                        date ? date.toISOString() : ""
                      )
                    }
                    className="form-control date-picker"
                    autoComplete="off"
                  />
                  {formik.errors.scheduled_payment_3 &&
                    formik.touched.scheduled_payment_3 && (
                      <p style={{ color: "red" }}>
                        {" "}
                        {formik.errors.scheduled_payment_3}
                      </p>
                    )}
                </div>
              </Col>
              <Col md={3} className="mt-4">
                <div className="input-group mt-1">
                  <Input
                    type={payment2Done ? "text" : "number"}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    id="scheduled_payment_3_value"
                    name="scheduled_payment_3_value"
                    disabled={disabled ? disabled : payment2Done}
                    value={formik.values.scheduled_payment_3_value}
                    className="form-control noscroll"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.scheduled_payment_3_value &&
                    formik.touched.scheduled_payment_3_value && (
                      <p style={{ color: "red" }}>
                        {" "}
                        {formik.errors.scheduled_payment_3_value}
                      </p>
                    )}
                  <div className="input-group-append">
                    {/* <span className="input-group-text">$</span> */}
                    <span className="input-group-text">{currency}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
      {isViewOnly ? (
        ""
      ) : (
        <NavigationFooter
          toggle={toggleMain}
          currentState={mainCurrentTab}
          lastPage={"5"}
          validationFunction={handelValidation}
          isViewOnly={disabled}
          formik={formik}
          isEventEditing={isEventEditing}
          emailAvialable={true}
          usernameAvailable={true}
        />
      )}
    </>
  );
};

export default EFinancials;
