import { Row, Col, Button, Label, Input } from "reactstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ReactSelect from "../../../components/ReactSelect/ReactSelect";
import Required from "../../components/Required";
import { Icon } from "../../../components/Component";
import { getVenueList } from "../../../http/get/getApi";
import { useQuery } from "react-query";
import { transformData } from "../../../utils/Utils";

const TabContent = ({ buttonName, setSecondaryVenue, Heading, isEnable, formik, name, isViewOnly }) => {
  const navigate = useNavigate();

const [route, setRoute]= useState(`${window.location.pathname}${window.location.search}`)
const handelNewVenueClick = () => {
  navigate("/venue-list/create-venue?page=event");
  localStorage.setItem("route",route)
  };

  useEffect(()=>{
    localStorage.removeItem("route");
  },[])

  //fetching venueList data
  const {
    data: venuesList,
    isLoading: venueListLoading,
    isError: venueListError,
  } = useQuery({
    queryKey: ["get-venue-list"],
    queryFn: () => getVenueList({ id: "", page: "", limit: "", search: "" }),
    // staleTime: 50000
    staleTime: Infinity
  });

  function handleExistingVenue(e) {
    formik.setFieldValue(name, e.value);
  }

  //options for react select (nextActions)
  const venueOptions = transformData({
    dataArray: venuesList?.data?.list || [],
    label: "venue_name",
    value: "id",
    isLoading: venueListLoading,
  });

  return (
    <>
      <h5 className="mb-3" style={{ marginTop: isEnable ? "0px" : "18px" }}>
        {Heading}
      </h5>
      <Row className="p-2 py-0">
        <Col md={12} className="border p-3 bg-white">
          {/* First  Row */}
          <Row className="row g-2">
            <Label for="existingVenue">
              <div>
                Selected Venue
                {/* <Required /> */}
              </div>
            </Label>
            <>
              <Col sm="6" md="7">
                {!venueListLoading && (
                  <ReactSelect
                   disabled={isViewOnly}
                    options={venueOptions}
                    placeholder={"Select Venue"}
                    id={name}
                    name={name}
                    value={formik.values[name] || null}
                    onChange={(e) => handleExistingVenue(e)}
                    onBlur={() => formik.setFieldTouched(name, true)}
                  />
                )}
              </Col>
              {isEnable && (
                <Col sm="6" lg="3">
                  <Button
                    // color="primary"
                    className="btn-dim btn-primary"
                    disabled={isViewOnly}
                    outline
                    onClick={() => {
                      setSecondaryVenue(true);
                    }}
                  >
                    {/* <Icon className="ni-plus"></Icon> */}
                    <span style={{color:"white"}}>{buttonName}</span>
                  </Button>
                </Col>
              )}
            </>
          </Row>
          {/* Second  Row */}
          <Row className="p-2 py-0 ">
            <Col md={5} className="mx-2 mt-4  p-3 bg-white">
              <Label>Create New Venue</Label>
              <p>
                Click to create new venue details. A new venue record will be created for you automatically from the
                details you enter.
              </p>
              <Button color="primary"  disabled={isViewOnly} onClick={handelNewVenueClick}>
                {/* <Icon className="ni-plus"></Icon> */}
                <span>Add New Venue</span>
              </Button>
            </Col>
            {/* <Col md={5} className="mx-2 mt-4 p-3 bg-white">
              <Label>Use Clients Address</Label>
              <p>
                Click to use the client address details as the venue details. A new venue record will be created for you
                automatically from the details
              </p>
              <Button color="primary" disabled={isViewOnly} >
                <span>Use Clients Address</span>
              </Button>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TabContent;
