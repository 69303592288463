import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import NavigationHeader from "../../components/NavigationHeader";
import { useLocation, useNavigate } from "react-router";
import BasicInfo from "../../Profile/BasicInfo";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {  editPhotoDeatils } from "../../../http/edit/editApi";
import { CategoryScale } from "chart.js";
import { toast } from "react-toastify";
import { Icon } from "../../../components/Component";
import { getChangedValues, getCookie } from "../../../utils/Utils";
import Swal from "sweetalert2";
import { DataContext } from "../../../context/DataProvider";
import { getPhotoProfile } from "../../../http/get/getApi";
import { addPhotoGrapherEvent } from "../../../http/post/postApi";
import Albums from "./Albums";
import PhotoGrapherGallary from "./PhotoGrapherGallary";
import PhotoGrapherVideo from "./PhotoGrapherVideo";
import BasicinfoPhotographer from "./BasicinfoPhotographer";

const PhotoGraphyEvent = () => {
  const useExampleContext = useContext(DataContext);

  const {
    setProfileProgress,
    profileprogress,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    planEnddates,
    setPlanEndDates,
    activeTab,
    setActivetab,
    subscribedpalresponce,
    setSubscribedPalResponce,
  } = useExampleContext;
  // navigation declrations
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  //getting details from the url
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const page = searchParams.get("page");
  const isEditOnly = type === "edit" ? true : false;
  const isViewOnly = type === "view" ? true : false;
  useEffect(() => {
    if (type === null) {
      formik.setValues(initialValues);
    }
  }, [type]);
  // const [activeTab, setActivetab] = useState("1");
  const toggle = (number) => {
    setActivetab(number);
  };
  const schema = Yup.object().shape({
    first_name: Yup.string().required("First name field is required."),
    last_name: Yup.string().required("Last name field is required."),
    city: Yup.string().required("City  field is required."),
    country: Yup.string().required("country  field is required."),
    state: Yup.string().required("state  field is required."),
  });
  const [planEndDate, setPlanEndDate] = useState();
  const [photosLimit, setphotoslimit] = useState({});
  const [serviceList, SetService_list] = useState();
  const [videosLimit, setVideoslimit] = useState();
  const [youtubLinkLimit, setYoutubLinkLimit] = useState();

  const [business_descriptionlimit, Setbusiness_description] = useState();
  const [mixersLimit, SetMixersLimit] = useState();
  const [SocialMediaUrls, SetSocialMediaUrls] = useState();

  let initialValues = {
    email: "",
    username: "",
    password: "",
    first_name: "",
    last_name: "",
    contact_number: "",
    phone_number: "",
    personal_phone_number: "",
    address_1: "",
    address_2: "",
    country: "",
    state: "",
    files: "",
    albumns:[],
    city: "",
    zip_code: "",
    youtube_links: [],
    link: "",
    title: "",
    id:"",
    currentYoutubeLink: "",
    category: [],
    images: [],
    videos: [],
    mixes: [],
    profile_image_path:"",
    mixes_links: [],
    mixlink: "",
    mixtitle: "",
    mobileNumber: "",
    mixres: "",
    nameCount: "",
    lastNameCount: "",
    contactNumberCount: "",
    personal_phone_number_count: "",
    address1Count: "",
    address2Count: "",
    zipCodeCount: "",
    imagePresent: "",
    mixesType: "",
    album_pdf:"",
    
  };
  // get company profile
  const {
    data: companyDetails1,
    isLoading: companyprofileLoading,
    isError: companyprofileError,
  } = useQuery({
    queryKey: ["get-photographer-event-by-id", id],
    queryFn: () => getPhotoProfile({id: id}),
    staleTime: Infinity,
    cacheTime: 300000,
    retry: 1,
  });
  // Mutation hook for edit photographer
  const {
    mutate: companyMutation,
    isLoading: isCompanyProfileEditing,
    error: companyProfileError,
  } = useMutation({
    mutationKey: ["edit-photographer-event"],
    mutationFn: (value, id) => editPhotoDeatils(value, id),
    onSuccess: (data) => {
      if (data.status) {
        queryClient.invalidateQueries(["get-photographer-event-by-id"]);
        // queryClient.invalidateQueries(["get-photographer-event"]);
        navigate(`/photographer-list/add-photographer-event?type=edit&id=${data.data.event_id}`);
      }
      if (data?.status == true) {
        Swal.fire({
          text: data?.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: data?.message,
          text: data?.errors,
          icon: "warning",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    },
    onError: (error) => {
      toast.error("Failed to update company profile.");
    },
  });
  //   add
  const {
    mutate: addPhotoEvent,
    isLoading: isEmployeeAdding,
    error: employeeAddError,
  } = useMutation({
    mutationKey: ["Add-Events"],
    mutationFn: (values) => addPhotoGrapherEvent(values),
    onSuccess: (data) => {
      // console.log("data", data);
      if (!data.errors && data.status) {
        navigate(`/photographer-list/add-photographer-event?type=edit&id=${data.data.eventdata[0].id}`);
      } else if (data.errors) {
        Swal.fire({
          icon: "info",
          // title: "Error",
          text: data.errors[0],
          focusConfirm: false,
        });
      } else {
        Swal.fire({
          icon: "info",
          // title: "Error",
          text: data.message,
          focusConfirm: false,
        });
      }
    },
  });
  const editCompanyProfile = (value) => {
    console.log(id, "editCompanyProfile");
    try {
      companyMutation(value, id);
    } catch (error) {
      console.error("Error editing client:", error);
    }
  };

  useEffect(() => {
    subscribedPlanAPI();
  }, [photosLimit, videosLimit, youtubLinkLimit, mixersLimit]);
  const subscribedPlanAPI = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-subscribed-plan`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setSubscribedPalResponce(responseData.status);
        setPlanEndDates(responseData?.data?.package?.end_date);

        // setPlanEndDate(
        //   JSON.parse(
        //     responseData?.data?.package?.end_date ? responseData?.data?.package?.end_date : 0
        //   )
        // );

        setphotoslimit(
          JSON.parse(
            responseData?.data?.package?.plan_data?.photos ? responseData?.data?.package?.plan_data?.photos : 0
          )
        );

        SetMixersLimit(
          JSON.parse(responseData?.data?.package?.plan_data?.mixes ? responseData?.data?.package?.plan_data?.mixes : 0)
        );

        // const businessDescriptionString = responseData?.data?.package?.plan_data?.business_description
        //   ? responseData?.data?.package?.plan_data?.business_description
        //   : 0;
        // const parsedDescription = JSON.parse(businessDescriptionString);
        // const businessValue = parsedDescription;

        // Setbusiness_description(businessValue);
        Setbusiness_description(
          JSON.parse(
            responseData?.data?.package?.plan_data?.business_description
              ? responseData?.data?.package?.plan_data?.business_description
              : 0
          )
        );

        setVideoslimit(
          JSON.parse(
            responseData?.data?.package?.plan_data?.videos ? responseData?.data?.package?.plan_data?.videos : 0
          )
        );

        SetService_list(
          JSON.parse(
            responseData?.data?.package?.plan_data?.service_list
              ? responseData?.data?.package?.plan_data?.service_list
              : 0
          )
        );
        const youtubeLinkData = responseData?.data?.package?.plan_data?.youtube_link;
        const youtubeLinkLimit =
          youtubeLinkData === "Unlimited" ? Infinity : JSON.parse(youtubeLinkData ? youtubeLinkData : 0);
        setYoutubLinkLimit(youtubeLinkLimit);
        // setYoutubLinkLimit(JSON.parse(responseData?.data?.package?.plan_data?.youtube_link ? responseData?.data?.package?.plan_data?.youtube_link : 0));
        const socialMediaUrlsData = responseData?.data?.package?.plan_data?.social_media_urls;
        const socialMediaUrls =
          socialMediaUrlsData === "Unlimited" ? Infinity : JSON.parse(socialMediaUrlsData ? socialMediaUrlsData : 0);
        SetSocialMediaUrls(socialMediaUrls);
        // SetSocialMediaUrls(JSON.parse(responseData?.data?.package?.plan_data?.social_media_urls ? responseData?.data?.package?.plan_data?.social_media_urls : 0 ));
      } else {
        throw new Error("Failed to fetch plan data");
      }
      return response;
    } catch (error) {
      console.error("Error fetching plan data:", error);
    }
  };
  const [videoCount, SetvideoCount] = useState();
  const [mixesCount, SetMixesCount] = useState();
  const [imagecount, Setimagecount] = useState();
  const [youtubelinkCount, SetYoutubeLinkCount] = useState();
  useEffect(() => {
    if (!companyprofileLoading) {
      formik.setValues((prevData) => {
        const parseStringifiedObject = (stringifiedObject) => {
          if (!stringifiedObject) return {};
          try {
            return JSON.parse(stringifiedObject);
          } catch (error) {
            console.error("Error parsing stringified object:", error);
            return {};
          }
        };
        const youtubeLinks = JSON.parse(companyDetails1?.data?.eventdata?.youtube_links || "[]");
        const mixesLinks = JSON.parse(companyDetails1?.data?.eventdata?.mixes_links || "[]");
        Setimagecount(JSON.parse(companyDetails1?.data?.eventdata?.image_count || "[]"));
        SetvideoCount(JSON.parse(companyDetails1?.data?.eventdata?.video_count || "0"));
        SetMixesCount(JSON.parse(companyDetails1?.data?.eventdata?.mixres_count || "0"));
        SetYoutubeLinkCount(JSON.parse(companyDetails1?.data?.eventdata?.youtube_link_count || "0"));
        setProfileProgress(
          JSON.parse(
            companyDetails1?.data?.eventdata?.profile_progress ? companyDetails1?.data?.eventdata?.profile_progress : 0
          )
        );
        return {
          ...prevData,
          ...companyDetails1?.data.eventdata[0],
          profile_image_path: companyDetails1?.data.eventdata[0]?.profile_image_path,
          username: companyDetails1?.data?.eventdata[0]?.username,
          email: companyDetails1?.data?.eventdata[0]?.email,
          country: parseInt(companyDetails1?.data?.eventdata[0]?.country),
          state: parseInt(companyDetails1?.data?.eventdata[0]?.state),
          city: parseInt(companyDetails1?.data?.eventdata[0]?.city),
          latitude: parseFloat(companyDetails1?.data?.eventdata[0]?.latitude),
          longitude: parseFloat(companyDetails1?.data?.eventdata[0]?.longitude),
          // images: parseImages(companyDetails?.data?.profile?.images),
          images: companyDetails1?.data?.eventdata[0]?.images,
          videos: companyDetails1?.data?.eventdata[0]?.videos || [],
          files: companyDetails1?.data?.eventdata[0]?.profile_image_path,
          albumns: companyDetails1?.data?.eventdata[0]?.albumns,
          // mixes: companyDetails1?.data?.mixres || [],
          youtube_links: youtubeLinks,
          mixes_links: mixesLinks,
        };
      });
    }
  }, [companyprofileLoading, videoCount, companyDetails1, youtubelinkCount]);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value) => {
      console.log("wesrdtfgh", id);
      editCompanyProfile(getChangedValues(companyDetails1?.data?.eventdata, formik.values));
    },
  });

  // console.log("formk",formik.values)

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        const message = "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);
  return (
    <>
      <NavigationHeader
       heading={!id ? "Add Event" : "Edit Event"}
        buttonInnerText={"Events list"}
        route={"/photographer-list"}
        back
      />
      <section className="m-4 mt-2 border p-3 pt-0 bg-white" style={{ height: "auto" }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "1" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("1");
              }}
            >
              <Icon name="user" />
              <span> Basic Information</span>
            </NavLink>
          </NavItem>
       
          <NavItem>
            {/* mixes convert into album */}
            <NavLink
              // disabled
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "2" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("2");
              }}
            >
              <Icon name="file-pdf" />
              <span> PDF</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              // disabled
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "3" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("3");
              }}
            >
              <Icon name="img" />
              <span> Gallery</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              // disabled
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "4" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("4");
              }}
            >
              <Icon name="video" />
              <span> Videos</span>
            </NavLink>
          </NavItem>
          <NavItem></NavItem>
        </Nav>
        <form onSubmit={formik.handleSubmit} className="mt-3">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <BasicinfoPhotographer
                toggle={toggle}
                currentState={activeTab}
                isCompanyProfileEditing={isCompanyProfileEditing}
                companyprofileLoading={companyprofileLoading}
                formik={formik}
              />
            </TabPane>
            {/* mixes convert into album */}
            <TabPane tabId="2">
              <Albums
                toggle={toggle}
                currentState={activeTab}
                isCompanyProfileEditing={isCompanyProfileEditing}
                formik={formik}
                mixersLimit={mixersLimit}
                mixesCount={mixesCount}
                subscribedpalresponce={subscribedpalresponce}
                planEndDate={planEndDate}
              />
            </TabPane>
            <TabPane tabId="3">
              <PhotoGrapherGallary
                toggle={toggle}
                currentState={activeTab}
                isCompanyProfileEditing={isCompanyProfileEditing}
                formik={formik}
                photosLimit={photosLimit}
                imagecount={imagecount}
                subscribedpalresponce={subscribedpalresponce}
              />
            </TabPane>
            <TabPane tabId="4">
              <PhotoGrapherVideo
                toggle={toggle}
                currentState={activeTab}
                isCompanyProfileEditing={isCompanyProfileEditing}
                formik={formik}
                videosLimit={videosLimit}
                youtubLinkLimit={youtubLinkLimit}
                videoCount={videoCount}
                youtubelinkCount={youtubelinkCount}
                subscribedpalresponce={subscribedpalresponce}
              />
            </TabPane>
          </TabContent>
        </form>
      </section>
    </>
  );
};
export default PhotoGraphyEvent;
