import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import Required from "../../components/Required";
import { useForm } from "react-hook-form";
import { getCookie } from "../../../utils/Utils";
import PuffLoader from "react-spinners/PuffLoader";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function RazorpayPayment({
  price,
  payid,
  setModalTab,
  modalTab,
  handleSubmitSubscribePayment,
  publickey,
  duration_type,
}) {
  const [formData, setFormData] = useState({
    fname: "",
    email: "",
    contact: "",
    amount: price,
  });
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setFormData({
      fname: "",
      email: "",
      contact: "",
      amount: "",
    });
  };

  const displayRazorpay = async () => {
    setLoading(true);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      setLoading(false);
      return;
    }
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}api/v1/client/event-razor-payment`,
      {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: formData.amount,
          currency: "INR",
        }),
      }
    ).then((t) => t.json());
    setLoading(false);

    const options = {
      key: publickey,
      currency: "INR",
      name: formData.fname,
      order_id: data?.order_id,
      prefill: {
        name: formData.fname,
        email: formData.email,
        contact: formData.contact,
      },
      theme: {
        color: "#F37254",
      },
      handler: function (response) {
        fetch(
          `${process.env.REACT_APP_API_URL}api/v1/client/verify-razor-payment`,
          {
            method: "POST",
            headers: {
              authToken: getCookie("authToken"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            }),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.status) {
              resetForm();
              setModalTab(!modalTab);
              handleSubmitSubscribePayment();
            } else {
              console.log(data);
              alert("Payment Verification Failed");
            }
          })
          .catch((error) => {
            console.error("Error verifying payment:", error);
          });
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      <form onSubmit={handleSubmit(displayRazorpay)}>
        <Row>
          <Col lg={12}>
            <div className="form-group mt-3 px-1">
              <label className="form-label">Full Name</label>
              <Required />
              <input
                type="text"
                {...register("fname", {
                  required: "This field is required",
                  pattern: {
                    value: /^[A-Za-z\s]+$/,
                    message: "Only alphabets and spaces are allowed",
                  },
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters",
                  },
                })}
                maxLength={40}
                value={formData.fname}
                placeholder="Enter full name"
                onChange={(e) => {
                  setFormData({ ...formData, fname: e.target.value });
                }}
                className="form-control"
              />
              {errors.fname && (
                <span className="invalid">{errors.fname.message}</span>
              )}
            </div>
          </Col>
          <Col lg={12}>
            <div className="form-group mt-3 px-1">
              <label className="form-label">Email</label>
              {/* <Required />zz */}
              <input
                className="form-control"
                type="text"
                id="email"
                placeholder="Enter Email"
                {...register("email", {
                  // required: "This field is required",
                  pattern: {
                    value: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                    message: "Enter a valid email address",
                  },
                  maxLength: {
                    value: 255,
                    message: "Email must not exceed 255 characters",
                  },
                })}
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
              {errors.email && (
                <span className="invalid">{errors.email.message}</span>
              )}
            </div>
          </Col>
          <Col lg={12}>
            <div className="form-group mt-3 px-1">
              <label className="form-label">Contact Number</label>
              {/* <Required /> */}
              <input
                type="text" // Use "text" instead of "number"
                value={formData.contact}
                {...register("contact", {
                  // required: "This field is required",
                  pattern: {
                    value: /^\d{10,15}$/, // Ensure only digits are allowed
                    message: "Enter a valid phone number (10-15 digits)",
                  },
                  maxLength: {
                    value: 15,
                    message: "Contact number should not exceed 15 digits",
                  },
                })}
                onInput={(e) => {
                  if (e.target.value.length > 15) {
                    e.target.value = e.target.value.slice(0, 15); // Trim value to 15 digits
                  }
                }}
                onChange={(e) =>
                  setFormData({ ...formData, contact: e.target.value })
                }
                className="form-control bg-transparent border form-control"
                placeholder="Enter contact number"
              />
              {errors.contact && (
                <span className="invalid">{errors.contact.message}</span>
              )}
            </div>
          </Col>
          <Col lg={12}>
            <div className="form-group mt-3 px-1">
              <label className="form-label">Plan Pay Amount</label>
              <Required />
              <input
                type="number"
                value={formData.amount}
                disabled
                {...register("amount")}
                maxLength={15} // Enforce max length at the HTML level
                onChange={(e) => {
                  setFormData({ ...formData, amount: e.target.value });
                }}
                className="form-control border form-control"
                placeholder="Enter pay amount"
              />
              {errors.amount && (
                <span className="invalid">{errors.amount.message}</span>
              )}
            </div>
          </Col>
          <Col lg={12}>
            <div className="form-group mt-3 px-1">
              <Button color="primary" type="submit" disabled={loading}>
                {/* {loading ? "Processing..." : "Pay Now"} */}
                {loading ? (
                  <PuffLoader speedMultiplier="2" size="30" color={"#39ff00"} />
                ) : (
                  "Pay Now"
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
}

export default RazorpayPayment;
