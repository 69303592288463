import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Button, FormGroup, Label, Input, Spinner } from "reactstrap";
import NavigationFooter from "../../components/NavigationFooter";
import UploadMultipleImages from "../../Profile/UploadMultipleImages";
import Swal from "sweetalert2";
import { getCookie, transformData } from "../../../utils/Utils";
import { DataContext } from "../../../context/DataProvider";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteImages, deletePhotographerImages } from "../../../http/delete/deleteApi";
import { getCompanyProfile, getEventType, getPhotoGrapherType } from "../../../http/get/getApi";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Link, useLocation, useNavigate } from "react-router-dom";
import subscribeimg from "../../../assets/images/subscribe.jpg";
import { useTheme } from "../../../layout/provider/Theme";
import Select from "react-select";
import jsPDF from "jspdf";
import { getActiveEnvironmentVariable } from "../../../utils/Utils";
import dashboard from "../../../Jsonfiles/Dashboard.json"

const Allcategories = [
  { value: "Wedding", label: "Wedding" },
  { value: "Parties", label: "Parties" },
  { value: "Birthday Party", label: "Birthday Party" },
  { value: "Corporate Events", label: "Corporate Events" },
  { value: "School Events", label: "School Events" },
  { value: "Quinceanera", label: "Quinceanera" },
  { value: "Bar Mitzvah", label: "Bar Mitzvah" },
  { value: "Bat Mitzvah", label: "Bat Mitzvah" },
  { value: "Sweet Sixteen", label: "Sweet Sixteen" },
  { value: "Fashion Show", label: "Fashion Show" },
  { value: "Talent Show", label: "Talent Show" },
  { value: "Grand Opening Event", label: "Grand Opening Event" },
  { value: "Non-Profit Event", label: "Non-Profit Event" },
  { value: "Holiday Event", label: "Holiday Event" },
  { value: "Private Events", label: "Private Events" },
  // { value: "Other", label: "Other" },
];
// let token = process.env.REACT_APP_AUTH_TOKEN;
const PhotoGrapherGallary = ({
  currentState,
  formik,
  toggle,
  isCompanyProfileEditing,
  photosLimit,
  imagecount,
  subscribedpalresponce,
  styles,
}) => {
  const { hasUnsavedChanges, setHasUnsavedChanges, planEnddates, activeTab, setActivetab } = useContext(DataContext);
  const categories = Object.keys(formik?.values?.images || "");
  const queryClient = useQueryClient();
  const [selectedCategory, setSelectedCategory] = useState("Wedding");
  const [previewImages, setPreviewImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [draggedImage, setDraggedImage] = useState(null);
  const totlaCount = previewImages.length + imagecount;
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const { skin } = useTheme();
  const activeVariable = getActiveEnvironmentVariable();
  const customStyles = {
    // Style the container
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#fff" : "#fff", // Example dark mode background
      color: "black", // Change text color
      borderColor: state.isFocused ?  process.env.REACT_APP_THEME_COLOR: "#444", // Change border color when focused
      boxShadow: state.isFocused ? ` 0 0 0 1px ${process.env.REACT_APP_THEME_COLOR}` : null, // Highlight border on focus
    }),
    // Style for each option in the dropdown
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? process.env.REACT_APP_THEME_COLOR// Change background color when option is selected
        : state.isFocused
        ? "lightgray" // Change background color when option is hovered
        : null,
      color: state.isSelected ? "#ffff" : "#000000", // Change text color for selected and hovered options
      padding: 10,
    }),
    // Style for the dropdown menu
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff", // Background of dropdown
    }),
  };

  const darkModeStyles = {
    control: (provided, _state) => ({
      ...provided,
      backgroundColor: "#141c26",
      color: "white",
      // border: "1px solid #384d69",
      borderColor: _state.isFocused ? process.env.REACT_APP_THEME_COLOR : process.env.REACT_APP_THEME_COLOR,
      boxShadow: _state.isFocused ? ` 0 0 0 1px ${process.env.REACT_APP_THEME_COLOR}` : null,
    }),
    placeholder: (provided, _state) => ({ ...provided, color: "#fff", opacity: 0.7 }),
    singleValue: (provided, _state) => ({ ...provided, color: "white" }),
    valueContainer: (provided, _state) => ({ ...provided, color: "white" }),
    option: (provided, _state) => ({
      ...provided,
      // backgroundColor: "#141c26",
      backgroundColor: _state.isSelected
        ? process.env.REACT_APP_THEME_COLOR // Change background color when option is selected
        : _state.isFocused
        ? "lightgray" // Change background color when option is hovered
        : null,
      color: _state.isSelected ? "#ffff" : _state.isFocused ? "#000000" : "#fff",
      // color: "white",
      border: "1px solid #384d69",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#141c26", // Background of dropdown
    }),
  };

  const handleValidation = (callbackFun) => {
    // callbackFun(true);

    if (formik.values.first_name === "") {
      formik.setFieldTouched("first_name", true);
    }

    if (formik.values.last_name === "") {
      formik.setFieldTouched("last_name", true);
    }

    if (formik.values.phone_number === "") {
      formik.setFieldTouched("phone_number", true);
    }

    if (formik.values.city === "") {
      formik.setFieldTouched("city", true);
    }
    if (formik.values.country === "") {
      formik.setFieldTouched("country", true);
    }
    if (formik.values.state === "") {
      formik.setFieldTouched("state", true);
    }

    if (
      formik.values.first_name !== "" &&
      formik.values.last_name !== "" &&
      formik.values.phone_number !== "" &&
      formik.values.country !== "" &&
      formik.values.state !== "" &&
      formik.values.city !== ""
    ) {
      callbackFun(true);
    } else {
      callbackFun(false);
    }
  };

  const handleAddImage = (base64Strings) => {
    setPreviewImages((prev) => [...prev, ...base64Strings]);
  };

  const handleRemovePreviewImage = (index) => {
    const updatedImages = previewImages.filter((_, i) => i !== index);
    setPreviewImages(updatedImages);
    setHasUnsavedChanges(false);
  };

  const handleSavePreviewImages = () => {
    const currentDate = new Date(); // Get the current date
    const planEndDate = new Date(planEnddates); // Convert planEnddates to a Date object

    // if (previewImages?.length > 0 && !(totlaCount <= photosLimit)) {
    if (!subscribedpalresponce) {
      Swal.fire({
        title: "Subscription Required",
        text: "Subscribe to one of our plans and enjoy the ability to add images to your account.",
        imageUrl: subscribeimg,
        imageWidth: 250,
        imageHeight: 200,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonText: "Subscribe Now",
        cancelButtonText: "Maybe Later",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/plan-selection");
        }
      });
    } else if (planEndDate < currentDate) {
      Swal.fire({
        title: "Subscription Expired",
        text: "Your subscription has expired. Please renew your subscription to proceed.",
        icon: "warning",
        confirmButtonText: "Renew Now",
        cancelButtonText: "Maybe Later",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/plan-selection"); // Navigate to the plan selection page
        }
      });
    } else {
      if (totlaCount <= photosLimit) {
        handleValidation((validation) => {
          if (validation === false) {
            setActivetab("1");
            navigate("/photographer-list/add-photographer-event");
          } else if (validation === true) {
            const updatedImages = {
              ...formik?.values?.images,
              [selectedCategory]: [...(formik?.values?.images[selectedCategory] || []), ...previewImages],
            };
            formik.setFieldValue("images", updatedImages);
            formik.submitForm();
            setPreviewImages([]);
          }
        });
      } else {
        Swal.fire({
          title: "Images Limit Exceeded!!",
          text: `You've reached the limit of ${photosLimit} for your images. Upgrade your plan to add more images and make your business stand out!`,
          icon: "warning",
          confirmButtonText: "Upgrade Plan Now!",
          cancelButtonText: "Maybe Later",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/plan-selection");
            setActivetab("5");
          }
        });
      }
    }
  };
  const {
    mutate: deleteimage,
    isLoading: isimageDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-img-by-id-category"],
    mutationFn: (data) => deletePhotographerImages(data),
    onSuccess: (data) => {
      if (data.status) {
        queryClient.invalidateQueries(["get-photographer-event-by-id"]);
      }
    },
  });
  const deletImage = async (images, category) => {
    const imgdata = { images, category };
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedValue = { ...imgdata, file_type: "image", eventId: id };
          deleteimage(updatedValue);
          // eventsList.data = eventsList.data.filter((event) => event?.id !== value);
          Swal.fire({
            title: "Deleted!",
            text: "Your image has been deleted.",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    } catch (error) {
      console.error("Error deleting mixe:", error);
    }
  };

  const handleDragStart = (category, index) => {
    setDraggedImage({ category, index });
  };

  const handleDrop = (e, targetCategory) => {
    e.preventDefault();
    e.stopPropagation();

    if (draggedImage && draggedImage.category !== targetCategory) {
      const { category, index } = draggedImage;
      const imageToMove = formik.values.images[category][index];

      // Remove the image from the original category
      const updatedSourceImages = {
        ...formik.values.images,
        [category]: formik.values.images[category].filter((_, i) => i !== index),
      };
      if (updatedSourceImages[category].length === 0) {
        delete updatedSourceImages[category];
      }

      // Add the image to the target category
      const updatedTargetImages = {
        ...updatedSourceImages,
        [targetCategory]: [...(updatedSourceImages[targetCategory] || []), imageToMove],
      };

      formik.setFieldValue("images", updatedTargetImages);
      setDraggedImage(null);
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const {
    data: eventType,
    isLoading: eventtypeLoading,
    isError: eventTypeError,
  } = useQuery({
    queryKey: ["get-event-type"],
    queryFn: () => getPhotoGrapherType(),
    staleTime: Infinity,
    // staleTime: 50000
  });
  const eventTypeOption = transformData({
    dataArray: eventType?.data?.Category || [],
    label: "title",
    value: "id",
    isLoading: eventtypeLoading,
  });
  const [showDownloadSendOptions, setShowDownloadSendOptions] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null); 

  const fetchImagesAsBase64 = async (images) => {
    console.log("images", images);
    const promises = images.map(async (image) => {
      const response = await fetch(image.url);
      console.log("response", response);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });

    return Promise.all(promises); // Wait for all promises to resolve
  };
  const processImages = async (imagesObject) => {
    const base64ImagesByCategory = {};
    for (const [category, images] of Object.entries(imagesObject)) {
      const base64Images = await fetchImagesAsBase64(images); // Convert all images in the category
      base64ImagesByCategory[category] = base64Images; // Store the base64 results by category
    }
    return base64ImagesByCategory; // Returns an object with categories and base64 images
  };
  const generatePDFWithImages = async (images) => {
    setLoading(true);
    const base64ImagesByCategory = await processImages(images);
    const pdf = new jsPDF({
      userUnit: 300,
    });
    const pageHeight = pdf.internal.pageSize.height; // Get the page height
    for (const [category, base64Images] of Object.entries(base64ImagesByCategory)) {
      if (pdf.internal.getNumberOfPages() > 1) {
        pdf.addPage(); // Add a new page before starting a new category, except for the first one
      }
      pdf.setFontSize(14);
      pdf.text(category, 10, 10); // Add category name as a title
      let yPosition = 20; // Initial position for the first image on the page
      base64Images.forEach((base64Image, index) => {
        const imageHeight = 100; // Set the height of each image
        const spacing = 10; // Space between images
        const nextPosition = yPosition + imageHeight + spacing;
        // Check if the image exceeds the page height
        if (nextPosition > pageHeight) {
          pdf.addPage();
          yPosition = 20; // Reset position for the new page
          pdf.setFontSize(14);
          pdf.text(category, 10, 10); // Re-add category title on the new page
        }
        // Add image
        pdf.addImage(base64Image, "JPEG", 10, yPosition, 180, imageHeight);
        // Update position for the next image
        yPosition += imageHeight + spacing;
      });
    }
  
    // Set the PDF Blob in state
    const pdfBlob = pdf.output('blob');
    setPdfBlob(pdfBlob);  // Store the Blob data
    setLoading(false);
    setShowDownloadSendOptions(true);  // Show the options to download/send
  };

  const [selectedImages1, setSelectedImages1] = useState([]);
  const toggleSelectImage = (category, image) => {
    const isSelected = selectedImages1.some((selected) => selected.url === image.url && selected.category === category);

    if (isSelected) {
      setSelectedImages1(selectedImages1.filter((selected) => selected.url !== image.url));
    } else {
      setSelectedImages1([...selectedImages1, { ...image, category }]);
    }
  };
  const toggleSelectAllImages = (category, images) => {
    // Check if all images in the category are already selected
    const allSelected = images.every((image) =>
      selectedImages1.some(
        (selected) =>
          selected.url === image.url && selected.category === category
      )
    );
  
    if (allSelected) {
      // If all images are selected, deselect them
      setSelectedImages1(
        selectedImages1.filter((selected) => selected.category !== category)
      );
    } else {
      // Otherwise, select all images in the category
      const newSelections = images.map((image) => ({
        ...image,
        category,
      }));
      setSelectedImages1([
        ...selectedImages1.filter((selected) => selected.category !== category),
        ...newSelections,
      ]);
    }
  };
  const handleGeneratePDF = () => {
    setLoading(true);
    const groupedImages = selectedImages1.reduce((acc, image) => {
      acc[image.category] = acc[image.category] || [];
      acc[image.category].push(image);
      return acc;
    }, {});
    console.log("groupedImages", groupedImages);
    generatePDFWithImages(groupedImages);
    setSelectedImages1([]);
    setLoading(false);
  };
  const handleDownload = () => {
    if (pdfBlob) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = "Albums.pdf";
      link.click();
    }
    setShowDownloadSendOptions(false); // Hide options after download
  };
  
  const handleSendToBackend = async (id, pdfBlob) => {
    setLoading(true);
    console.log("Send to Backend triggered with id:", id, "and pdfBlob:", pdfBlob);
  
    if (!id || !pdfBlob) {
      console.error("Missing id or pdfBlob");
      setLoading(false);
      return;
    }
  
    // Construct the FormData payload
    const formData = new FormData();
    formData.append("id", id); // Add the id to the payload
    formData.append("album_pdf", pdfBlob, "Albums.pdf"); // Add the binary PDF data
  
    try {
      console.log("Preparing to send API request...");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/event-info/generate-event-album`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
          body: formData,
        }
      );
  
      if (response.ok) {
        const responseData = await response.json(); // Assuming the API returns a JSON response
        console.log("PDF successfully uploaded:", responseData);
  
        // Show success toast using Swal
        handleSuccess(1);
       
      } else {
        console.error("Failed to upload PDF:", response.status, response.statusText);
  
        // Show error toast using Swal
        handleSuccess(0);

       
      }
    } catch (error) {
      console.error("Error uploading PDF:", error);
  
      // Show error toast using Swal
      handleSuccess(0);

      
    }
    setLoading(false);
  };
  
  const handleSuccess = (data) => {
    
    Swal.fire({
      position:"center",
      // icon: "success",
      icon: data === 1 ? "success" : "error",
      title: data === 1 ? "Generated PDF sent successfully" : "Not generated PDF" ,
      showConfirmButton: false,
      timer: 3000,
    });
  };
  
  return (
    <Row className="p-2 py-0">
      <Col md={4} className="border p-3 bg-white">
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="categorySelect">Select {dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)} Type</Label>
              {/* <Label for="categorySelect">Select Category</Label> */}
              <Select
                id="categorySelect"
                name="categorySelect"
                // value={Allcategories?.find((option) => option?.value === selectedCategory)}
                value={eventTypeOption?.find((option) => option?.label === selectedCategory)}
                onChange={(selectedOption) => setSelectedCategory(selectedOption?.label)}
                // options={Allcategories}
                options={eventTypeOption}
                styles={skin === "dark" ? { ...darkModeStyles, ...styles } : customStyles}
              />
            </FormGroup>
            <UploadMultipleImages
              label="Upload Image"
              name="files"
              handleAddImage={handleAddImage}
              isCompanyProfileEditing={isCompanyProfileEditing}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
            <div className="row">
              {previewImages.map((image, index) => (
                <Col key={index} md={3} className="position-relative mb-3">
                  <img
                    src={image}
                    alt={`preview ${index}`}
                    className="img-fluid"
                    style={{
                      height: "100px", // Adjust height as needed
                      width: "auto", // Auto width to maintain aspect ratio
                      objectFit: "cover", // Or use 'contain' as per your design
                      border: "1px solid #EBECEC",
                    }}
                  />
                  <Button
                    color="danger"
                    size="xs"
                    className="position-absolute"
                    style={{ top: "0px", right: "14px" }}
                    onClick={() => handleRemovePreviewImage(index)}
                  >
                    X
                  </Button>
                </Col>
              ))}
            </div>
          </div>
        </Row>
        {/* {previewImages?.length > 0 && totlaCount <= photosLimit && ( */}
        {previewImages.length > 0 ? (
          <Button
            type="button"
            color="primary"
            onClick={handleSavePreviewImages}
            className="btn btn-success mt-2"
            // style={{marginLeft:'85%'}}
          >
            Upload
          </Button>
        ) : (
          ""
        )}
      </Col>

      <Col md={8} className="border p-3 bg-white border-start-0">
        {isCompanyProfileEditing ? (
          <div style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
            {categories.map(
              (category) =>
                formik.values.images[category] &&
                formik.values.images[category].length > 0 && (
                  <div
                    key={category}
                    onDrop={(e) => handleDrop(e, category)}
                    onDragOver={handleDragOver}
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                      margin: "10px 0",
                    }}
                  >
                    <h5>{category}</h5>
                    <Row>
                      {formik.values.images[category].map((image, index) => (
                        <Col
                          key={index}
                          md={2} // Adjusted to fit 6 columns per row
                          className="position-relative mb-1"
                        >
                          <div className="position-relative" style={{ width: "100px", height: "70px" }}>
                            <ShimmerThumbnail height={70} width={100} rounded />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                )
            )}
          </div>
        ) : (
          <div style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
  {categories.map(
    (category) =>
      formik.values.images[category] &&
      formik.values.images[category].length > 0 && (
        <div
          key={category}
          onDrop={(e) => handleDrop(e, category)}
          onDragOver={handleDragOver}
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            margin: "10px 0",
          }}
        >
          {/* Category Header with "Select All" */}
          <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
            <h5 style={{ flex: 1 }}>{category}</h5>
            <Input
              type="checkbox"
              onChange={() =>
                toggleSelectAllImages(category, formik.values.images[category])
              }
              checked={formik.values.images[category].every((image) =>
                selectedImages1.some(
                  (selected) =>
                    selected.url === image.url && selected.category === category
                )
              )}
            />
            {/* <label style={{ marginLeft: "5px" }}>Select All</label> */}
          </div>

          {/* Image Grid */}
          <Row>
            {formik.values.images[category].map((image, index) => (
              <Col
                key={index}
                xs={6}
                sm={6}
                md={2}
                className="position-relative mb-3 d-flex justify-content-center"
                draggable
                onDragStart={() => handleDragStart(category, index)}
              >
                <div
                  className="position-relative"
                  style={{
                    width: "100px",
                    height: "70px",
                  }}
                >
                  <img
                    src={image.url}
                    alt={`uploaded ${index}`}
                    className="img-fluid"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      border: "1px solid #EBECEC",
                    }}
                  />
                  <Input
                    type="checkbox"
                    className="position-absolute"
                    style={{ bottom: "5px", left: "5px" }}
                    onChange={() => toggleSelectImage(category, image)}
                    checked={selectedImages1.some(
                      (selected) =>
                        selected.url === image.url &&
                        selected.category === category
                    )}
                  />
                  <Button
                    color="danger"
                    disabled={isCompanyProfileEditing}
                    size="xs"
                    className="position-absolute"
                    style={{ top: "0px", right: "0px" }}
                    onClick={() => deletImage(image.url, category)}
                  >
                    X
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )
  )}
</div>

        )}
       {selectedImages1.length > 0 ? (
  <Button
    type="button"
    color="primary"
    onClick={handleGeneratePDF}
    className="btn btn-success mt-2"
    disabled={loading}
  >
    {loading ? (
      <>
        <Spinner size="sm" color="light" className="mr-2" /> {/* Spinner with margin */}
        Loading... {/* Loading text */}
      </>
    ) : (
      "Generate PDF"
    )}
  </Button>
) : (
  ""
)}
       {showDownloadSendOptions && (
  <div className="mt-2 d-flex gap-2">
    <Button
      type="button"
      color="primary"
      onClick={() => handleDownload()}
      className="btn btn-success "
      disabled={loading}
    >
      Download PDF
    </Button>
    <Button
      type="button"
      color="danger"
      onClick={() => handleSendToBackend(id, pdfBlob)}
      className="btn btn-danger"
      disabled={loading}
    >
       {loading ? (
      <>
        <Spinner size="sm" color="light" className="mr-2" /> {/* Spinner with margin */}
        Loading... {/* Loading text */}
      </>
    ) : (
      " Send to Email"
    )}
     
    </Button>
  </div>
)}


      </Col>
      <NavigationFooter
        toggle={toggle}
        currentState={currentState}
        lastPage="9"
        validationFunction={handleValidation}
        formik={formik}
        isCompanyProfileEditing={isCompanyProfileEditing}
        isimageDeleting={isimageDeleting}
        form={"updateprofile"}
      />
    </Row>
  );
};
export default PhotoGrapherGallary;
