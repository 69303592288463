import React, { useEffect, useState, useContext } from "react";
import Head from "../../layout/head/Head";
import ContentAlt from "../../layout/content/ContentAlt";
import ChatBody from "./ChatBody";
import { Button, Icon, UserAvatar } from "../../components/Component";
import { UncontrolledDropdown } from "reactstrap";
import { ChatContext } from "./ChatContext";
import { ChatAsideBody } from "./ChatAsideBody";
import Cookies from "js-cookie";
import { db } from "../../Firebase Files/firebaseConfig";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  limit,
} from "firebase/firestore";
import { postRequest, postRequestGetUserProfile } from "../../api-service";
import { updateUserVisibility } from "../../Firebase Files/firebase-service";
import homePageImg from "../../assets/images/Chat Graphics.png";
import { getCookie, getInitiatals } from "../../utils/Utils";

const Chat = () => {
  const [mainTab, setMainTab] = useState("Chats");
  const [selectedId, setSelectedId] = useState();
  const [selectedName, setSelectedName] = useState("");
  const [selectedUsername, setSelectedUsername] = useState("");
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [filterText, setFilterText] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [mobileView, setMobileView] = useState(false);
  const { chatState, fav } = useContext(ChatContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const authToken = Cookies.get("authToken");
  const [contact, setContact] = useState([]);
  const [FireBaseContact, setFirebaseContact] = useState([]);
  const [textMsg, setTextMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [sharedPhotos, setSharedPhotos] = useState([]);
  const [isRecentUsersFetched, setIsRecentUsersFetched] = useState(false);
  const [deletedUserIds, setDeletedUserIds] = useState(new Set());
  const [isUpdating, setIsUpdating] = useState(false);

  const [messages, setMessages] = useState([]);
  const [chat, setChat] = chatState;
  const [favData] = fav;

  const profilePic = Cookies.get("profile_image");
  const userIdLoggedin = parseInt(getCookie("userId"));
  const userNameLoggedin = getCookie("username");

  // Hello Model
  const [showHelloMessage, setShowHelloMessage] = useState(false);

  // to create a new chatRoom
  useEffect(() => {
    createNewChat(userIdLoggedin);
  }, [userIdLoggedin, userIdLoggedin]);

  const createNewChat = async (id) => {
    const q = query(
      collection(db, "Chats"),
      where("participants", "array-contains", userIdLoggedin)
    );

    const querySnapshot = await getDocs(q);
    let chatExists = false;

    querySnapshot.forEach((doc) => {
      const participants = doc.data().participants;
      if (participants.includes(id)) {
        chatExists = true;
        // selectUser({ chatId: doc.id, ...id });
      }
    });

    if (!chatExists) {
      try {
        if (id !== userIdLoggedin) {
          const newChat = await addDoc(collection(db, "Chats"), {
            participants: [userIdLoggedin, id],
            createdAt: new Date(),
            ChatRoomActive: false,
            ChatRequestSent: false,
          });
          selectUser({ chatId: newChat.id, ...id });
        }
      } catch (error) {
        console.error("Error creating new chat:", error);
      }
    }
  };

  const checkChatAvailable = async (id) => {
    const currentUserId = userIdLoggedin;
    if (!currentUserId) {
      console.error("No user logged in");
      return;
    }

    const q = query(
      collection(db, "Chats"),
      where("participants", "array-contains", currentUserId)
    );

    const querySnapshot = await getDocs(q);
    let chatExists = false;

    querySnapshot.forEach((doc) => {
      const participants = doc.data().participants;
      if (participants.includes(id)) {
        chatExists = true;
        selectUser({ chatId: doc.id, ...id });
      }
    });

    if (!chatExists) {
      if (id !== userIdLoggedin) {
        setShowHelloMessage(true);
      }
    }
  };

  const selectUser = (user) => {
    setSelectedUser(user);
    setUsers(
      users.map((u) =>
        u.id === user.id ? { ...u, selected: true } : { ...u, selected: false }
      )
    );
  };

  //useEffect to filter recent chats users based on different conditions
  useEffect(() => {
    getUsers();
  }, [textMsg, deletedUserIds]);

  const getUsers = async () => {
    const userCollectionRef = collection(db, "Users");
    const CurrentUsersDocId = `${userNameLoggedin}_${userIdLoggedin}`;
    const currentUserRef = doc(db, "Users", CurrentUsersDocId);
    const currentUserDoc = await getDoc(currentUserRef);
    const currentUsersChattingIds = currentUserDoc?.data()?.chattingIds;

    // Function to filter and sort users based on conditions
    const filterAndSortUsers = async (userList) => {
      const filteredUserPromises = userList?.map(async (user) => {
        // Keep users only if their ID exists in currentUsersChattingIds
        if (
          currentUsersChattingIds?.includes(user.id) &&
          user.id !== userIdLoggedin &&
          !deletedUserIds.has(user.id)
        ) {
          const q = query(
            collection(db, "Chats"),
            where("participants", "array-contains", userIdLoggedin)
          );
          const querySnapshot = await getDocs(q);

          const isChatParticipant = querySnapshot.docs.some((chatDoc) => {
            const participants = chatDoc.data().participants;
            return participants.includes(user.id);
          });

          // Return the user only if they are a chat participant
          return isChatParticipant ? user : null;
        }
        return null; // Exclude user if conditions are not met
      });

      // Resolve promises and filter out null values
      const filteredUserList = (await Promise.all(filteredUserPromises)).filter(
        Boolean
      );

      return filteredUserList;
    };

    // Real-time listener for Users collection
    const unsubscribeUsers = onSnapshot(
      userCollectionRef,
      async (snapshot) => {
        const userList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const sortedUserList = await filterAndSortUsers(userList);

        const debounceSetFilteredChatList = (newList) => {
          setTimeout(() => {
            setFilteredChatList(newList);
          }, 100);
        };

        debounceSetFilteredChatList(sortedUserList);
        setContact(sortedUserList);
        setFirebaseContact(sortedUserList);

        fetchUsers(currentUsersChattingIds, sortedUserList);
        setIsUpdating(false);
      },
      (error) => {
        console.error("Error fetching user data: ", error);
        setIsUpdating(false);
      }
    );

    // Real-time listener for incoming messages
    const unsubscribeMessages = onSnapshot(
      collection(db, "Messages"),
      async (snapshot) => {
        const incomingMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const usersWhoMessaged = incomingMessages
          .filter((msg) => msg.to === userIdLoggedin)
          .map((msg) => msg.from);

        const uniqueUserIds = [...new Set(usersWhoMessaged)];
        const newUsersPromises = uniqueUserIds.map(async (userId) => {
          const userDoc = await getDoc(doc(userCollectionRef, userId));
          return userDoc.exists() ? { id: userId, ...userDoc.data() } : null;
        });

        const newUsers = (await Promise.all(newUsersPromises)).filter(Boolean);

        // Combine with existing contacts and remove duplicates
        const existingContacts = await filterAndSortUsers(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );

        // Combine contacts while ensuring uniqueness
        const combinedContacts = [
          ...new Map(
            [...existingContacts, ...newUsers].map((user) => [user.id, user])
          ).values(),
        ];

        setContact(combinedContacts);
        setFirebaseContact(combinedContacts);
      },
      (error) => {
        console.error("Error fetching message data: ", error);
      }
    );

    // Cleanup listeners on unmount
    return () => {
      unsubscribeUsers();
      unsubscribeMessages();
    };
  };

  useEffect(() => {
    if (selectedUser?.chatId) {
      const messagesRef = collection(
        db,
        "Chats",
        selectedUser?.chatId,
        "messages"
      );
      const q = query(messagesRef, orderBy("timestamp", "desc"));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(updatedMessages);
      });

      return () => unsubscribe();
    }
  }, [selectedUser?.chatId, selectedId]);

  // Filtering users by search and removing duplicate users based on their IDs
  useEffect(() => {
    console.log("calling useEffect");
    const updatedFilteredList = async () => {
      if (filterText !== null) {
        const filteredObject = contact.filter((item) => {
          return (
            item?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
            item?.username?.toLowerCase().includes(filterText.toLowerCase())
          );
        });

        if (filteredObject.length === 0) {
          return;
        }

        const uniqueFilteredObject = filteredObject.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.id === item.id)
        );

        // console.log("uniqueFilteredObject:", uniqueFilteredObject);

        setSearchData(uniqueFilteredObject);
      } else {
        setSearchData(null);
        const contactchattingIds = contact.map(
          (item) => item?.id !== userIdLoggedin
        );

        fetchUsers(contactchattingIds);
      }
    };
    updatedFilteredList();
  }, [filterText, contact]);

  const fetchUsers = async (currentUsersChattingIds, userList) => {
    setLoading(true);
    try {
      const userChatMap = new Map();
      const chatsRef = collection(db, "Chats");

      const chatQueries = currentUsersChattingIds?.map((chatId) =>
        query(chatsRef, where("participants", "array-contains", userIdLoggedin))
      );

      chatQueries.forEach((chatQuery) => {
        onSnapshot(chatQuery, async (chatSnapshot) => {
          for (const chatDoc of chatSnapshot.docs) {
            const chatData = chatDoc.data();
            const messagesRef = collection(db, "Chats", chatDoc.id, "messages");

            onSnapshot(
              query(messagesRef, orderBy("timestamp", "desc"), limit(1)),
              async (messagesSnapshot) => {
                if (!messagesSnapshot.empty) {
                  const latestMessage = messagesSnapshot.docs[0].data();
                  const recipientId = chatData.participants.find(
                    (id) => id !== userIdLoggedin
                  );

                  if (
                    currentUsersChattingIds?.includes(recipientId) && // Respect the condition
                    !deletedUserIds.has(recipientId) &&
                    userList
                  ) {
                    const userDoc = userList.find(
                      (user) => user.id === recipientId
                    );

                    if (userDoc) {
                      setFilteredChatList((prevList) => {
                        const updatedList = prevList.filter(
                          (user) => user.id !== recipientId
                        );
                        return [userDoc, ...updatedList];
                      });
                    }
                  }
                }
                setIsRecentUsersFetched(true);
              }
            );
          }
        });
      });
      setContact(FireBaseContact);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setIsRecentUsersFetched(true);
      }, 10000);
      setLoading(false);
    }
  };

  //fetching recentaly chat users from firebase
  useEffect(() => {
    if (contact) {
      const contactchattingIds = contact.map((item) => item?.id);
      fetchUsers(contactchattingIds);
    }
  }, []);

  const onInputChange = (e) => {
    const value = e.target.value;
    !value ? setFilterText(null) : setFilterText(e.target.value);
  };

  const formData = new FormData();

  const getIpdmUsers = async () => {
    formData.append("limit", 10);
    formData.append("search", filterText);
    setLoading(true);
    try {
      const res = await postRequest("api/v1/user/users-list", formData);

      if (res && res?.data?.users.length !== 0) {
        setContact(res?.data?.users);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getIpdmUsers();
  }, [filterText]);

  const chatItemClick = async (id, name, item) => {
    console.log("item:", item);
    checkChatAvailable(id);
    setSelectedUsername(name);
    updateUserVisibility(`${name}_${id.toString()}`, true);

    const userRef = doc(
      db,
      "Users",
      `${item?.username || item?.name}_${item?.id}`
    );

    try {
      // Get the document from Firestore
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        // If the user exists, set the current user data
        setCurrentUser(currentUser);

        // Check if the logged-in user's ID is in chattingIds
        if (!userDoc.data().chattingIds.includes(userIdLoggedin)) {
          // Add the logged-in user's ID to chattingIds if it's not already there
          await updateDoc(userRef, {
            chattingIds: arrayUnion(userIdLoggedin),
          });
        }
      } else {
        // If the user doesn't exist, create a new user in Firestore
        const newUser = {
          id: item?.id,
          name: item?.username || item?.name || "Unknown User",
          createdAt: new Date().toISOString(),
          profilePic: item?.thumbnail || item?.profile_image_path || "",
          messages: false,
          chattingIds: [userIdLoggedin],
          blockedIds: [],
        };

        // Create the new user document in Firestore
        await setDoc(userRef, newUser);
      }
    } catch (error) {
      console.error("Error fetching or creating user:", error);
    }

    // Handle chat UI updates
    createNewChat(id);

    let data = contact;
    const index = data.findIndex((item) => item.id === id);
    const dataSet = data.find((item) => item.id === id);

    if (dataSet?.unread === true) {
      data[index].unread = false;
      setChat([...data]);
    }
    // Set the selected user details
    setSelectedId(id);
    setSelectedName(name);

    // Handle mobile view toggle
    if (window.innerWidth < 860) {
      setMobileView(true);
    }
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (authToken) {
          const res = await postRequestGetUserProfile(
            "api/v1/account/get-profile"
          );
          setProfile(res?.data?.profile); // Set profile state
        } else {
          console.log("No token found");
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchUserProfile();
  }, [authToken]);

  console.log("profile:", profile);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      if (!profile || !profile?.user_id) {
        return;
      }

      const userRef = doc(
        db,
        "Users",
        `${profile?.username}_${profile?.user_id}`.toString()
      );
      try {
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          setCurrentUser(userDoc.data());
          await updateDoc(userRef, {
            active: true,
            visible: true,
          });
        } else {
          // If the user doesn't exist, create the user in Firestore
          const newUser = {
            id: profile?.user_id,
            name: profile?.username || "Unknown User",
            createdAt: new Date().toISOString(),
            profilePic: profile?.thumbnail || profile?.profile_image_path || "",
            active: true,
            chattingIds: [profile?.user_id],
            blockedIds: [],
          };
          await setDoc(userRef, newUser);
          setCurrentUser(newUser);
        }
      } catch (error) {
        console.error("Error fetching or creating user:", error);
      }
    };

    fetchCurrentUser();
  }, [profile]);

  useEffect(() => {
    if (currentUser) {
      // If the current user is fetched or created, add them to the chat users list
      setUsers((prevUsers) => {
        if (!prevUsers.some((user) => user.id === userIdLoggedin)) {
          return [...prevUsers, currentUser];
        }
        return prevUsers;
      });
    }
  }, [currentUser]);

  const handleSayHello = () => {
    setTextMsg("Hello 👋");
    setShowHelloMessage(false);
  };

  return (
    <React.Fragment>
      <Head title={"I Party DJ MIX"}></Head>
      <ContentAlt>
        <div className="nk-chat">
          <div className={`nk-chat-aside ${mobileView ? "has-aside" : ""}`}>
            <div className="nk-chat-aside-head">
              <div
                className="nk-chat-aside-user"
                style={{ width: "45px", height: "45px" }}
              >
                <UncontrolledDropdown>
                  {profilePic ? (
                    <img
                      src={profilePic}
                      alt="Profile"
                      className="user-avatar"
                      style={{
                        width: "45px",
                        height: "100%",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <UserAvatar
                      text={getInitiatals(
                        currentUser?.name || profile?.username || "User"
                      )}
                      theme="primary"
                      style={{
                        width: "45px",
                        height: "45px",
                        borderRadius: "50%",
                      }}
                    ></UserAvatar>
                  )}
                  <div className="title">{mainTab}</div>
                </UncontrolledDropdown>
              </div>
            </div>
            <ChatAsideBody
              onInputChange={onInputChange}
              filteredChatList={filteredChatList}
              selectedUser={selectedUser}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              chatItemClick={chatItemClick}
              currentUser={currentUser}
              messages={messages}
              filterText={filterText}
              loading={loading}
              searchData={searchData}
              setLoading={setLoading}
              setFilteredChatList={setFilteredChatList}
              isRecentUsersFetched={isRecentUsersFetched}
              setDeletedUserIds={setDeletedUserIds}
              isUpdating={isUpdating}
              setMessages={setMessages}
            />
          </div>
          {selectedId === undefined && (
            <div className="nk-chat-body">
              <div className="nk-chat-blank">
                <div className="nk-chat-blank-btn">
                  <img src={homePageImg} />
                </div>
                <p>Select contact to chat!!</p>
              </div>
            </div>
          )}

          {selectedId !== null ? (
            <ChatBody
              id={selectedId}
              name={selectedName}
              setSelectedId={setSelectedId}
              setMobileView={setMobileView}
              mobileView={mobileView}
              username={selectedUsername}
              currentUser={currentUser}
              selectedUser={selectedUser}
              createNewChat={createNewChat}
              setTextMsg={setTextMsg}
              textMsg={textMsg}
              user={selectedUser}
              setCurrentUser={setCurrentUser}
              chatItemClick={chatItemClick}
              sharedPhotos={sharedPhotos}
              setLoading={setLoading}
              setFilteredChatList={setFilteredChatList}
              setFilterText={setFilterText}
              showHelloMessage={showHelloMessage}
              handleSayHello={handleSayHello}
              setShowHelloMessage={setShowHelloMessage}
            />
          ) : (
            <>
              <div className="nk-chat-body">
                <div className="nk-chat-blank">
                  <div className="nk-chat-blank-btn">
                    <img src={homePageImg} />
                  </div>
                  <p>Select contact to chat!!</p>
                </div>
              </div>
            </>
          )}
        </div>
      </ContentAlt>
    </React.Fragment>
  );
};

export default Chat;
