import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Button, Tooltip } from "reactstrap";
import Swal from "sweetalert2";
import NavigationFooter from "../../components/NavigationFooter";
import { DataContext } from "../../../context/DataProvider";
import { useMutation, useQueryClient } from "react-query";
import { deleteAlbums } from "../../../http/delete/deleteApi";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useLocation, useNavigate } from "react-router";
import UploadMultipleAlbum from "./UploadMultipleAlbum";
import profileimg2 from '../../../images/pdf_icon.svg'
const SubAlbumTab = ({
  toggle,
  currentState,
  formik,
  isCompanyProfileEditing,
  videosLimit,
  videoCount,
  mixersLimit,
  mixesCount,
  subscribedpalresponce,
  planEndDate,
}) => {
  const [newMixes, setNewMixes] = useState([]);

  const { hasUnsavedChanges, setHasUnsavedChanges, setActivetab } = useContext(DataContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const handleAddMixes = (base64Strings) => {
    console.log("base", base64Strings);
    handleValidation((validation) => {
      if (validation === false) {
        setActivetab("1");
        navigate("/photographer-list/add-photographer-event");
      } else if (validation === true) {
        const updatedMixes = [...(formik.values.albumns || []), ...base64Strings];
        formik.setFieldValue("albumns", updatedMixes);
        formik.submitForm();
        setNewMixes([]);
      }
    });
  };
  const handleValidation = (callbackFun) => {
    // callbackFun(true);
    if (formik.values.first_name === "") {
      formik.setFieldTouched("first_name", true);
    }

    if (formik.values.last_name === "") {
      formik.setFieldTouched("last_name", true);
    }

    if (formik.values.phone_number === "") {
      formik.setFieldTouched("phone_number", true);
    }

    if (formik.values.city === "") {
      formik.setFieldTouched("city", true);
    }
    if (formik.values.country === "") {
      formik.setFieldTouched("country", true);
    }
    if (formik.values.state === "") {
      formik.setFieldTouched("state", true);
    }

    if (
      formik.values.first_name !== "" &&
      formik.values.last_name !== "" &&
      formik.values.phone_number !== "" &&
      formik.values.country !== "" &&
      formik.values.state !== "" &&
      formik.values.city !== ""
    ) {
      callbackFun(true);
    } else {
      callbackFun(false);
    }
  };

  // useMutation to delete album
  const {
    mutate: deletealbums,
    isLoading: isMixesDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-album-by-url"],
    mutationFn: (data) => deleteAlbums(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["get-photographer-event-by-id"]);
    },
  });
  const deleteMixe = async (mixe) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info",
        },
      }).then((result) => {
        if (result.isConfirmed){
          // Determine the file type based on the file extension or your file handling logic
          const file_name = mixe.albumPath || mixe.name;
          // Adjust to pass the correct name if necessary
          const updatedValue = { file_name, file_type: "album", id: id };
          deletealbums(updatedValue);
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (index) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  return (
    <Row className="p-2 py-0">
      <Col md={4} className="border p-3 bg-white border-start-0">
        <Row>
          <Col md={12}>
            <UploadMultipleAlbum
              label="Upload PDF"
              name="files"
              handleAddMixes={handleAddMixes}
              formik={formik}
              isCompanyProfileEditing={isCompanyProfileEditing}
              mixersLimit={mixersLimit}
              mixesCount={mixesCount}
              videoCount={videoCount}
              setHasUnsavedChanges={setHasUnsavedChanges}
              subscribedpalresponce={subscribedpalresponce}
              planEndDate={planEndDate}
            />
          </Col>
        </Row>
      </Col>
      <Col md={8} className="border p-3 bg-white border-end-0">
        {isCompanyProfileEditing ? (
          <div style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
            <Row className="mt-4">
              {formik.values.albumns?.map((image, index) => (
                <Col key={index} md={4} lg={4} className="position-relative mb-3">
                  <ShimmerThumbnail height={80} width="100%" rounded />
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <div style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
         
   <Row className="mt-4">
  {formik.values.albumns?.map((file, index) => (
    <Col key={index} md={4} lg={4} className="position-relative mb-3">
      {/* Check if the file is a PDF */}
      {file?.type === "application/pdf" ? (
        <div style={{ position: "relative", height: "140px" }}>
          {/* Show default image for PDFs that cannot be previewed */}
          <img
            src={profileimg2} // Default image if preview fails
            alt="PDF Thumbnail"
            className="position-absolute"
            style={{
              width: "100%",
              height: "140px",
              objectFit: "cover",
            }}
          />
          <object
            data={file?.temporaryUrl}
            type="application/pdf"
            width="100%"
            height="140px"
            style={{ border: "1px solid #EBECEC", position: "absolute", top: "0", left: "0" }}
          >
            <p>No preview available. <a href={file?.temporaryUrl} target="_blank" rel="noopener noreferrer">Download PDF</a></p>
          </object>
        </div>
      ) : (
        // Fallback for non-PDF file types
        <div
          style={{
            height: "140px",
            width: "230px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #EBECEC",
            backgroundColor: "#f9f9f9",
          }}
        >
          <img
            src={profileimg2} // Default image if not PDF
            alt="File Thumbnail"
            className="position-absolute"
            style={{
              width: "100%",
              height: "140px",
              objectFit: "contain",
              
            }}
          />
        </div>
      )}

      {/* Display the file name */}
      <p
        id={`fileNameTooltip-${index}`}
        className="text-break"
        style={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
        }}
      >
        {file?.albumPath?.split('/').pop() || "unknown"}
      </p>

      {/* Tooltip for full file name */}
      <Tooltip
        placement="top"
        isOpen={tooltipOpen[index] || false}
        target={`fileNameTooltip-${index}`}
        toggle={() => toggleTooltip(index)}
      >
        {file?.albumPath?.split('/').pop() || "unknown"}
      </Tooltip>

      {/* Delete button */}
      <Button
        color="danger"
        disabled={isCompanyProfileEditing}
        size="xs"
        className="position-absolute"
        style={{
          top: "0px",
          right: "25px",
          padding: "2px 5px",
          fontSize: "0.75rem",
          lineHeight: "1",
          height: "auto",
          width: "auto",
        }}
        onClick={() => deleteMixe(file)} // Update the delete function to delete a file
      >
        X
      </Button>
    </Col>
  ))}
</Row>


          </div>
        )}
      </Col>
    </Row>
  );
};

export default SubAlbumTab;
