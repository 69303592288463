import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Button, Tooltip } from "reactstrap";
import UploadMultipleVideos from "../../Profile/UploadMultipleVideo";
import Swal from "sweetalert2";
import { DataContext } from "../../../context/DataProvider";
import { useMutation, useQueryClient } from "react-query";
import { deleteMixes, deleteVideos, deleteVideosPhotographer } from "../../../http/delete/deleteApi";
import { getCompanyProfile } from "../../../http/get/getApi";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useLocation, useNavigate } from "react-router";

const SubVideoTabEngineer = ({ formik, isCompanyProfileEditing, videosLimit, videoCount, subscribedpalresponce }) => {
  const [newVideos, setNewVideos] = useState([]);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const { hasUnsavedChanges, setHasUnsavedChanges, setActivetab } = useContext(DataContext);

  const handleAddVideo = (base64Strings) => {
    handleValidation((validation) => {
      if (validation === false) {
        setActivetab("1");
        navigate("/photographer-list/add-photographer-event");
      } else if (validation === true) {
        const updatedVideos = [...(formik.values.videos || []), ...base64Strings];
        formik.setFieldValue("videos", updatedVideos);
        formik.submitForm();
        setNewVideos([]);
      }
    });
  };

  const handleValidation = (callbackFun) => {
    // callbackFun(true);
    if (formik.values.first_name === "") {
      formik.setFieldTouched("first_name", true);
    }

    if (formik.values.last_name === "") {
      formik.setFieldTouched("last_name", true);
    }

    if (formik.values.phone_number === "") {
      formik.setFieldTouched("phone_number", true);
    }

    if (formik.values.city === "") {
      formik.setFieldTouched("city", true);
    }
    if (formik.values.country === "") {
      formik.setFieldTouched("country", true);
    }
    if (formik.values.state === "") {
      formik.setFieldTouched("state", true);
    }

    if (
      formik.values.first_name !== "" &&
      formik.values.last_name !== "" &&
      formik.values.phone_number !== "" &&
      formik.values.country !== "" &&
      formik.values.state !== "" &&
      formik.values.city !== ""
    ) {
      callbackFun(true);
    } else {
      callbackFun(false);
    }
  };
  const {
    mutate: deleteVideo,
    isLoading: isVideoDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-video-by-id"],
    mutationFn: (data) => deleteVideosPhotographer(data),
    onSuccess: () => {
      queryClient.invalidateQueries("get-photographer-event-by-id");
    },
  });
  const deletvideo = async (value) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info",
        },
      }).then((result) => {
        if (result.isConfirmed) {
            const file_name = value.videoPath;
          const updatedValue = { ...value,file_name, file_type: 'video',id:id };
          deleteVideo(updatedValue);
          // eventsList.data = eventsList.data.filter((event) => event?.id !== value);
          Swal.fire({
            title: "Deleted!",
            text: "Your video has been deleted.",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    } catch (error) {
      console.error("Error deleting mixe:", error);
    }
  };

  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (index) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <Row className="p-2 py-0">
      <Col md={4} className="border p-3 bg-white border-start-0">
        <Row>
          <Col md={12}>
            <UploadMultipleVideos
              label="Upload Video"
              name="files"
              handleAddVideo={handleAddVideo}
              formik={formik}
              isCompanyProfileEditing={isCompanyProfileEditing}
              videosLimit={videosLimit}
              videoCount={videoCount}
              setHasUnsavedChanges={setHasUnsavedChanges}
              subscribedpalresponce={subscribedpalresponce}
            />
          </Col>
        </Row>
      </Col>
      <Col md={8} className="border p-3 bg-white border-end-0">
        {isCompanyProfileEditing ? (
          <div style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
            <Row className="mt-4">
              {formik.values.videos?.map((video, index) => (
                <Col key={index} md={4} className="position-relative mb-3">
                  <ShimmerThumbnail height={150} width="100%" rounded />
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <div style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
            <Row className="mt-4">
              {formik.values.videos?.map((video, index) => (
                <Col key={index} md={4} className="position-relative mb-3">
                  <video
                    src={video.temporaryUrl}
                    alt={`uploaded ${index}`}
                    className="img-fluid"
                    controls
                    style={{ height: "150px", width: "100%", objectFit: "cover", border: "1px solid black" }}
                  />
                  <p
                    id={`videoNameTooltip-${index}`}
                    className="text-break"
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                    }}
                  >
                    {video?.name}
                  </p>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen[index] || false} // Open state for the specific tooltip
                    target={`videoNameTooltip-${index}`} // Unique target ID
                    toggle={() => toggleTooltip(index)}
                  >
                    {video?.name}
                  </Tooltip>
                  <Button
                    color="danger"
                    disabled={isCompanyProfileEditing}
                    size="xs"
                    className="position-absolute"
                    style={{ top: "0px", right: "15px" }}
                    onClick={() => deletvideo(video)}
                  >
                    X
                  </Button>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default SubVideoTabEngineer;
