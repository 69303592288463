import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Card, CardBody, CardTitle, Modal, ModalBody, Spinner, ModalHeader, Row, Col } from "reactstrap";
import { Icon, ReactDataTable } from "../../../components/Component";
import Swal from "sweetalert2";
import TableActionBar from "../../components/TableActionBar";
import ReactPlayer from "react-player";
import { deleteyoutublink } from "../../../http/delete/deleteApi";
import { useMutation, useQueryClient } from "react-query";
import { DataContext } from "../../../context/DataProvider";
import { Link, useNavigate } from "react-router-dom";
import subscribeimg from "../../../assets/images/subscribe.jpg";

// Common initial part of YouTube link (adjust as needed)
const commonYouTubeLinkPart = "https://www.youtube.com/watch?v=";

function UploadYoutubeLinkEng({
  formik,
  isCompanyProfileEditing,
  youtubLinkLimit,
  youtubelinkCount,
  subscribedpalresponce,
}) {
  const [localLinks, setLocalLinks] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState("");

  const { hasUnsavedChanges, setHasUnsavedChanges, planEnddates, activeTab, setActivetab } = useContext(DataContext);

  const queryClient = useQueryClient();

  const [formikdata, setFormikdata] = useState(formik);
  const navigate = useNavigate();
  // console.log("formikdata",formikdata)

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const openVideoModal = (videoId) => {
    setCurrentVideoId(videoId);
    toggleModal();
  };

  const handleAddYoutubeLink = () => {
    const currentDate = new Date(); // Get the current date
    const planEndDate = new Date(planEnddates); // Convert planEnddates to a Date object

    if (!subscribedpalresponce) {
      Swal.fire({
        title: "Subscription Required",
        text: "Subscribe to one of our plans and enjoy the ability to add more youtubeLinks to your account.",
        imageUrl: subscribeimg,
        imageWidth: 250,
        imageHeight: 200,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonText: "Subscribe Now",
        cancelButtonText: "Maybe Later",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/plan-selection");
        }
      });
    } else if (planEndDate < currentDate) {
      Swal.fire({
        title: "Subscription Expired",
        text: "Your subscription has expired. Please renew your subscription to proceed.",
        icon: "warning",
        confirmButtonText: "Renew Now",
        cancelButtonText: "Maybe Later",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/plan-selection"); // Navigate to the plan selection page
        }
      });
    } else {
      if (youtubLinkLimit > youtubelinkCount) {
        handleValidation((validation) => {
          if (validation === false) {
            setActivetab("1");
            navigate("/photographer-list/add-photographer-event");
          } else if (validation === true) {
            if (formik.values.title && formik.values.link) {
              const newlinks = {
                index: formik.values.youtube_links.length + 1,
                title: formik.values?.title,
                link: formik.values?.link,
              };

              // Ensure vendors is an array
              const youTubLinksArray = Array.isArray(formik.values?.youtube_links) ? formik.values.youtube_links : [];

              formik.setValues({
                ...formik.values,
                youtube_links: [...youTubLinksArray, newlinks],
                title: "",
                link: "",
              });
              formik.submitForm();
            }
          }
        });
      } else {
        Swal.fire({
          title: "YoutubeLink Limit Exceeded!",
          text: `You've reached the limit of ${youtubLinkLimit} for your youtubelink. Upgrade your plan to add more youtubelinks and make your business stand out!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Upgrade Plan Now!",
          cancelButtonText: "Maybe Later",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/plan-selection");
            setActivetab("6");
          }
        });
      }
    }
  };

  const handleValidation = (callbackFun) => {
    // callbackFun(true);
    if (formik.values.first_name === "") {
      formik.setFieldTouched("first_name", true);
    }

    if (formik.values.last_name === "") {
      formik.setFieldTouched("last_name", true);
    }

    if (formik.values.phone_number === "") {
      formik.setFieldTouched("phone_number", true);
    }

    if (formik.values.city === "") {
      formik.setFieldTouched("city", true);
    }
    if (formik.values.country === "") {
      formik.setFieldTouched("country", true);
    }
    if (formik.values.state === "") {
      formik.setFieldTouched("state", true);
    }

    if (
      formik.values.first_name !== "" &&
      formik.values.last_name !== "" &&
      formik.values.phone_number !== "" &&
      formik.values.country !== "" &&
      formik.values.state !== "" &&
      formik.values.city !== ""
    ) {
      callbackFun(true);
    } else {
      callbackFun(false);
    }
  };

  // const handleRemoveYoutubeLink = (index) => {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'Do you want to remove this YouTube link?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Delete',
  //     cancelButtonText: 'Cancel',
  //     preConfirm: () => {
  //       return new Promise((resolve) => {
  //         setTimeout(resolve, 500); // Adding a delay before resolving
  //       });
  //     }
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       // Create a copy of the youtube_links array and remove the element at the specified index
  //       const updatedLinks = [...formik.values.youtube_links];
  //       updatedLinks.splice(index, 1);

  //       formik.setFieldValue('youtube_links', updatedLinks).then(() => {
  //         // Ensure form submission happens after state update
  //         setTimeout(() => {
  //           formik.submitForm();
  //           Swal.fire({
  //             title: 'Deleted!',
  //             text: 'The YouTube link has been removed.',
  //             icon: 'success',
  //             timer: 1500,
  //             showConfirmButton: false,
  //           });
  //         }, 2000); // Delay form submission to ensure state update
  //       });
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       Swal.fire({
  //         title: 'Cancelled',
  //         text: 'The YouTube link was not removed.',
  //         icon: 'info',
  //         timer: 1500,
  //         showConfirmButton: false,
  //       });
  //     }
  //   });
  // };

  const {
    mutate: deleteYoutublink,
    isLoading: islinkDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-youtub_link-by-id"],
    mutationFn: (data) => deleteyoutublink(data),
    onSuccess: (data) => {
      if (data.status) {
        queryClient.invalidateQueries("get-company-profile");
      }
    },
  });
  const handleRemoveYoutubeLink = async (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteYoutublink(id);
          // eventsList.data = eventsList.data.filter((event) => event?.id !== value);
          Swal.fire({
            title: "Deleted!",
            text: "Your youtube_link has been deleted.",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    } catch (error) {
      console.error("Error deleting youtube_link:", error);
    }
  };

  const handleYoutubLink = (e) => {
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const tableHeadings = [
    {
      name: "Sr. No.",
      // selector: (row, index) => index + 1,
      selector: (row) => row.id,
      style: {
        paddingLeft: "30px",
        paddingRight: "10px",
      },
    },
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "YouTube Link ID",
      selector: (row) => row.link,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <TableActionBar
            options1Icon="youtube-line"
            options1TooleTipText="View"
            options1onClick={() => openVideoModal(row.link)}
            options2Icon="ni ni-trash"
            options2TooleTipText="Delete"
            options2onClick={() => handleRemoveYoutubeLink(row.id)}
          />
        </>
      ),
    },
  ];

  return (
    <div className="tab-content">
      {/* <div> */}
        <Card>
          <CardBody>
            <div style={{ textAlign: "center" }}>{islinkDeleting ? <Spinner color="dark" /> : ""}</div>

            <CardTitle style={{ fontWeight: "bold" }}>Add YouTube Link</CardTitle>
            {/* <div className="form-control-wrap d-flex align-items-center" style={{ width: "60%", marginLeft: "18%" }}> */}
            <Row className="g-2 p-0">
              <Col sm="12" md="3" >
              <Input
                type="text"
                name="title"
                placeholder="Enter YouTube link title"
                value={formik.values.title || ""}
                // onChange={formik.handleChange}
                onChange={handleYoutubLink}
                style={{ marginRight: "20px" }}
              />
              </Col>
              <Col sm="12" md="3">
              <span className="input-group-text text-truncate justify-content-sm-start" id="basic-addon3">
                {commonYouTubeLinkPart}
              </span>
              </Col>
              <Col md="4">
              <Input
                type="text"
                name="link"
                maxLength={11}
                placeholder="Enter YouTube video ID (e.g., -wtIMTCHWuI)"
                value={formik.values.link || ""}
                // onChange={formik.handleChange}
                onChange={handleYoutubLink}
                style={{ marginRight: "20px" }}
              />
              </Col>
              <Col sm="12" md="2">
              <Button
                // disabled={!(youtubLinkLimit > youtubelinkCount)}
                color="primary"
                onClick={handleAddYoutubeLink}
                className="ml-5"
              >
                <Icon name="plus-sm" style={{ fontSize: "20px" }} />
              </Button>
              </Col>
            {/* </div> */}
            </Row>
            <div className="text-center mt-3">
              {/* {!(youtubLinkLimit > youtubelinkCount)  ? (
          !subscribedpalresponce ? (
            <span style={{ color: "red" }}>
              <Link to="/plan-selection" style={{ color: "red",  cursor: 'pointer',}}>
                Please subscribe plan!
              </Link>
            </span>
          ) : (
            <span style={{ color: "red" }}>You have reached your youtublinks limit. Please upgrade your plan !</span>
          )
        ) : (
          ""
        )} */}
            </div>
          </CardBody>
        </Card>
      {/* </div> */}
      <div className="pt-2 pb-0">
        <h5>Added YouTube Links</h5>
        <section className="mt-4 border p-3 bg-white">
          <ReactDataTable
            data={formik.values.youtube_links}
            columns={tableHeadings}
            pagination
            actions
            searchBy="title"
            pageNo={setPageNo}
            recordLimit={setRecordLimit}
            setSearch={setSearch}
          />
        </section>
      </div>
      {/* <Modal isOpen={modalOpen} toggle={toggleModal}  size="lg" style={{objectFit:'cover'}}>
          <ModalBody  fullscreen='lg' style={{padding:0 }}>
            <div className="embed-responsive embed-responsive-16by9" style={{objectFit:'cover'}}  >
              <iframe 
              className="embed-responsive-item"
              src={`https://www.youtube.com/embed/${currentVideoId}`}
              allowFullScreen
              title="YouTube video player"
              style={{ width: '100%', height: '20em', objectFit:'cover' }}
              />
            </div>
          </ModalBody>
        </Modal> */}

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader
          toggle={toggleModal}
          close={
            <button className="close" onClick={toggleModal}>
              <Icon name="cross" />
            </button>
          }
        >
          {/* Video Preview */}
        </ModalHeader>
        <ModalBody className="p-0 h-350px">
          <ReactPlayer
            controls
            url={`https://www.youtube.com/embed/${currentVideoId}`}
            className="react-player"
            width="100%"
            height="100%"
          />
        </ModalBody>
      </Modal>
    </div>
  );
}

export default UploadYoutubeLinkEng;
