import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import exportFromJSON from "export-from-json";
import CopyToClipboard from "react-copy-to-clipboard";
import { Col, Modal, ModalBody, Row, Button, Spinner } from "reactstrap";
import { DataTablePagination, TooltipComponent } from "../Component";
import { ShimmerTable } from "react-shimmer-effects";

const Export = ({ data1 }) => {
  
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (modal === true) {
      setTimeout(() => setModal(false), 2000);
    }
  }, [modal]);

  // const fileName = "user-data";

  // const exportCSV = () => {
  //   const exportType = exportFromJSON.types.csv;
  //   exportFromJSON({ data, fileName, exportType });
  // };

  const exportExcel = (data1) => {
    
    const exportType = exportFromJSON.types.xls;
    const fileName = "user-data";
    exportFromJSON({ data: data1, fileName, exportType });
  };

  const copyToClipboard = () => {
    setModal(true);
  };

  return (
    <React.Fragment>
      <div className="dt-export-buttons d-flex align-center">
        <div className="dt-export-title d-none d-md-inline-block">Export</div>
        <TooltipComponent id={"exportData"} text="Export in Excel Format" direction="top" />
        <div className="dt-buttons btn-group flex-wrap" id="exportData">
          {/* <CopyToClipboard text={JSON.stringify(data)}>
            <Button className="buttons-copy buttons-html5" onClick={() => copyToClipboard()}>
              <span>Copy</span>
            </Button>
          </CopyToClipboard>{" "} */}
          {/* <button className="btn btn-secondary buttons-csv buttons-html5" type="button" onClick={() => exportCSV()}>
            <span>CSV</span>
          </button>{" "} */}
          <button
            className="btn btn-primary buttons-excel buttons-html5"
            type="button"
            onClick={() => exportExcel(data1)}
          >
            <span>Excel</span>
          </button>{" "}
        </div>
      </div>
      {/* <Modal isOpen={modal} className="modal-dialog-centered text-center" size="sm">
        <ModalBody className="text-center m-2">
          <h5>Copied to clipboard</h5>
        </ModalBody>
        <div className="p-3 bg-light">
          <div className="text-center">Copied {data.length} rows to clipboard</div>
        </div>
      </Modal> */}
    </React.Fragment>
  );
};

const ExpandableRowComponent = ({ data }) => {
  return (
    <ul className="dtr-details p-2 border-bottom ms-1">
      <li className="d-block d-sm-none">
        <span className="dtr-title">Company</span> <span className="dtr-data">{data.company}</span>
      </li>
      <li className="d-block d-sm-none">
        <span className="dtr-title ">Gender</span> <span className="dtr-data">{data.gender}</span>
      </li>
      <li>
        <span className="dtr-title">Start Date</span> <span className="dtr-data">{data.startDate}</span>
      </li>
      <li>
        <span className="dtr-title">Salary</span> <span className="dtr-data">{data.salary}</span>
      </li>
    </ul>
  );
};

const CustomCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="custom-control custom-control-sm custom-checkbox notext">
    <input id={rest.name} type="checkbox" className="custom-control-input" ref={ref} onClick={onClick} {...rest} />
    <label className="custom-control-label" htmlFor={rest.name} />
  </div>
));

const ReactDataTable = ({
  data,
  datacount,
  columns,
  pagination,
  actions,
  className,
  selectableRows,
  expandableRows,
  searchBy,
  loading,
  activePage,
  totalRecords,
  pageNo,
  recordLimit,
  setSearch,
  normalSearch,
}) => {
  const [tableData, setTableData] = useState(data);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPageS, setRowsPerPage] = useState(10);
  const [mobileView, setMobileView] = useState();

  const removeNestedObjects = (obj) => {
    if (obj && typeof obj === "object") {
      Object.keys(obj).forEach((key) => {
        if (obj[key] && typeof obj[key] === "object") {
          delete obj[key]; // Remove nested object
        }
      });
      Object.keys(obj).forEach((key) => {
        if (obj[key] && typeof obj[key] === "object") {
          removeNestedObjects(obj[key]); // Recursively process nested objects
        }
      });
    }
    return obj;
  };

  const filteredData = data?.map((item, index) => {
    let filteredItem = {};

    // Iterate through columns to process each item
    columns.forEach((column) => {
      // Handle function selectors
      if (typeof column.selector === "function") {
        filteredItem[column.name] = column.selector(item, index);
      }
      // Handle cell functions
      else if (column.cell) {
        filteredItem[column.name] = column.cell(item);
      }
    });

    // Optionally remove 'Actions' from each filtered item
    // delete filteredItem['Actions'];
    // delete filteredItem['Message'];
    removeNestedObjects(filteredItem);

    return filteredItem;
  });

  useEffect(() => {
    let defaultData = data; // Update defaultData directly from data prop
    if (searchText !== "") {
      defaultData = data.filter((item) => {
        return item[searchBy].toLowerCase().includes(searchText.toLowerCase());
      });
    }
    setTableData(defaultData); // Set tableData with filtered or unfiltered data
  }, [data, searchText]); // Update tableData when data prop or searchText changes

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Show loading message while data is being fetched

  return (
    <div className={`dataTables_wrapper dt-bootstrap4 no-footer ${className ? className : ""}`}>
      <Row className={`justify-between g-2 ${actions ? "with-export" : ""}`}>
        <Col className="col-7 text-start" sm="4">
          <div id="DataTables_Table_0_filter" className="dataTables_filter w-220">
            <label>
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder={`Search by ${searchBy}`}
                autoComplete="off"
                onChange={(ev) => {
                  setSearchText(ev.target.value);
                  // Delay the setSearch invocation by 1 second
                  if (!normalSearch) {
                    setTimeout(() => {
                      pageNo(0);
                      setSearch(ev.target.value);
                    }, 500);
                  }
                }}
              />
            </label>
          </div>
        </Col>
        <Col className="col-5 text-end" sm="8">
          <div className="datatable-filter">
            <div className="d-flex justify-content-end g-2">
              {/* {actions && <Export data={data} />} */}
              {/* {actions && <Export data1={filteredData} />} */}
              <div className="dataTables_length" id="DataTables_Table_0_length">
                <label>
                  <span className="d-none d-sm-inline-block">Show</span>
                  <div className="form-control-select">
                    <select
                      name="DataTables_Table_0_length"
                      className="custom-select custom-select-sm form-control form-control-sm"
                      onChange={(e) => setRowsPerPage(e.target.value)}
                      value={rowsPerPageS}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {loading ? (
        // <div className="d-flex justify-content-start align-items-center mt-3 ms-1">
        //   <Spinner color="primary" size="sm" />
        //   <h5 className="ms-1">{searchText === "" ? "Loading..." : "Searching..."}</h5>
        // </div>
        <div className="p-1">
          <ShimmerTable row={5} />
        </div>
      ) : (
        <DataTable
          data={tableData && tableData.length > 0 ? tableData : []}
          columns={columns}
          className={className}
          selectableRows={selectableRows}
          selectableRowsComponent={CustomCheckbox}
          expandableRowsComponent={ExpandableRowComponent}
          expandableRows={mobileView}
          noDataComponent={<div className="p-2">There are no records found</div>}
          sortIcon={
            <div>
              <span>&darr;</span>
              <span>&uarr;</span>
            </div>
          }
          pagination={pagination}
          paginationComponent={({ currentPage, rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage }) => {
            const ChangePage = (payload) => {
              if (pageNo) pageNo(payload - 1); // payload has clicked page no // setting it in state from APIs
              onChangePage(payload);
            };

            const ChangeRowsPerPage = (payload) => {
              if (recordLimit) recordLimit(payload); // payload has no of records to display // setting it in state from APIs
              onChangeRowsPerPage(payload);
            };
            return (
              <>
                {totalRecords > 10 && (
                  <DataTablePagination
                    customItemPerPage={rowsPerPageS}
                    itemPerPage={rowsPerPage}
                    totalItems={totalRecords || rowCount} // totalRecords comes from props; it is fetched from API
                    paginate={ChangePage} // callback function to retrieve the clicked page number
                    currentPage={parseInt(activePage) + 1 || currentPage} // contains the current page number
                    onChangeRowsPerPage={ChangeRowsPerPage} // callback function to retrieve the number of records to display
                    setRowsPerPage={setRowsPerPage}
                    changePage={pageNo}
                  />
                )}
              </>
            );
          }}
        ></DataTable>
      )}
    </div>
  );
};

export default ReactDataTable;
