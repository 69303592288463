import React, { useState } from 'react';
import { Col, Row, Button, Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { Icon } from '../../components/Component';
import EventListPage from './EventListPage';
import NavigationHeader from '../components/NavigationHeader';
import EventBookingRequest from './EventBookingRequest';
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import dashboard from "../../Jsonfiles/Dashboard.json"

const CommonEvent = () => {
  const activeVariable = getActiveEnvironmentVariable();
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  

  return (
    <div className="">
        <div className='mb-3 ml-3'>
        <NavigationHeader heading={`${dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)} List`} buttonInnerText={`Add ${dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)}`} route={"/add-event"} />
        </div>
      <Nav tabs className='ms-4'>
        <NavItem>
          <NavLink
            tag="a"
            href="#tab"
            className={classnames({ active: activeTab === "1" })}
            onClick={(ev) => {
              ev.preventDefault();
              toggleTab("1");
            }}
          >
            <Icon name="view-list-fill" /> <span>{dashboard?.[activeVariable]?.section1?.map((item) => item?.text1)}</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag="a"
            href="#tab"
            className={classnames({ active: activeTab === "2" })}
            onClick={(ev) => {
              ev.preventDefault();
              toggleTab("2");
            }}
          >
            <Icon name="view-list-fill" /> <span>{dashboard?.[activeVariable]?.section1?.map((item) => item?.text6)} Request</span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <EventListPage  currentState={activeTab} />
        </TabPane>
        <TabPane tabId="2">
        <EventBookingRequest currentState={activeTab} />
        </TabPane>
      </TabContent>

    </div>
  );
};

export default CommonEvent;

