import React, { useContext, useEffect, useRef, useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
  Progress,
  Spinner,
} from "reactstrap";
import { Icon, UserAvatar } from "../../components/Component";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { getInitiatals } from "../../utils/Utils";
import { ChatContext } from "./ChatContext";
import { useTheme } from "../../layout/provider/Theme";
import ContactCard from "../components/ContactCard";
import pdfImg from "../../images/reports.png";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import { ClearChat } from "../../Firebase Files/firebase-service";
import FileMessage from "../components/FileMessage";
import { db } from "../../Firebase Files/firebaseConfig";
import ChatLastMessages from "../components/ChatLastMessages";

export const MeChat = ({
  item,
  chat,
  toggleDeleteChatModal,
  handleMsgAction,
  messageRefs,
  scrollToMessage,
  select,
  handleSelectMessage,
  selectedMessageId,
  chatItemClick,
  uploading,
  uploadingFiles,
  lastMessageId,
}) => {
  const theme = useTheme();

  useEffect(() => {
    return () => {
      // Revoke all URLs after component unmount
      Object.keys(uploadingFiles).forEach((fileName) => {
        const fileObject = uploadingFiles[fileName].file;
        if (fileObject instanceof File) {
          URL.revokeObjectURL(fileObject);
        }
      });
    };
  }, [uploadingFiles]);

  return (
    <div
      className="chat is-me"
      ref={(el) => (messageRefs.current[item.id] = el)} // Set ref for each message
    >
      <div className="chat-content">
        <div className="chat-bubbles">
          <div className="chat-bubble" key={item?.id}>
            {item?.deleted === true ? (
              <div className="chat-msg border bg-white text-muted">
                This message has been deleted
              </div>
            ) : (
              <React.Fragment>
                <div>
                  <ul
                    className="chat-meta d-flex"
                    style={{ listStyleType: "none", paddingLeft: 0 }}
                  >
                    <li className="text-primary">
                      {item?.edited && <p>edited</p>}
                    </li>
                  </ul>

                  {item?.reply && (
                    <div
                      className="chat-bubble pointer"
                      key={item.id}
                      onClick={() => scrollToMessage(item.replyingToMessageId)}
                    >
                      <div
                        className={`chat-msg bg-white text-${
                          theme.skin === "dark" ? "white" : "dark"
                        }`}
                      >
                        {item.replyingMsg
                          .slice(0, 80)
                          .split(" ")
                          .map((word, i) =>
                            word.match(/(https?:\/\/[^\s]+)/g) ? (
                              <span
                                key={i}
                                href={word}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {word}
                                {item.replyingMsg.length > 80 ? " ..." : ""}
                              </span>
                            ) : (
                              <span key={i}>{word}</span>
                            )
                          )}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {item?.contactShare && (
                      <>
                        <ContactCard
                          contact={item?.contactInfo}
                          chatItemClick={chatItemClick}
                          item={item}
                        />
                      </>
                    )}
                    {item.fileURLs && item.fileURLs.length > 0 && (
                      <div
                        className={`chat-msg bg-${chat.chatTheme} ${
                          item?.contactShare || item?.forwarded ? "d-none" : ""
                        }`}
                      >
                        {item?.forwardedFiles && (
                          <div className="text-50 opacity-75 mb-2">
                            <Icon name="reply-all"></Icon>
                            forwarded
                          </div>
                        )}
                        <FileMessage item={item} pdfImg={pdfImg} />
                      </div>
                    )}

                    {item.text !== "" && (
                      <div
                        className={`chat-msg bg-${chat.chatTheme} ${
                          item?.contactShare ? "d-none" : ""
                        }`}
                        style={{
                          width: "fit-content",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          textAlign: "start",
                          fontSize: "13px",
                        }}
                      >
                        {item?.forwarded && item.text !== "" && (
                          <div className="text-50 opacity-75">
                            <Icon name="reply-all"></Icon>
                            forwarded
                          </div>
                        )}

                        {item.text}
                      </div>
                    )}

                    {/* Conditionally render the checkbox if `select` is true */}
                    {select && (
                      <div
                        className="chat-checkbox"
                        style={{
                          marginLeft: "10px",
                          textAlign: "-webkit-right",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={() => handleMsgAction(item.id, "select")}
                          checked={selectedMessageId?.includes(item.id)} // Keep checkbox selected based on selected IDs
                          style={{
                            width: "20px",
                            height: "20px",
                            transform: "scale(0.9)", // Make the checkbox bigger
                            accentColor: "red", // Set the checkbox theme to reddish
                            cursor: "pointer",
                          }}
                          onClick={() => handleSelectMessage(item.id)} // Select or deselect message
                        />
                      </div>
                    )}
                  </div>
                </div>
                {/* Show uploading indicator only on the last message */}

                <ul className="chat-msg-more">
                  <li className="d-none d-sm-block">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        href="#options"
                        className="btn btn-icon btn-sm btn-trigger"
                      >
                        <Icon name="more-v" />
                      </DropdownToggle>
                      <DropdownMenu end>
                        <DropdownItem
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggleDeleteChatModal(item);
                          }}
                        >
                          Delete
                        </DropdownItem>

                        {/* <DropdownItem
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleMsgAction(item.id, "copy");
                          }}
                        >
                          Copy
                        </DropdownItem> */}
                        {/* <DropdownItem
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleMsgAction(item.id, "reply");
                          }}
                        >
                          Reply
                        </DropdownItem> */}
                        {/* <DropdownItem
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleMsgAction(item.id, "edit");
                          }}
                        >
                          Edit
                        </DropdownItem> */}
                        {/* <DropdownItem
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleMsgAction(item.id, "selectMsg");
                          }}
                        >
                          Select messages
                        </DropdownItem> */}
                        {/* <DropdownItem
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleMsgAction(item.id, "forwardMsg", item);
                          }}
                        >
                          Forward
                        </DropdownItem> */}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                </ul>
              </React.Fragment>
            )}
          </div>
        </div>
        <ul className="chat-meta">
          <li>
            {new Date(item.timestamp.seconds * 1000).toLocaleTimeString(
              "en-US",
              {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }
            )}
          </li>
        </ul>
        {uploading && item.id === lastMessageId && (
          <div className={`chat-msg bg-${chat.chatTheme} uploading-indicator`}>
            <div className="uploading-header">
              <Spinner size="sm" color="primary" className="me-2" />
              <span className="uploading-text">Uploading Files...</span>
            </div>
            {Object.keys(uploadingFiles).map((fileName) => (
              <div key={fileName} className="file-upload-container">
                <div className="file-info">
                  <i className="file-icon bi bi-file-earmark-arrow-up" />{" "}
                  {/* Bootstrap Icon */}
                  <span className="file-name">{fileName}</span>
                </div>
                <Progress
                  animated
                  color="success"
                  value={Math.round(uploadingFiles[fileName])}
                  className="progress-bar"
                >
                  {Math.round(uploadingFiles[fileName])}%
                </Progress>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const YouChat = ({
  item,
  chat,
  handleMsgAction,
  messageRefs,
  scrollToMessage,
  select,
  handleSelectMessage,
  selectedMessageId,
  chatItemClick,
}) => {
  const theme = useTheme();
  return (
    <div
      className="chat is-you"
      ref={(el) => (messageRefs.current[item?.id] = el)} // Set ref for each message
    >
      <div className="chat-avatar">
        {chat?.profilePic ? (
          <img
            src={chat?.profilePic} // URL of the DJ's profile image
            alt="Profile"
            className="user-avatar"
            style={{ width: "45px", height: "45px", borderRadius: "50%" }} // Ensure the image is rounded
          />
        ) : (
          <UserAvatar
            text={getInitiatals(chat?.name)}
            theme="primary"
            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
          ></UserAvatar>
        )}
      </div>
      <div className="chat-content">
        <div className="chat-bubbles">
          {item?.deleted === true ? (
            <div className="chat-msg border bg-white text-muted">
              This message has been deleted
            </div>
          ) : (
            <div className="chat-bubble" key={item?.id}>
              <div>
                <ul
                  className="chat-meta d-flex "
                  style={{ listStyleType: "none", paddingLeft: 0 }}
                >
                  <li className="text-primary">
                    {item?.edited && <p>edited</p>}
                  </li>
                </ul>

                {item?.reply && (
                  <div
                    className="chat-bubble pointer"
                    key={item.id}
                    onClick={() => scrollToMessage(item?.replyingToMessageId)}
                  >
                    <div
                      className={`chat-msg bg-white text-${
                        theme.skin === "dark" ? "white" : "grey"
                      }`}
                    >
                      <p>
                        {item?.replyingMsg
                          .slice(0, 80)
                          .split(" ")
                          .map((word, i) =>
                            word.match(/(https?:\/\/[^\s]+)/g) ? (
                              <span
                                key={i}
                                href={word}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {word}
                                {item?.replyingMsg.length > 80 ? " ..." : ""}
                              </span>
                            ) : (
                              <span key={i}>{word}</span>
                            )
                          )}
                        {item.replyingMsg.length > 80 ? " ..." : ""}
                      </p>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {item?.contactShare && (
                    <>
                      <ContactCard
                        contact={item?.contactInfo}
                        chatItemClick={chatItemClick}
                        item={item}
                      />
                    </>
                  )}
                  {item.fileURLs && item.fileURLs.length > 0 && (
                    <div
                      className={`chat-msg bg-${chat?.chatTheme} ${
                        item?.contactShare || item?.forwarded ? "d-none" : ""
                      }`}
                    >
                      {item?.forwardedFiles && (
                        <div className="text-50 opacity-75 mb-2">
                          <Icon name="reply-all"></Icon>
                          forwarded
                        </div>
                      )}
                      <FileMessage item={item} pdfImg={pdfImg} />
                    </div>
                  )}
                  {item.text !== "" && (
                    <div
                      className={`chat-msg bg-${chat.chatTheme} ${
                        item?.contactShare ? "d-none" : ""
                      }`}
                      style={{
                        width: "fit-content",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        textAlign: "start",
                        fontSize: "13px",
                      }}
                    >
                      {item?.forwarded && (
                        <div className="text-50 opacity-75">
                          <Icon name="reply-all"></Icon>
                          forwarded
                        </div>
                      )}

                      {item.text}
                    </div>
                  )}
                  {/* Conditionally render the checkbox if `select` is true */}
                  {select && (
                    <div
                      className="chat-checkbox"
                      style={{ marginLeft: "10px", textAlign: "-webkit-right" }}
                    >
                      <input
                        type="checkbox"
                        onChange={() => handleMsgAction(item.id, "select")}
                        checked={selectedMessageId?.includes(item.id)} // Keep checkbox selected based on selected IDs
                        style={{
                          width: "20px",
                          height: "20px",
                          transform: "scale(0.9)", // Make the checkbox bigger
                          accentColor: "red", // Set the checkbox theme to reddish
                          cursor: "pointer",
                        }}
                        onClick={() => handleSelectMessage(item.id)} // Select or deselect message
                      />
                    </div>
                  )}
                </div>
              </div>{" "}
              <ul className="chat-msg-more">
                <li className="d-none d-sm-block">
                  <UncontrolledDropdown>
                    {/* <DropdownToggle
                      tag="a"
                      href="#options"
                      className="btn btn-icon btn-sm btn-trigger"
                    >
                      <Icon name="more-v" />
                    </DropdownToggle> */}
                    {/* Dropdown Menu */}
                    <DropdownMenu left>
                      {/* <DropdownItem
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleMsgAction(item.id, "copy");
                        }}
                      >
                        Copy
                      </DropdownItem>
                      <DropdownItem
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleMsgAction(item.id, "reply");
                        }}
                      >
                        Reply
                      </DropdownItem> 
                      <DropdownItem
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleMsgAction(item.id, "selectMsg");
                        }}
                      >
                        Select messages
                      </DropdownItem>
                      <DropdownItem
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleMsgAction(item.id, "forwardMsg", item);
                        }}
                      >
                        Forward
                      </DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
              </ul>
            </div>
          )}
          {/* );
          })} */}
        </div>
        <ul className="chat-meta">
          {/* <li>{chat.name}</li> */}
          <li>
            {new Date(item.timestamp.seconds * 1000).toLocaleTimeString(
              "en-US",
              {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

//component which shows recent chat results
export const ChatItem = ({
  item,
  chatItemClick,
  setSelectedId,
  selectedId,
  currentUser,
  setLoading,
  setDeletedUserIds,
  setFilteredChatList,
}) => {
  const dropdownRef = useRef(null);

  const { deleteConvo } = useContext(ChatContext);
  const [lastMessageTime, setLastMessageTime] = useState("");
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleContextMenu = (e) => {
    e.preventDefault();
    setIsDropdownOpen(true);
  };

  const handleCloseDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseDropdown);
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") setIsDropdownOpen(false);
    });

    return () => {
      document.removeEventListener("click", handleCloseDropdown);
      document.removeEventListener("keydown", (e) => {
        if (e.key === "Escape") setIsDropdownOpen(false);
      });
    };
  }, []);

  const checkBeforeDelete = (name, id) => {
    if (selectedId === id) {
      setSelectedId(null);
    }
    deleteConvo(id);
    openModal1(name, id);
  };

  const toggleModal1 = () => {
    setIsModalOpen1(!isModalOpen1);
  };

  const openModal1 = (name, id) => {
    setSelectedContact({ name, id });
    toggleModal1();
  };

  const confirmDelete1 = async () => {
    if (!selectedContact) return;
    try {
      setLoading(true);
      await ClearChat(currentUser?.id, selectedContact?.id, currentUser?.name);
      setDeletedUserIds((prev) => new Set(prev).add(selectedContact?.id));
    } catch (error) {
      console.error("Error clearing the chat:", error);
    } finally {
      setLoading(false);
      toggleModal1();
    }
  };

  return (
    <>
      <li
        className={`chat-item ${item?.unread ? "is-unread" : ""}`}
        style={item?.id === selectedId ? { backgroundColor: "#ebeef2" } : {}}
        onContextMenu={handleContextMenu}
      >
        <a
          className="chat-link"
          href="#chat-link"
          onClick={(ev) => {
            ev.preventDefault();
            chatItemClick(item?.id, item?.username || item?.name, item);
          }}
        >
          <div
            className="position-relative"
            style={{ width: "45px", height: "45px" }}
          >
            {item?.profilePic ? (
              <img
                src={item.profilePic}
                alt={`Profile of ${item?.name || item?.username}`}
                className="user-avatar"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <div
                className="d-flex align-items-center justify-content-center bg-primary border"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#555",
                }}
              >
                {getInitiatals(item?.name || item?.username)}
              </div>
            )}
          </div>

          <div className="chat-info ps-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="chat-from">
                <div className="name text-capitalize">
                  {item?.name || item?.username}
                </div>
              </div>

              <div className="d-flex align-items-center">
                <span
                  className="text-muted me-2"
                  style={{ fontSize: "0.85rem" }}
                >
                  {lastMessageTime}
                </span>
              </div>
            </div>

            <div className="chat-context">
              <div className="text w-100">
                <ChatLastMessages
                  itemId={item?.id}
                  currentUserId={currentUser?.id}
                  selectedId={selectedId}
                  setLastMessageTime={setLastMessageTime}
                />
              </div>
            </div>
          </div>
        </a>

        {isDropdownOpen && (
          <div
            ref={dropdownRef}
            className="chat-actions"
            style={{
              position: "absolute",
              zIndex: 1000,
              right: "10px",
              top: "10px",
            }}
          >
            <UncontrolledDropdown isOpen={isDropdownOpen}>
              <DropdownToggle
                tag="a"
                className="btn btn-icon btn-sm btn-trigger dropdown-toggle"
              >
                <Icon name="more-v"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li
                    onClick={() =>
                      checkBeforeDelete(item?.username || item?.name, item?.id)
                    }
                  >
                    <DropdownItem
                      tag="a"
                      href="#delete"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      disabled={item?.id === currentUser?.id}
                    >
                      Delete
                    </DropdownItem>
                  </li>
                  {/* <li onClick={() => propAction(item.id, "unread")}>
                    <DropdownItem
                      tag="a"
                      href="#unread"
                      className={item.unread ? "disabled" : ""}
                      onClick={() => {
                        handleClearChat();
                      }}
                    >
                      Clear Chat
                    </DropdownItem>
                  </li> */}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
      </li>
      <ConfirmDeleteModal
        isOpen={isModalOpen1}
        toggle={toggleModal1}
        onConfirm={confirmDelete1}
      />
    </>
  );
};

//component which shows search items
export const ChatItemSearch = ({
  item,
  chatItemClick,
  selectedId,
  currentUser,
  setMessages,
}) => {
  const [lastMessageTime, setLastMessageTime] = useState("");

  useEffect(() => {
    const getChatTime = async () => {
      const chatRoomsQuery = query(
        collection(db, "Chats"),
        where("participants", "array-contains", currentUser?.id)
      );

      const chatRoomsSnapshot = await getDocs(chatRoomsQuery);
      let chatRoomId = null;
      chatRoomsSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.participants.includes(item?.id)) {
          chatRoomId = doc.id;
        }
      });

      if (!chatRoomId) {
        return;
      }
    };

    getChatTime();
  }, []);

  useEffect(() => {
    async function setupLastMessageListener() {
      try {
        // Query to find the chatroom between the current user and the specified item ID
        const chatRoomsQuery = query(
          collection(db, "Chats"),
          where("participants", "array-contains", currentUser?.id)
        );
        const chatRoomsSnapshot = await getDocs(chatRoomsQuery);
        let chatRoomId = null;
        chatRoomsSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.participants.includes(item?.id)) {
            chatRoomId = doc.id;
          }
        });

        if (!chatRoomId) {
          return;
        }
      } catch (error) {
        console.error("Error setting up listener for last message:", error);
      }
    }

    setupLastMessageListener();
  }, [item?.id, currentUser?.id]);

  return (
    <>
      <li
        className={`chat-item ${item?.unread ? "is-unread" : ""}`}
        style={item?.id === selectedId ? { backgroundColor: "#ebeef2" } : {}}
      >
        <a
          className="chat-link"
          href="#chat-link"
          onClick={(ev) => {
            ev.preventDefault();
            chatItemClick(item?.id, item?.username || item?.name, item);
          }}
        >
          <div
            className="position-relative"
            style={{ width: "45px", height: "45px", overflow: "hidden" }}
          >
            {item?.profile_image_path &&
            item?.profile_image_path.trim() !== "" ? (
              <>
                <img
                  src={item?.thumbnail}
                  alt={`Profile of ${item?.name || item?.username}`}
                  className="user-avatar"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </>
            ) : (
              <>
                <UserAvatar
                  id={item.id}
                  text={getInitiatals(item?.name || item?.username)}
                  theme="primary"
                  style={{ width: "45px", height: "45px", borderRadius: "50%" }}
                />
              </>
            )}
          </div>

          <div className="chat-info ps-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="chat-from">
                <div className="name text-capitalize">
                  {item?.name || item?.username}
                </div>
              </div>

              <div className="d-flex align-items-center">
                <span
                  className="badge bg-primary text-uppercase"
                  style={{
                    fontSize: "8px",
                    padding: "0px 4px",
                    borderRadius: "50%",
                    color: "#fff",
                    fontWeight: "400",
                    border: "none",
                    boxShadow: "0 1px 2px rgba(40, 167, 69, 0.3)",
                  }}
                >
                  {item?.role_name === "DJ" && item?.role_name}
                </span>
              </div>
            </div>

            <div className="chat-context">
              <div className="text w-100">
                <ChatLastMessages
                  itemId={item?.id}
                  currentUserId={currentUser?.id}
                  selectedId={selectedId}
                  setLastMessageTime={setLastMessageTime}
                />
              </div>
            </div>
          </div>
        </a>
      </li>
    </>
  );
};
