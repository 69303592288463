import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import NavigationFooter from "../../components/NavigationFooter";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
// import ReactSelect from "react-select";
import ReactSelect from "../../../components/ReactSelect/ReactSelect";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery } from "react-query";
import { getCities, getCountries, getStates } from "../../../http/get/getApi";
import { transformData } from "../../../utils/Utils";
import UploadImage from "../../components/UploadImage";
import Required from "../../components/Required";
import { toast } from "react-toastify";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { DataContext } from "../../../context/DataProvider";
import { handleResetEmail, verifyEmailResetOtp } from "../../../http/edit/editApi";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import debounce from "lodash.debounce";
import { checkEmailAvailability } from "../../../http/post/postApi";

const BasicinfoPhotographer = ({ formik, currentState, toggle, isCompanyProfileEditing, companyprofileLoading }) => {
  const { hasUnsavedChanges, setHasUnsavedChanges } = useContext(DataContext);
  const [cityName, SetCityNmae] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [modalOpen1, setModalOpen1] = useState(false);
  const [otpFiledIsFilled, setOtpFiledIsFilled] = useState(false);
  const [passOtp, setPassOtp] = useState();
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [emailError, setEmailError] = useState(true);
  const [emailMessage, setEmailMessage] = useState("");
  const [emailAvialable, setEmailAvialable] = useState(true);

  const firstInputRef = useRef(null);
  const [focusedIndex, setFocusedIndex] = useState(0);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
      setFocusedIndex(0);
    }
  }, [modalOpen1]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    debouncedCheckEmailAvailability(email);
    setNewEmail(email);
    setIsValidEmail(validateEmail(email));
  };

  const handelCountryDropdown = (e) => {
    formik.setFieldValue("country", e.value);
    setHasUnsavedChanges(true);
  };
  const handelStateDropdown = (e) => {
    formik.setFieldValue("state", e.value);
    setHasUnsavedChanges(true);
  };

  const handelCityDropdown = (e) => {
    formik.setFieldValue("city", e.value);
    SetCityNmae(e.label);
    setHasUnsavedChanges(true);
  };

  const getLocation = async (selectedCity) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(selectedCity)}&format=json`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.length > 0) {
        const location = data[0];
        formik.setFieldValue("latitude", location.lat);
        formik.setFieldValue("longitude", location.lon);
      } else {
        alert("Unable to geocode the address. Please try again.");
      }
    } catch (error) {
      alert("Error occurred while fetching location data.");
    }
  };

  // const getLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //       formik.setFieldValue("latitude", position.coords.latitude);
  //       formik.setFieldValue("longitude", position.coords.longitude);

  //       },
  //       () => {
  //         alert("Geolocation is not supported by this browser.");
  //       }
  //     );
  //   } else {
  //     alert("Geolocation is not supported by this browser.");
  //   }
  // };

  //fetching countries data
  const {
    data: country,
    isLoading: countryLoading,
    isError: countryError,
  } = useQuery({
    queryKey: ["get-country"],
    queryFn: () => getCountries(),
    staleTime: Infinity,
    // staleTime: 60000,
    // cacheTime: 300000,
    // retry: 1,
  });

  //optiopns for countries dropdown
  const countriesOptions = transformData({
    dataArray: country?.data?.countries || [],
    label: "name",
    value: "id",
    isLoading: countryLoading,
  });

  let countryId = formik.values?.country;

  const {
    data: states,
    isLoading: statesLoading,
    isError: statesError,
  } = useQuery({
    queryKey: ["get-states-by-country", countryId],
    queryFn: () => getStates({ id: countryId }),
    staleTime: Infinity,
    // staleTime: 60000,
    // cacheTime: 300000,
    // retry: 1,
  });

  //optiopns for states dropdown
  const statesOptions = transformData({
    dataArray: states?.data?.states || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  // ---------------------
  let stateId = formik.values?.state;

  const {
    data: cities,
    isLoading: citiesLoading,
    isError: citiesError,
  } = useQuery({
    queryKey: ["get-cities-by-state", stateId],
    queryFn: () => getCities({ id: stateId }),
    staleTime: Infinity,
    // staleTime: 60000,
    // cacheTime: 300000,
    // retry: 1,
  });

  //optiopns for cities dropdown
  const citiesOptions = transformData({
    dataArray: cities?.data?.cities || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  // ------------

  const handelValidation = (callbackFun) => {
    if (formik.values.first_name === "") {
      formik.setFieldTouched("first_name", true);
    }

    if (formik.values.last_name === "") {
      formik.setFieldTouched("last_name", true);
    }

    if (formik.values.phone_number === "") {
      formik.setFieldTouched("phone_number", true);
    }
    // if (formik.values.zip_code === "") {
    //   formik.setFieldTouched("zip_code", true);
    // }

    // if (formik.values.email === "") {
    //   formik.setFieldTouched("email", true);
    // }

    if (formik.values.city === "") {
      formik.setFieldTouched("city", true);
    }
    if (formik.values.country === "") {
      formik.setFieldTouched("country", true);
    }
    if (formik.values.state === "") {
      formik.setFieldTouched("state", true);
    }

    if (Number.isNaN(formik.values.state)) {
      document.getElementById("state").style.border = "2px solid red";

      toast.warning("state  field is required.");
    } else {
      document.getElementById("state").style.border = "none";
    }

    if (Number.isNaN(formik.values.city)) {
      document.getElementById("city").style.border = "2px solid red";
      toast.warning("City  field is required.");
    } else {
      document.getElementById("city").style.border = "none";
    }
    // if (formik.values.files === "") {
    //   formik.setFieldTouched("files", true);
    // }
    // if (formik.values.uniqueness === "") {
    //   formik.setFieldTouched("uniqueness", true);
    // }
    // if (formik.values.contact_number === "") {
    //   formik.setFieldTouched("contact_number", true);
    // }

    // if (formik.errors.email && formik.values.email !== "") {
    //   toast.warning("Invalid Email.");
    //   return;
    // }

    // if (formik.errors.zip_code && formik.values.zip_code !== "") {
    //   toast.warning("Invalid zip-code.");
    //   return;
    // }

    if (
      formik.values.first_name !== "" &&
      formik.values.last_name !== "" &&
      formik.values.phone_number !== "" &&
      formik.values.country !== "" &&
      formik.values.state !== "" &&
      formik.values.city !== "" &&
      !formik.errors.state &&
      !formik.errors.city &&
      !formik.errors.zip_code &&
      !formik.errors.phone_number &&
      !formik.errors.personal_phone_number &&
      !Number.isNaN(formik.values.state) &&
      !Number.isNaN(formik.values.city)
    ) {
      callbackFun(true);
    }
  };

  // const handelValidation = (callbackFun) => {
  //   callbackFun(true);
  // };

  const handleName = (e) => {
    const { value } = e.target;
    let wordCount = 0;

    // Check if the input is not empty
    if (value.trim() !== "") {
      wordCount = value.trim().split(/\s+/).length;
    }

    formik.setFieldValue("nameCount", wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const handleLastName = (e) => {
    const { value } = e.target;
    let wordCount = 0;

    // Check if the input is not empty
    if (value.trim() !== "") {
      wordCount = value.trim().split(/\s+/).length;
    }

    formik.setFieldValue("lastNameCount", wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const handleContactNumber = (e) => {
    const { value } = e.target;
    let wordCount = 0;

    // Check if the input is not empty
    if (value.trim() !== "") {
      wordCount = value.trim().split(/\s+/).length;
    }

    formik.setFieldValue("contactNumberCount", wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const handlePersonalContactNumber = (e) => {
    const { value } = e.target;
    let wordCount = 0;

    // Check if the input is not empty
    if (value.trim() !== "") {
      wordCount = value.trim().split(/\s+/).length;
    }

    formik.setFieldValue("personal_phone_number_count", wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const handleAddress1 = (e) => {
    const { value } = e.target;
    let wordCount = 0;

    // Check if the input is not empty
    if (value.trim() !== "") {
      wordCount = value.trim().split(/\s+/).length;
    }

    formik.setFieldValue("address1Count", wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const handleAddress2 = (e) => {
    const { value } = e.target;
    let wordCount = 0;

    // Check if the input is not empty
    if (value.trim() !== "") {
      wordCount = value.trim().split(/\s+/).length;
    }

    formik.setFieldValue("address2Count", wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const handleZipCode = (e) => {
    const { value } = e.target;
    let wordCount = 0;

    // Check if the input is not empty
    // if (value.trim() !== '') {
    //   wordCount = value?.trim()?.split(/\s+/)?.length;
    // }

    formik.setFieldValue("zipCodeCount", wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const handleLatitude = (e) => {
    // const { value } = e.target;
    const value = parseFloat(e.target.value);
    // let wordCount = 0;

    // Check if the input is not empty
    // if (value.trim() !== '') {
    //   wordCount = value?.trim()?.split(/\s+/)?.length;
    // }

    // console.log("wordCountUU", wordCount);
    formik.setFieldValue("latitude", value);
    // console.log("value",value)
    setHasUnsavedChanges(true);
    // formik.handleChange(value);
  };

  const handleLongitude = (e) => {
    // const { value } = e.target;
    const value = parseFloat(e.target.value);
    // let wordCount = 0;

    // Check if the input is not empty
    // if (value.trim() !== '') {
    //   wordCount = value.trim().split(/\s+/).length;
    // }

    // console.log("wordCountUU", wordCount);
    formik.setFieldValue("longitude", value);
    setHasUnsavedChanges(true);
    // formik.handleChange(value);
  };

  const {
    mutate: emailverify,
    isLoading: emailverifyLoading,
    error: emailVerifyError,
  } = useMutation({
    mutationKey: ["verify-email"],
    mutationFn: (value) => handleResetEmail(value),
    onSuccess: (data) => {
      if (data.status) {
      }
    },
  });

  const {
    mutate: otpverify,
    isLoading: emailOtpLoading,
    error: emailOtpyError,
  } = useMutation({
    mutationKey: ["verify-email-otp"],
    mutationFn: (value) => verifyEmailResetOtp(value),
    onSuccess: (data) => {
      if (data.status === true) {
        Swal.fire({
          icon: "success",
          title: "OTP Verified!",
          text: "You will be redirected to the login page shortly.",
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            setLogout();
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Invalid OTP.",
          timer: 3000,
        });
      }
    },
  });

  const handleEmail = () => {
    setModalOpen(true);
  };

  const handleSaveEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await handleResetEmail({ email: newEmail });
      setPassOtp(response);
      setModalOpen(false);
      Swal.fire({
        icon: "success",
        title: "OTP Sent!",
        text: "An OTP has been sent to your new email address. Please check your inbox.",
        timer: 4000,
        timerProgressBar: true,
        showConfirmButton: false,
      }).then(() => {
        // Optionally handle post-OTP-send actions here
        // setNewEmail("");
        setModalOpen1(true);
      });
    } catch (error) {
      console.error("Error saving email:", error);

      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Something went wrong while sending the OTP. Please try again.",
      });
      setModalOpen1(true);
    }
  };

  // Handle OTP input change
  const handleOtpChange = (value, index) => {
    if (/^[0-9]*$/.test(value)) {
      // Ensure only numbers
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      const allFieldsFilled = newOtp.every((digit) => digit && digit.length === 1);
      setOtpFiledIsFilled(allFieldsFilled);
      // Auto-focus to next box if not the last box
      if (value && index < 3) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const setLogout = () => {
    sessionStorage.removeItem("profileProgress");
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    Cookies.set("logoutURL", process.env.REACT_APP_ACCOUNT_URL, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
    window.location.href = logoutUrl;
  };

  // Handle OTP submission
  const handleSubmitOtp = () => {
    const enteredOtp = otp.join("");
    const verifyData = { otp: enteredOtp, email: newEmail };
    otpverify(verifyData);
    setNewEmail("");
    setEmailMessage("");
    // Clear the OTP fields
    setOtp(new Array(4).fill(""));
    // setModalOpen1(false);
  };

  const debouncedCheckEmailAvailability = useCallback(
    debounce((email) => {
      checkEmailAvailability({ data: email, type: "email" })
        .then((response) => {
          setEmailError(response.status);
          if (response.data.email == true) {
            setEmailMessage("Email is available");
            setEmailAvialable(false);
          } else {
            setEmailMessage("Email is already taken");
            setEmailAvialable(true);
          }
          // setErrorMessage(response.message);
        })
        .catch((error) => {
          console.error("Error checking email availability:", error);
        });
    }, 3000), // 5000ms (5s) debounce delay
    []
  );

  return (
    <div>
      {companyprofileLoading ? (
        <div>
          <Row>
            <Col md={6} className="border p-3 bg-white">
              <Row>
                <Col md={6} className=" p-3 bg-white">
                  <ShimmerThumbnail height={250} width={300} rounded />
                  <ShimmerThumbnail height={40} width={300} rounded />
                  <ShimmerThumbnail height={40} width={300} rounded />
                </Col>
                <Col md={6} className=" p-3 bg-white">
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                </Col>
              </Row>
            </Col>
            <Col md={6} className=" border p-3 bg-white">
              <Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <Row className="p-2 py-0">
            {/* First Section */}
            <Col md={6} className="border p-3 bg-white">
              <section>
                <Row className="g-2 ">
                  <Col sm={12} md={7} lg={6}>
                    <UploadImage
                      label="Upload Profile Picture"
                      name="files"
                      formik={formik}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                    />
                    {formik.errors.files && formik.touched.files && (
                      <p style={{ color: "red" }}>{formik.errors.files}</p>
                    )}
                  </Col>
                  <Col sm={12} md={7} lg={6}>
                    <div>
                      <Label for="username">
                        Username
                        {/* <Required /> */}
                      </Label>
                      <Input
                        disabled={true}
                        type="text"
                        id="username"
                        name="username"
                        value={formik.values.username}
                        placeholder="Enter username"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.errors.username && formik.touched.username}
                      />
                      {formik.errors.username && formik.touched.username && (
                        <p style={{ color: "red" }}>{formik.errors.username}</p>
                      )}
                    </div>
                    <div className="mt-4">
                      <Label for="first_name">
                        First Name
                        <Required />
                      </Label>
                      <Input
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={formik.values.first_name}
                        placeholder="Enter first name"
                        // onChange={formik.handleChange}
                        onChange={handleName}
                        onBlur={formik.handleBlur}
                        invalid={formik.errors.first_name && formik.touched.first_name}
                      />
                      {formik.errors.first_name && formik.touched.first_name && (
                        <p style={{ color: "red" }}>{formik.errors.first_name}</p>
                      )}
                    </div>
                    <div className="mt-4">
                      <Label htmlFor="last_name">
                        Last Name
                        <Required />
                      </Label>
                      <Input
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={formik.values.last_name}
                        placeholder="Enter last name"
                        // onChange={formik.handleChange}
                        onChange={handleLastName}
                        onBlur={formik.handleBlur}
                        invalid={formik.errors.last_name && formik.touched.last_name}
                      />
                      {formik.errors.last_name && formik.touched.last_name && (
                        <p style={{ color: "red" }}>{formik.errors.last_name}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={7} lg={6}>
                    <div className="mt-4">
                      <Label for="phone_number">
                        Office Mobile Number
                        <Required />
                      </Label>
                      <Input
                        type="number"
                        id="phone_number"
                        name="phone_number"
                        value={formik.values.phone_number}
                        placeholder="Enter Office Mobile Number"
                        // onChange={formik.handleChange}
                        onChange={handleContactNumber}
                        onBlur={formik.handleBlur}
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        invalid={formik.errors.phone_number && formik.touched.phone_number}
                      />
                      {formik.errors.phone_number && formik.touched.phone_number && (
                        <p style={{ color: "red" }}>{formik.errors.phone_number}</p>
                      )}
                    </div>
                  </Col>
                  <Col sm={12} md={7} lg={6}>
                    <div className="mt-4">
                      <Label for="personal_phone_number">Personal Mobile Number</Label>
                      <Input
                        type="number"
                        id="personal_phone_number"
                        name="personal_phone_number"
                        value={formik.values.personal_phone_number}
                        placeholder="Enter Personal Mobile Number"
                        // onChange={formik.handleChange}
                        onChange={handlePersonalContactNumber}
                        onBlur={formik.handleBlur}
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        // invalid={formik.errors.phone_number && formik.touched.phone_number}
                      />
                      {formik.errors.personal_phone_number && formik.touched.personal_phone_number && (
                        <p style={{ color: "red" }}>{formik.errors.personal_phone_number}</p>
                      )}
                    </div>
                  </Col>
                </Row>
              </section>
            </Col>
            {/* Second Section */}
            <Col md={6} className="border p-3 bg-white border-start-0">
              <section>
                {/* First Row */}
                <Row className="g-2">
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      <Label for="email">
                        Email
                        {/* <Required /> */}
                      </Label>
                      <Input
                        disabled={true}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.errors.email && formik.touched.email}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <p style={{ color: "red" }}>{formik.errors.email}</p>
                      )}
                    </div>
                    <div>
                      <span style={{ cursor: "pointer", color: process.env.REACT_APP_THEME_COLOR }} onClick={handleEmail}>
                        Reset Email
                      </span>
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      {/* <Label for="address_1">Address Line 1</Label> */}
                      <Label for="address_1">Business Address 1</Label>
                      <Input
                        id="address_1"
                        placeholder="Enter Address 1"
                        maxLength={30}
                        name="address_1"
                        value={formik.values.address_1}
                        // onChange={formik.handleChange}
                        onChange={handleAddress1}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      {/* <Label for="address_2">Address Line 2</Label> */}
                      <Label for="address_2">Business Address 2</Label>
                      <Input
                        id="address_2"
                        placeholder="Enter Address 2"
                        maxLength={30}
                        name="address_2"
                        value={formik.values.address_2}
                        // onChange={formik.handleChange}
                        onChange={handleAddress2}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </Col>
                </Row>
                {/* Second Row */}
                <Row className="mt-2 g-2">
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      <Label for="country">
                        Select Country <Required />
                      </Label>
                      <ReactSelect
                        options={countriesOptions}
                        id="country"
                        name="country"
                        value={formik.values.country}
                        onChange={(e) => handelCountryDropdown(e)}
                        onBlur={() => formik.setFieldTouched("country", true)}
                        invalid={formik.errors.country && formik.touched.country}
                      />
                      {formik.errors.country && formik.touched.country && (
                        <p style={{ color: "red" }}>{formik.errors.country}</p>
                      )}
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      <Label for="state">
                        Select State <Required />
                      </Label>
                      <ReactSelect
                        options={statesOptions}
                        id="state"
                        name="state"
                        value={formik.values.state}
                        onChange={(e) => handelStateDropdown(e)}
                        onBlur={() => formik.setFieldTouched("state", true)}
                        // invalid={formik.errors.state && formik.touched.state}
                      />
                      {formik.touched.state && Number.isNaN(formik.values.state) && (
                        <p style={{ color: "red" }}>state field is required.</p>
                      )}
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      <Label for="city">
                        Select City <Required />
                      </Label>
                      <ReactSelect
                        options={citiesOptions}
                        id="city"
                        name="city"
                        value={formik.values.city}
                        onChange={(e) => {
                          handelCityDropdown(e);
                          getLocation(e.label);
                        }}
                        // onChange={(e) => handelCityDropdown(e)}
                        // onChange={(e) => {handelCityDropdown(e); getCoordinates()}}
                        onBlur={() => formik.setFieldTouched("city", true)}
                        invalid={formik.errors.city && formik.touched.city}
                      />
                      {formik.touched.city && Number.isNaN(formik.values.city) && (
                        <p style={{ color: "red" }}>City field is required.</p>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row className="mt-2 g-2">
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      <Label for="zip_code">
                        Zip-Code
                        {/* <Required /> */}
                      </Label>
                      <Input
                        id="zip_code"
                        type="number"
                        placeholder="Enter zip code"
                        name="zip_code"
                        value={formik.values.zip_code}
                        // onChange={formik.handleChange}
                        onChange={handleZipCode}
                        onBlur={formik.handleBlur}
                        invalid={formik.errors.zip_code && formik.touched.zip_code}
                      />
                      {formik.errors.zip_code && formik.touched.zip_code && (
                        <p style={{ color: "red" }}>{formik.errors.zip_code}</p>
                      )}
                    </div>
                  </Col>
                  {/* <Col md={4}>
                    <div>
                      <Label for="latitude ">Latitude</Label>
                      <Input
                        id="latitude "
                        type="number"
                        placeholder="Enter latitude "
                        name="latitude "
                        value={formik.values.latitude}
                        // onChange={formik.handleChange}
                        onChange={handleLatitude}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <Label for="longitude">Longitude</Label>
                      <Input
                        id="longitude"
                        type="number"
                        placeholder="Enter longitude"
                        name="longitude"
                        value={formik.values.longitude}
                        // onChange={formik.handleChange}
                        onChange={handleLongitude}
                      />
                    </div>
                  </Col> */}
                </Row>
              </section>
            </Col>
          </Row>
          <Row className="g-2">
            <NavigationFooter
              toggle={toggle}
              currentState={currentState}
              lastPage="8"
              // validationFunction={handelValidation}
              validationFunction={handelValidation}
              formik={formik}
              isCompanyProfileEditing={isCompanyProfileEditing}
              emailAvialable={true}
              usernameAvailable={true}
            />
          </Row>

          <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
            <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Reset Email</ModalHeader>
            <ModalBody>
              <Label for="newEmail">New Email</Label>
              <Input
                type="email"
                id="newEmail"
                value={newEmail}
                placeholder="Enter your new email"
                onChange={handleEmailChange}
              />
              {emailError && emailMessage == "Email is already taken" ? (
                <p style={{ color: emailMessage == "Email is already taken" ? "red" : "green" }}>{emailMessage}</p>
              ) : (
                <p style={{ color: emailMessage == "Email is available" ? "green" : "red" }}>{emailMessage}</p>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={(e) => handleSaveEmail(e)}
                disabled={!isValidEmail || emailAvialable} // Disable button if email is invalid
              >
                Send
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  setModalOpen(false);
                  setNewEmail("");
                  setEmailMessage("");
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={modalOpen1} toggle={() => setModalOpen1(!modalOpen1)}>
            <ModalHeader toggle={() => setModalOpen1(!modalOpen1)}>Verify OTP</ModalHeader>
            <ModalBody>
              <Label>Enter OTP</Label>
              <div style={{ display: "flex", justifyContent: "space-evenly", gap: "2px" }}>
                {otp.map((digit, index) => (
                  <Input
                    key={index}
                    ref={index === 0 ? firstInputRef : null}
                    id={`otp-input-${index}`}
                    type="text"
                    value={digit}
                    maxLength="1"
                    style={{
                      width: "40px",
                      textAlign: "center",
                      // borderColor: digit ? "#60CF4F" : "", // Green border when a value is entered
                      borderColor: focusedIndex === index ? process.env.REACT_APP_THEME_COLOR : digit ? process.env.REACT_APP_THEME_COLOR : "",
                      borderWidth: "2px",
                      padding: "5px",
                    }}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                  />
                ))}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" disabled={!otpFiledIsFilled} onClick={handleSubmitOtp}>
                Verify
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  setModalOpen1(false);
                  setOtp(new Array(4).fill(""));
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </div>
  );
};

export default BasicinfoPhotographer;
