import React, { useContext, useRef, useState } from "react";
import { Button, Spinner } from "reactstrap";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { DataContext } from "../../../context/DataProvider";
import subscribeimg from "../../../assets/images/subscribe.jpg";

const UploadMultipleAlbum = ({
  label,
  name,
  handleAddVideo,
  formik,
  isCompanyProfileEditing,
  mixesCount,
  mixersLimit,
  handleAddMixes,
  setHasUnsavedChanges,
  subscribedpalresponce,
  planEndDate,
}) => {
  const fileInputRef = useRef(null);

  const { planEnddates, activeTab, setActivetab } = useContext(DataContext);

  const getCurrentDate = () => {
    const d = new Date();
    return d.toISOString().split("T")[0];
  };
  const currentDateFormatted = getCurrentDate();
  const navigate = useNavigate();

  const [previewMixes, setPreviewMixes] = useState([]);
  const [dragging, setDragging] = useState(false);
  // console.log("previewMixes",previewMixes.length )
  // const totalMixesCount = previewMixes.length + mixesCount
  const totalMixesCount = previewMixes.length + mixesCount;

  // Function to handle video files
  const handleMixesChange = (e) => {
    const files = Array.from(e.target.files);
    processFiles(files);
  };

  // Function to handle drag and drop
  const handleDrop = (e) => {
    setHasUnsavedChanges(true);
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const files = Array.from(e.dataTransfer.files);
    processFiles(files);
  };

  // Function to open file selector dialog
  const handleClick = () => {
    setHasUnsavedChanges(true);
    fileInputRef.current.click();
  };

  // Handle drag over event
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  // Handle drag leave event
  const handleDragLeave = () => {
    setDragging(false);
  };

  // Process selected or dropped files
  const processFiles = (files) => {
    const base64Strings = [];
    // const maxSize = 500 * 1024 * 1024;
    const maxSize = 10 * 1024 * 1024;
    let allFilesValid = true; // Flag to track if all files are valid

    files.forEach((file) => {
      if (file.type !== "application/pdf") {
        Swal.fire({
          icon: "error",
          title: "Invalid file type",
          text: "Please upload only image files.",
        });
        allFilesValid = false;
        return;
      }

      if (file.size > maxSize) {
        Swal.fire({
          icon: "error",
          title: "File too large",
          text: "Each audio must be less than 50MB.",
        });
        allFilesValid = false;
        return;
      }

      if (allFilesValid) {
        base64Strings.push(file);
        setPreviewMixes((prev) => [...prev, ...base64Strings]);
      }
    });

    if (!allFilesValid) {
      return;
    }
  };

  // Handle saving videos
  const handleSaveMixes = () => {
    const currentDate = new Date(); // Get the current date
    const planEndDate = new Date(planEnddates); // Convert planEnddates to a Date object

    if (!subscribedpalresponce) {
      Swal.fire({
        title: "Subscription Required",
        text: "Subscribe to one of our plans and enjoy the ability to add more mixes to your account.",
        imageUrl: subscribeimg,
        imageWidth: 250,
        imageHeight: 200,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonText: "Subscribe Now",
        cancelButtonText: "Maybe Later",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/plan-selection");
        }
      });
    } else if (planEndDate < currentDate) {
      Swal.fire({
        title: "Subscription Expired",
        text: "Your subscription has expired. Please renew your subscription to proceed.",
        icon: "warning",
        confirmButtonText: "Renew Now",
        cancelButtonText: "Maybe Later",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/plan-selection"); // Navigate to the plan selection page
        }
      });
    } else {
      if (totalMixesCount <= mixersLimit) {
        handleAddMixes(previewMixes);
        setPreviewMixes([]);
        // formik.submitForm();
      } else {
        Swal.fire({
          title: "Mixes Limit Exceeded!",
          text: `You've reached the limit of ${mixersLimit} for your mixes. Upgrade your plan to add more mixes and make your business stand out!`,
          icon: "warning",
          confirmButtonText: "Upgrade Plan Now!",
          cancelButtonText: "Maybe Later",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/plan-selection");
            setActivetab("4");
          }
        });
      }
    }
  };

  // Handle removing a preview video
  const handleRemovePreviewMixes = (index) => {
    setPreviewMixes((prev) => prev.filter((_, i) => i !== index));
    setHasUnsavedChanges(false);
  };

  return (
    <div>
      <label>{label}</label>
      <br />
      <div
        style={{
          border: dragging ? "2px solid #00f" : "2px dashed #ccc",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
          height: "40%",
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
        className="upload-drop-zone mt-2"
      >
        {isCompanyProfileEditing ? (
          <Button disabled color="primary">
            <Spinner size="sm" />
            <span> Uploading... </span>
          </Button>
        ) : (
          <Button
            type="button"
            // className="btn btn"
            color="primary"
          >
            Select PDF's
          </Button>
        )}
        <input
          type="file"
          name={name}
          disabled={isCompanyProfileEditing}
          accept="application/pdf"
          multiple
          onChange={handleMixesChange}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <p>or drag and drop PDF here</p>
      </div>

      <div className="mt-3">
        {previewMixes.length > 0 && <h5>Preview pdf</h5>}
        <div style={{ maxHeight: "200px", overflowY: "auto", overflowX: "hidden" }}>
          {/* <div className="row">
            {previewMixes.map((mixe, index) => (
              <div
                key={index}
                className="col-md-6 position-relative mb-3"
                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              >
                <img
                  src={URL.createObjectURL(mixe)} // Use the image file directly for preview
                  alt={`Preview ${index}`}
                  style={{
                    height: "100px",
                    width: "100%",
                    objectFit: "cover",
                    border: "1px solid #EBECEC",
                  }}
                />
                <p
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                    marginTop: "5px",
                  }}
                >
                  {mixe?.name}
                </p>
                <Button
                  type="button"
                  className="btn btn-danger position-absolute"
                  style={{
                    top: "0px",
                    right: "14px",
                    fontSize: "0.7rem",
                    padding: "2px 5px",
                    lineHeight: "1",
                    height: "auto",
                    width: "auto",
                    borderRadius: "3px",
                  }}
                  onClick={() => handleRemovePreviewMixes(index)}
                >
                  X
                </Button>
              </div>
            ))}
          </div> */}
          <div className="row">
  {previewMixes.map((mixe, index) => (
    <div key={index} className="col-md-6 position-relative mb-3">
      <iframe
        src={URL.createObjectURL(mixe)}
        title={`Preview ${index}`}
        style={{
          height: "150px",
          width: "100%",
          border: "1px solid #EBECEC",
        }}
      />
      <p>{mixe.name}</p>
      <Button
        type="button"
        className="btn btn-danger position-absolute"
        style={{ top: "0px", right: "10px", height:"5px", width:"3px",padding:"10px", justifyContent:"center"}}
        onClick={() => handleRemovePreviewMixes(index)}
      >
        X
      </Button>
    </div>
  ))}
</div>

        </div>
        {previewMixes.length > 0 ? (
          <Button type="button" color="primary" onClick={handleSaveMixes} className="btn btn-success mt-2">
            Upload
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default UploadMultipleAlbum;
