import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Appbar from "./appbar/Appbar";
import AppMain from "./global/AppMain";
import AppRoot from "./global/AppRoot";
import AppHeader from "./header/AppHeader";
import Head from "./head/Head";
import { fetchPlanData, getCompanyProfile } from "./../http/get/getApi";
import { PlanContext } from "../pages/context/PlanContext";
import { formatDate } from "../utils/Utils";
import {
  ShimmerCircularImage,
  ShimmerTable,
  ShimmerThumbnail,
} from "react-shimmer-effects";
import { Col, Row } from "reactstrap";
import { useQuery } from "react-query";
import SuspendPage from "../pages/error/suspendPage";
import { useLocation } from "react-router-dom";

// let token = process.env.REACT_APP_AUTH_TOKEN;

const Layout = ({ title, app }) => {
  const [planData, setPlanData] = useState(null);
  const [planState, setPlanState] = useState();
  const [isSuspended, setIsSuspended] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isInvoicePrintRoute = location.pathname.includes("invoice-print");
  //fetching countries data
  const {
    data: fetchedPlanData,
    isLoading: planDataLoading,
    isError: planDataError,
  } = useQuery({
    queryKey: ["get-planData"],
    queryFn: () => fetchPlanData(),
    staleTime: Infinity,
    // staleTime: 60000,
    // cacheTime: 300000,
    // retry: 1,
  });

  const { data, isLoading: companyprofileLoading, isError } = useQuery({
    queryKey: ["userProfile-data"],
    queryFn: () => getCompanyProfile,
    // staleTime: 60000,
    staleTime: Infinity,
    // cacheTime: 300000,
    retry: 1,
  });
  // console.log("data123",data);

  useEffect(() => {
    if (data?.data?.is_suspend === "1") {
      setIsSuspended(true);
    } else if (data?.data?.is_suspend === "0") {
      setIsSuspended(false);
    }
  }, [data]);

  useEffect(() => {
    if (!planDataLoading && !planDataError && fetchedPlanData) {
      if (
        fetchedPlanData.status &&
        new Date(fetchedPlanData?.data?.package.end_date) > new Date()
      ) {
        setPlanData(fetchedPlanData.data.plans);
        setPlanState("true");
      } else {
        setPlanState("false");
      }
    }
  }, [fetchedPlanData, planDataLoading, planDataError]);

  return (
    <>
      <Head title={title || "Loading..."} />
      {planDataLoading ? (
        <div className="p-1">
          <Row>
            <Col md={1} className=" border p-1 bg-white">
              <div className="sidebar px-3 ">
                <div className="logo my-2">
                  <ShimmerThumbnail height={100} width={100} rounded />
                </div>
                <div className="icons px-1 my-3">
                  <ShimmerThumbnail height={40} rounded />
                  <ShimmerThumbnail height={40} rounded />
                  <ShimmerThumbnail height={40} rounded />
                  <ShimmerThumbnail height={40} rounded />
                  <ShimmerThumbnail height={40} rounded />
                  <ShimmerThumbnail height={40} rounded />
                  <ShimmerThumbnail height={40} rounded />
                  <ShimmerThumbnail height={40} rounded />
                </div>
              </div>
            </Col>
            <Col md={11} className="border p-1 bg-white">
              <Row>
                <div className="d-flex justify-content-between my-2">
                  <div>
                    <ShimmerThumbnail height={50} width={100} rounded />
                  </div>
                  <div className="d-flex align-item-center">
                    <div className="mx-2">
                      <ShimmerCircularImage size={50} />
                    </div>
                    <div className="mx-2">
                      <ShimmerCircularImage size={50} />
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <div className="d-flex justify-content-between my-2">
                  <div>
                    <ShimmerThumbnail height={50} width={200} rounded />
                  </div>
                  <div className="d-flex align-item-center">
                    <div className="mx-2">
                      <ShimmerThumbnail height={50} width={100} rounded />
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <div className="my-2">
                  <ShimmerTable row={10} />;
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <>
          {isSuspended ? (
            <SuspendPage isOpen={isSuspended} />
          ) : (
            <AppRoot>
              <Appbar />
              <AppMain>
                {!isInvoicePrintRoute && (
                  <AppHeader app={app} className="sticky-top" />
                )}{" "}
                <PlanContext.Provider value={{ planState, setPlanState }}>
                  <Outlet />
                </PlanContext.Provider>
              </AppMain>
            </AppRoot>
          )}
        </>
      )}
    </>
  );
};

export default Layout;
