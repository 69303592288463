import React from "react";
import { useState, useEffect } from "react";
import NavigationFooter from "../components/NavigationFooter";
import TabContent from "./venues/TabContent";
import { toast } from "react-toastify";

const EVenue = ({
  toggleMain,
  mainCurrentTab,
  formik,
  disabled,
  isViewOnly,
  isEventEditing,
  isEventAdding,
}) => {
  const [secondaryVenue, setSecondaryVenue] = useState(false);

  const venueDetails = [
    {
      buttonsName: "Add Secondary Venue",
      name: "Primary Venue",
      isEnableButton: true,
    },
    {
      name: "Secondary Venue",
      isEnabledButton: false,
    },
  ];

  const handelValidation = (callbackFun) => {
    if (formik.values.venue_id === "") {
      formik.setFieldTouched("venue_id", true);
    }
    if (formik.values.venue_id !== "") {
      callbackFun(true);
    }
  };

  useEffect(() => {
    if (formik.values.secondary_venue_id !== "") {
      setSecondaryVenue(true);
    }
  }, []);

  return (
    <>
      <div>
        <div>
          <TabContent
          
            buttonName={venueDetails[0].buttonsName}
            setSecondaryVenue={setSecondaryVenue}
            Heading={venueDetails[0].name}
            isEnable={venueDetails[0].isEnableButton}
            formik={formik}
            disabled={disabled}
            name="venue_id"
            isViewOnly={isViewOnly}
          />
          {secondaryVenue && (
            <TabContent
              buttonName={venueDetails[1].buttonsName}
              secondaryVenue={secondaryVenue}
              setSecondaryVenue={setSecondaryVenue}
              Heading={venueDetails[1].name}
              disabled={disabled}
              isEnable={venueDetails[1].isEnableButton}
              formik={formik}
              name="secondary_venue_id"
              isViewOnly={isViewOnly}
            />
          )}
        </div>
        {isViewOnly ? (
          ""
        ) : (
          <NavigationFooter
            toggle={toggleMain}
            currentState={mainCurrentTab}
            lastPage={"5"}
            validationFunction={handelValidation}
            isViewOnly={disabled}
            formik={formik}
            isEventEditing={isEventEditing}
            isEventAdding={isEventAdding}
            emailAvialable={true}
            usernameAvailable={true}
          />
        )}
      </div>
    </>
  );
};

export default EVenue;
