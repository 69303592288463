import React, { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Button,
  Progress,
  Tooltip,
  Popover,
  PopoverBody,
} from "reactstrap";
import { getCompanyProfile } from "../../http/get/getApi";
import { ShimmerThumbnail } from "react-shimmer-effects";
import NavigationHeader from "../components/NavigationHeader";
import { updateFirebaseUserDetails } from "../../Firebase Files/firebase-service";
import Content from "../../layout/content/Content";
import { PreviewCard } from "../../components/Component";
import profileimg from "../../../src/assets/images/profileimg.png";
import profileimgph from "../../assets/images/avtarimage.png";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import dashboard from "../../Jsonfiles/Dashboard.json";
import {
  FaGlobe,
  FaLinkedinIn,
  FaTwitch,
  FaSoundcloud,
  FaMixcloud,
} from "react-icons/fa";
import { AiFillTikTok } from "react-icons/ai";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { DataContext } from "../../context/DataProvider";

const SocialMediaLink = ({ icon: IconComponent, profileUrl, id }) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const activeVariable = getActiveEnvironmentVariable();

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
      <span style={{ display: "flex", alignItems: "center", width: "100%" }}>
        {/* Render the icon component */}
        {IconComponent && (
          <IconComponent style={{ fontSize: "20px", marginRight: "8px" }} />
        )}
        <span
          id={`tooltip-${id}`}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%", // Adjust width as needed
            display: "block",
          }}
        >
          <a
            href={profileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-body"
          >
            {profileUrl}
          </a>
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={`tooltip-${id}`}
          toggle={toggleTooltip}
        >
          {profileUrl}
        </Tooltip>
      </span>
    </li>
  );
};

export default function UserProfile() {
  const {
    setProfileProgress,
    profileprogress,
    userProfile,
    setUserProfile,
    setProfileCompletion,
  } = useContext(DataContext);
  const [progress, setProgress] = useState(0); // Set the progress percentage here

  const [parsedGenres, setParsedGenres] = useState([]);
  const [parsedServices, setParsedServices] = useState([]);
  const [parsedCategories, setParsedCategories] = useState([]);

  const navigate = useNavigate();

  function handleEditClick() {
    navigate("edit-profile");
  }

  const iconMapping = {
    globe: FaGlobe,
    facebook: FaFacebookSquare,
    instagram: FaInstagramSquare,
    tiktok: AiFillTikTok,
    linkedin: FaLinkedinIn,
    soundcloud: FaSoundcloud, // Replace with correct DashLite icon if needed
    mixcloud: FaMixcloud, // Replace with correct DashLite icon if needed
    twitch: FaTwitch,
  };

  const { data: data, isLoading: companyprofileLoading, isError } = useQuery({
    queryKey: ["userProfile-data"],
    queryFn: () => getCompanyProfile(),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setProfileProgress(
      data?.data?.profile?.profile_progress
        ? JSON.parse(data.data?.profile?.profile_progress)
        : 0
    );
    setUserProfile(data?.data?.profile?.profile_image_path || "");
    setProfileCompletion(data?.data?.section_status);
    // updateFirebaseUserDetails(data?.data?.profile?.profile_image_path);
  }, [data]);

  useEffect(() => {
    // Parse the JSON strings
    const genres = data?.data?.profile?.genres
      ? data?.data?.profile?.genres
      : {};
    const services = data?.data?.profile?.services
      ? data?.data?.profile?.services
      : {};
    const categories = data?.data?.profile?.category
      ? data?.data?.profile?.category
      : {};

    // Set the state with the values of the parsed objects
    setParsedGenres(Object.values(genres));
    setParsedServices(Object.values(services));
    setParsedCategories(Object.values(categories));
  }, [data]);

  const description = data?.data?.profile?.description || "";
  const previewLength = 250; // Number of characters to show initially
  const previewText =
    description.length > previewLength
      ? description.substring(0, previewLength) + "..."
      : description;

  const [popoverOpen, setPopoverOpen] = useState(false);
  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const profile = data?.data?.profile;
  const popoverRef = useRef(null);
  const activeVariable = getActiveEnvironmentVariable();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopoverOpen(false);
      }
    };

    if (popoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverOpen]);

  const handleImageError = (e) => {
    e.target.src = activeVariable === "CRM" ? profileimg : profileimgph; // Set fallback image if userProfile image fails to load
  };

  return (
    <>
      {companyprofileLoading ? (
        <Content>
          <Row>
            <Col md={6} className="border p-3 bg-white">
              <Row>
                <Col md={6} className="p-3 bg-white">
                  <ShimmerThumbnail height={250} width={300} rounded />
                  <ShimmerThumbnail height={40} width={300} rounded />
                  <ShimmerThumbnail height={40} width={300} rounded />
                </Col>
                <Col md={6} className="p-3 bg-white">
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                </Col>
              </Row>
            </Col>
            <Col md={6} className="border p-3 bg-white">
              <Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Content>
      ) : (
        <React.Fragment>
          <NavigationHeader
            heading={"Profile"}
            buttonInnerText={"Edit Profile"}
            route={"edit-profile"}
            removeIcon={true}
          />
          <section className="m-4">
            <PreviewCard className="mt-2">
              <Row className="w-100 m-2  p-3 bg-white  container">
                <Col lg="4">
                  <Card className="mb-4 shadow-sm">
                    <CardBody className="text-center">
                      <div
                        style={{
                          width: "100%",
                          maxWidth: "190px",
                          height: "auto",
                          maxHeight: "190px",
                          margin: "0 auto",
                          borderRadius: "50%",
                          overflow: "hidden",
                          border: "2px solid #dee2e6",
                        }}
                      >
                        <CardImg
                          src={
                            data?.data?.profile?.profile_image_path
                              ? data?.data?.profile?.profile_image_path
                              : activeVariable === "CRM"
                              ? profileimg
                              : profileimgph
                          }
                          alt="avatar"
                          onError={handleImageError}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>

                      <CardText className="text-body mb-1 mt-3 h5">
                        {data?.data?.username ? data?.data?.username : " "}
                      </CardText>
                      <CardText className="text-body mb-4 h6">
                        {data?.data?.profile?.email
                          ? data?.data?.profile?.email
                          : " "}
                      </CardText>
                      {/* <div className="d-flex justify-content-center mb-2">
                      <Button color="primary" onClick={handleEditClick}>Edit Profile</Button>
                    </div> */}
                    </CardBody>
                  </Card>
                  <Card className="mb-4 shadow-sm">
                    <CardBody className="p-0">
                      <ul className="list-group">
                        {profile?.website && (
                          <SocialMediaLink
                            icon={iconMapping["globe"]}
                            profileUrl={profile.website}
                            id="website"
                          />
                        )}
                        {profile?.facebook && (
                          <SocialMediaLink
                            icon={iconMapping["facebook"]}
                            profileUrl={profile.facebook}
                            id="facebook"
                          />
                        )}
                        {profile?.instagram && (
                          <SocialMediaLink
                            icon={iconMapping["instagram"]}
                            profileUrl={profile.instagram}
                            id="instagram"
                          />
                        )}
                        {profile?.tiktok && (
                          <SocialMediaLink
                            icon={iconMapping["tiktok"]}
                            profileUrl={profile.tiktok}
                            id="tiktok"
                          />
                        )}
                        {profile?.linkedin && (
                          <SocialMediaLink
                            icon={iconMapping["linkedin"]}
                            profileUrl={profile.linkedin}
                            id="linkedin"
                          />
                        )}
                        {profile?.soundcloud && (
                          <SocialMediaLink
                            icon={iconMapping["soundcloud"]}
                            profileUrl={profile.soundcloud}
                            id="soundcloud"
                          />
                        )}
                        {profile?.mixcloud && (
                          <SocialMediaLink
                            icon={iconMapping["mixcloud"]}
                            profileUrl={profile.mixcloud}
                            id="mixcloud"
                          />
                        )}
                        {profile?.twitch && (
                          <SocialMediaLink
                            icon={iconMapping["twitch"]}
                            profileUrl={profile.twitch}
                            id="twitch"
                          />
                        )}
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="8">
                  <Card className="mb-4 shadow-sm text-body">
                    <CardBody>
                      <Row className="mb-3">
                        <Col sm="3">
                          <CardText className="font-weight-bold">
                            User Name
                          </CardText>
                        </Col>
                        <Col sm="9">
                          <CardText className="text-body">
                            {data?.data?.username ? data?.data?.username : " "}
                          </CardText>
                        </Col>
                      </Row>
                      <hr />
                      <Row className="mb-3">
                        <Col sm="3">
                          <CardText className="font-weight-bold">Name</CardText>
                        </Col>
                        <Col sm="9">
                          <CardText className="text-body">
                            {data?.data?.profile?.first_name ||
                            data?.data?.profile?.last_name
                              ? `${data?.data?.profile?.first_name || ""}  ${
                                  data?.data?.profile?.last_name || ""
                                }`
                              : " "}
                          </CardText>
                        </Col>
                      </Row>
                      <hr />
                      <Row className="mb-3">
                        <Col sm="3">
                          <CardText className="font-weight-bold">
                            Email
                          </CardText>
                        </Col>
                        <Col sm="9">
                          <CardText className="text-body">
                            {data?.data?.email ? data?.data?.email : " "}
                          </CardText>
                        </Col>
                      </Row>
                      <hr />
                      <Row className="mb-3">
                        <Col sm="3">
                          <CardText className="font-weight-bold">
                            Mobile No.
                          </CardText>
                        </Col>
                        <Col sm="9">
                          <CardText className="text-body">
                            {data?.data?.profile?.phone_number
                              ? data?.data?.profile?.phone_number
                              : " "}
                          </CardText>
                        </Col>
                      </Row>
                      <hr />
                      <Row className="mb-3">
                        <Col sm="3">
                          <CardText className="font-weight-bold">
                            Address
                          </CardText>
                        </Col>
                        <Col sm="9">
                          {data?.data?.profile?.address_1 ||
                          data?.data?.profile?.address_2 ||
                          data?.data?.profile?.cityName ||
                          data?.data?.profile?.stateName ||
                          data?.data?.profile?.countryName ? (
                            <CardText className="text-body">{`${
                              data?.data?.profile?.address_1
                                ? data?.data?.profile?.address_1 + ","
                                : ""
                            } ${
                              data?.data?.profile?.address_2
                                ? data?.data?.profile?.address_2 + ","
                                : ""
                            } ${
                              data?.data?.profile?.cityName
                                ? data?.data?.profile?.cityName + ","
                                : ""
                            } ${
                              data?.data?.profile?.stateName
                                ? data?.data?.profile?.stateName + ","
                                : ""
                            } ${
                              data?.data?.profile?.countryName
                                ? data?.data?.profile?.countryName + "."
                                : ""
                            }`}</CardText>
                          ) : (
                            <CardText className="text-body"></CardText>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Row>
                    <Col md="6">
                      <Card
                        className="mb-4 mb-md-0 shadow-sm "
                        style={{
                          height: "50vh",
                          maxHeight: "250px",
                          overflowY: "hidden",
                        }}
                      >
                        <CardBody>
                          <CardText className="h5 text-body">
                            Description
                          </CardText>
                          <hr />
                          <CardText
                            className="text-body mb-4"
                            id="DescriptionPopover"
                          >
                            {previewText}
                          </CardText>
                          {description.length > previewLength && (
                            <Popover
                              innerRef={popoverRef}
                              placement="top"
                              isOpen={popoverOpen}
                              target="DescriptionPopover"
                              toggle={togglePopover}
                              style={{
                                maxWidth: "700px", // Adjust the width as needed
                              }}
                            >
                              <PopoverBody
                                style={{
                                  maxHeight: "150px",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                  whiteSpace: "normal",
                                }}
                              >
                                {description}
                              </PopoverBody>
                            </Popover>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card className="mb-0 mb-md-0 shadow-sm h-250px">
                        <CardBody>
                          <CardText className="h5 text-body">Genres</CardText>
                          <hr />
                          <CardText
                            className="text-body mb-2"
                            style={{
                              maxHeight: "150px",
                              overflowY: "auto",
                              scrollbarWidth: "none",
                            }}
                          >
                            {parsedGenres?.map((item, index) => (
                              <ul
                                className="ml-6"
                                style={{
                                  listStyleType: "disc",
                                  marginLeft: "20px",
                                }}
                                key={index}
                              >
                                <li className="ml-4">{item}</li>
                              </ul>
                            ))}
                          </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md="6">
                      <Card className="mb-4 mb-md-0 shadow-sm h-250px">
                        <CardBody>
                          <CardText className="h5 text-body">
                            {dashboard?.[activeVariable]?.section1?.map(
                              (item) => item?.text2
                            )}{" "}
                            Enhancements
                          </CardText>
                          {/* <CardText className="h5 text-body">Services</CardText> */}
                          <hr />
                          {/* <CardText className="text-body mb-4"> */}
                          <CardText
                            className="text-body mb-2"
                            style={{
                              maxHeight: "150px",
                              overflowY: "auto",
                              scrollbarWidth: "none",
                            }}
                          >
                            {parsedServices?.map((item, index) => (
                              <ul
                                className="ml-6"
                                style={{
                                  listStyleType: "disc",
                                  marginLeft: "20px",
                                }}
                                key={index}
                              >
                                <li className="ml-4">{item}</li>
                              </ul>
                            ))}
                          </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card className="mb-4 mb-md-0 shadow-sm h-250px">
                        <CardBody>
                          <CardText className="h5 text-body">
                            {dashboard?.[activeVariable]?.section1?.map(
                              (item) => item?.text2
                            )}{" "}
                            Type
                          </CardText>
                          {/* <CardText className="h5 text-body">Category</CardText> */}
                          <hr />
                          <CardText
                            className="text-body mb-2"
                            style={{
                              maxHeight: "150px",
                              overflowY: "auto",
                              scrollbarWidth: "none",
                            }}
                          >
                            {parsedCategories?.map((item, index) => (
                              <ul
                                className="ml-6"
                                style={{
                                  listStyleType: "disc",
                                  marginLeft: "20px",
                                }}
                                key={index}
                              >
                                <li className="ml-4">{item}</li>
                              </ul>
                            ))}
                          </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </PreviewCard>
          </section>
          {/* </section> */}
          {/* </Content> */}
        </React.Fragment>
      )}
    </>
  );
}
