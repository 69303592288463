import React, { useEffect, useState } from "react";
import { CardTitle } from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../components/table/DataTable";
import { PaginationComponent } from "../../components/Component";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import dashboard from "../../Jsonfiles/Dashboard.json"

const RecentActivity = ({ allData }) => {
  const activeVariable = getActiveEnvironmentVariable();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(5);
  const [eventActivity, setEventActivity] = useState([]);

  // Update eventActivity state whenever allData changes
  useEffect(() => {
    setEventActivity(allData?.data?.eventActivityData || []);
  }, [allData]);

  // Calculate indices for pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;

  // Slice data for the current page
  const currentItems = eventActivity?.slice(indexOfFirstItem, indexOfLastItem);

  // Function to change the current page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <div className="card-inner">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">
              <h4 className="me-2">{dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)} Activities</h4>
              <div className="fs-14px fw-normal mt-3 text-body">Total {dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)} Types: {eventActivity?.length}</div>
            </h6>
          </CardTitle>
        </div>
      </div>

      <DataTableBody className="border-top h-100" bodyclass="nk-tb-orders">
        <DataTableHead>
          <DataTableRow className="text-center">
            <span className="text-dark tb-lead">Sr. No.</span>
          </DataTableRow>
          <DataTableRow className="text-center">
            <span className="text-dark tb-lead">{dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)} Name</span>
          </DataTableRow>
          <DataTableRow className="text-center">
            <span className="text-dark tb-lead">{dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)} Count</span>
          </DataTableRow>
        </DataTableHead>

        {/* Map over the sliced data for the current page */}
        {currentItems?.map((item, idx) => (
          <DataTableItem key={idx}>
            <DataTableRow className="text-center">
              <span className="tb-sub">
                <a href="#order" className="text-dark">
                  {indexOfFirstItem + idx + 1}
                </a>
              </span>
            </DataTableRow>
            <DataTableRow className="text-center">
              <span className="tb-sub text-dark">{item?.event_type_title || "Unknown"}</span>
            </DataTableRow>
            <DataTableRow className="text-center">
              <span className="tb-sub text-dark">{item?.event_count ?? 0}</span>
            </DataTableRow>
          </DataTableItem>
        ))}
      </DataTableBody>

      <div className="my-3 mx-auto">
        <PaginationComponent
          itemPerPage={itemPerPage}
          totalItems={eventActivity?.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </React.Fragment>
  );
};

export default RecentActivity;
