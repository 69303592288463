import React, { useEffect, useState } from "react";
import { CardImg, CardTitle } from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../components/table/DataTable";
import { Link } from "react-router-dom";
import { formatDate, getCookie } from "../../utils/Utils";
import Upcoming_events from "../../../src/assets/images/Upcoming events.png"
import commingsoon from "../../assets/images/commingsoonimage.jpg"
import bluecooming from '../../assets/images/coomingsoonimageblue.jpg';
import orgcooming from "../../assets/images/constructioncommingsoon.jpg";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import dashboard from "../../Jsonfiles/Dashboard.json"

// let token = process.env.REACT_APP_AUTH_TOKEN;

const UpcomingEvents = ({allData}) => {
  const activeVariable = getActiveEnvironmentVariable();
  const [eventdata, setEventData] = useState([]);
  const [isEventPresent, setIsEventPresent] = useState(true);
  const [newUpcomingEvent, setNewUpcomingEvent]= useState();

  useEffect(()=>{
    setNewUpcomingEvent(allData?.data?.upcomingEventData);
  },[allData])

  // useEffect(() => {
  //   eventInfo();
  //   return () => {};
  // }, []);
  // const eventInfo = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("categorize_event", "upcoming");
  //     formData.append("limit", "5");
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/events/get-event`, {
  //       method: "POST",
  //       headers: {
  //         authToken: getCookie("authToken"),
  //         Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //       },
  //       body: formData,
  //     });
  //     if (!response?.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const responseData = await response.json();
  //     if (responseData.status === false) {
  //       setIsEventPresent(false);
  //       return null;
  //     }
  //     setEventData(responseData ? responseData?.data : []);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  return (
    <React.Fragment>
      <div className="card-inner">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">
              <h4 className="me-2">Upcoming {dashboard?.[activeVariable]?.section1?.map((item) => item?.text5)}</h4>{" "}
              <Link to={`${process.env.PUBLIC_URL}event-list`} className="link d-block d-md-inline ">
               History
              </Link>
            </h6>
          </CardTitle>
        </div>
      </div>
      <DataTableBody className="border-top" bodyclass="nk-tb-orders">
        {/* {eventdata.length !== 0 ? ( */}
        {newUpcomingEvent?.length !== 0 ? (
          <>
            <DataTableHead>
              <DataTableRow>
                <span className="text-dark">Sr. No.</span>
              </DataTableRow>
              <DataTableRow>
                <span className="text-dark">Client UserName</span>
              </DataTableRow>
              <DataTableRow>
                <span className="text-dark">{dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)} Name</span>
              </DataTableRow>
              <DataTableRow>
                <span className="text-dark">{dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)} Type</span>
              </DataTableRow>
              <DataTableRow>
                <span className="text-dark">{dashboard?.[activeVariable]?.section1?.map((item) => item?.text2)} Date</span>
              </DataTableRow>
              <DataTableRow>
                <span className="text-dark">Venue Name</span>
              </DataTableRow>
            </DataTableHead>
            {/* {isEventPresent && */}
            {
              Array.isArray(newUpcomingEvent) && newUpcomingEvent?.map((item, idx) => {
                return (
                  <DataTableItem key={idx}>
                    <DataTableRow>
                      <span className="tb-lead">
                        <a href="#order" className="text-dark">{idx + 1}</a>
                      </span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="tb-sub text-dark" >{item?.client_username}</span>
                      {/* <span className="tb-sub px-1 text-dark " >{item?.client_details?.[0]?.last_name}</span> */}
                    </DataTableRow>
                    <DataTableRow>
                      <span className="tb-sub text-dark">{item?.event_name}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="tb-sub text-dark">{item?.event_type_title}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="tb-sub tb-amount text-dark">{formatDate(item?.start_date_time)}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="tb-sub tb-amount text-dark">
                        {item?.venue_name}
                      </span>
                    </DataTableRow>
                  </DataTableItem>
                );
              })}
          </>
        ) : (
          // <div className="fs-3 justify-center align-center h-100px">
          //   <span>No Records Present</span>
          // </div>
          <div className="text-body text-center m-3">
        {/* // <div className="text-body">No events found.</div> */}
        <CardImg

        src={activeVariable=== "CRM" ? Upcoming_events : activeVariable=== "photography" ? bluecooming : orgcooming}
        alt="avatar"
        style={{ width: "15%", height: "15%", objectFit: "cover" }}
      />
      </div>
        )}
      </DataTableBody>
    </React.Fragment>
  );
};
export default UpcomingEvents;
